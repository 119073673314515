import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import Styles from '../../executiveleadershipreviews.module.css'
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import API from '../../../../../../Services/API';

const SectionOne = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [profileImage, setProfileImage] = useState(null);
    const [heading, setHeading] = useState(props.label)
    const [description, setDescription] = useState(props.sublabel)



    function sectionImageUpload(e) {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        // Create a file reader to read the file and generate a preview URL
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        } else {
            setPreview(null);
        }
    }
    const saveContent = (event) =>{
        const formData = new FormData()
        if(profileImage != null){
            formData.append('banner', profileImage)
        }
        formData.append('label', heading)
        formData.append('sublabel', description)
        formData.append('id', parseInt(event.target.id))
        formData.append('pillar', parseInt(props.pillarId))
        API.put(`/impact-story`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/impact-story?pillar=${props.pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                props.getSecondSlideUpdate(response.data)
            }).catch(error=>{
                 
                setIsLoading(false)
            })            
        })
    }
    const changeLabel = (event) =>{
        setHeading(event.target.value)
    }
    
    const changeSubLabel = (event) =>{
        setDescription(event.target.value)
    }

    

    return (
        <>
            {
                props.editable ? 
                <Box p={'5px'}>
                    <FormControl border={'1px solid #cccccc'} p={'10px'} borderRadius={'10px'} mb={'15px'}>
                        <FormLabel fontWeight={'600'} mb={'5px'}> Label </FormLabel>
                        <Input defaultValue={heading} placeholder='Please provide label' mb={'5px'} onChange={changeLabel} />
                        <FormLabel fontWeight={'600'} mb={'5px'}> Section Image </FormLabel>
                        <Input type="file" title='mainSection' onChange={sectionImageUpload} padding={"4px"} mb={'5px'} />
                        <FormLabel fontWeight={'600'} mb={'5px'}> Profile Name </FormLabel>
                        <Input defaultValue={description} placeholder='Please provide sublabel' mb={'5px'} onChange={changeSubLabel} />
                        <Button className='buttonPrimary' title='mainsection' onClick={saveContent} mt={'10px'} float={'right'} id={props.id}> Save </Button>
                        <Box className='clearfix'></Box>
                    </FormControl>
                </Box>:
                <>
                    <Heading className={Styles.leftGridHeading}>
                        {props.label}
                    </Heading>
                    <Image className={Styles.profileImage} src={props.banner} alt={props.sublabel} />
                    <Text as={'p'} className={Styles.profileName}> {props.sublabel} </Text>
                </>
            }
        </>
    )
}

export default SectionOne