import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { Box, Button, Alert, AlertIcon } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import API from '../../../../Services/API';
import LoaderSpinner from '../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../Widgets/ErrorWidget/ErrorWidget';

const AddNewMember = ({ pillarId, teamType, teamLeads, getUpdatedTeam }) => {
    const token = window.localStorage.getItem("accessToken");
    const [isLoading, setIsLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const [users, setUsers] = useState([]);
    const [showSubmit, setShowSubmit] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            setIsLoading(true);
            setErrorMessage('');
            setSuccessMessage('');
            try {
                const response = await API.get(`/list-user`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setUsers(response.data);
            } catch (error) {
                console.error(error);
                setErrorMessage('Failed to load users. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };
        fetchUsers();
    }, [token]);

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
        setShowButton(false);
    };

    const options = useMemo(() => users.map(user => ({
        value: user.id,
        label: user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : user.email
    })), [users]);

    const handleSelect = (selectedOption) => {
        setShowSubmit(true);
        setSelectedUser(selectedOption);
    };

    const addTeamLead = async () => {
        setIsLoading(true);
        setErrorMessage('');
        setSuccessMessage('');
    
        const formData = new FormData();
        formData.append('user', selectedUser.value); // Use selectedUser.value
        formData.append('pillar', pillarId);
        formData.append('role', teamType === 'lead' ? 'lead' : 'member');
    
        if (teamType === 'actionoffice') {
            formData.append('action_office_user', true);
        }
    
        try {
            await API.post(`/pillar-team`, formData, {
                headers: { Authorization: `Bearer ${token}` }
            });
    
            const response = await API.get(`/pillar-team?pillar_id=${pillarId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            getUpdatedTeam(response.data);
            
            // Reset selected user and options
            setSelectedUser(null);
            setShowSubmit(false);
            setSuccessMessage('User successfully added!');
        } catch (error) {
            console.error(error);
            setErrorMessage('Failed to add user. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const filteredOptions = options.filter(option =>
        teamLeads && !teamLeads.some(lead => lead.member_name === option.label)
    );

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 99999999,
        }),
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {errorMessage && (<ErrorWidget message={errorMessage} />)}
            {successMessage && (<ErrorWidget message={successMessage} />)}
            <Box>
                {showButton && (
                    <Button onClick={toggleDropdown} leftIcon={<AddIcon />} isLoading={isLoading} className="defaultButton">
                        Add
                    </Button>
                )}
                {isDropdownOpen && (
                    <Select
                        options={filteredOptions}
                        onChange={handleSelect}
                        styles={customStyles}
                        value={selectedUser} // Set the value to the selected user
                    />
                )}
            </Box>
            {showSubmit && (
                <Box mt={2}>
                    <Button onClick={addTeamLead} isLoading={isLoading} className="buttonPrimary">
                        Add Selected User
                    </Button>
                </Box>
            )}
        </>
    );
};

export default AddNewMember;
