import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Styles from '../aspirations.module.css';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import {
    Grid,
    Card,
    CardHeader,
    CardBody,
    Heading,
    Switch,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Text,
    Box,
    Input,
    Textarea,
    Button,
    Image,
    FormControl,
    FormLabel,
    SimpleGrid
} from '@chakra-ui/react';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, useDisclosure } from '@chakra-ui/react';
import { EditIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons';
import API from '../../../../../../Services/API';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const EditMetricData = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const { metricId } = useParams();
    const [allow, setAllow] = useState(false);

    const [selectedPeriodName, setSelectedPeriodName] = useState(props.periodName);
    const [selectedMetric, setSelectedMetric] = useState(props.metricName);
    const [selectedPeriod, setSelectedPeriod] = useState(props.period);
    const [selectedFyq, setSelectedFyq] = useState(props.fyq);
    const [selectedStatus, setSelectedStatus] = useState(props.status);
    const [selectedApproval, setSelectedApproval] = useState(props.approvalStatus);
    const [selectedCountry, setSelectedCountry] = useState(props.geography);
    const [qtrListUp, setQtrListUp] = useState();

    const fiscalYearDetail = props.fiscalYearDetail && props.fiscalYearDetail.map(item => ({
        value: item.id,
        label: item.name
    }));

    const fiscalYearQtrDetail = props.fiscalYearQtrDetail && props.fiscalYearQtrDetail.map(item => ({
        value: item.id,
        label: item.name
    }));

    const statusOptions = [
        { value: 'on-track', label: 'On Track' },
        { value: 'delay', label: 'Delayed' },
        { value: 'acceleration', label: 'Acceleration' }
    ];

    const countriesList = props.countries && props.countries
        .map(region => ({
            value: region.id,
            label: region.name
        })).sort((a, b) => a.label.localeCompare(b.label));

    const changeCountry = (event) => {
        setSelectedCountry(event.value);
    };

    const changePeriod = (event) => {
        setSelectedPeriod(event.value);
        setSelectedPeriodName(event.label);
        setSelectedFyq(null); // Reset Period (FQ) to null when Period (FY) changes
    };

    const changeFyq = (event) => {
        setSelectedFyq(event.value);
    };

    const changeStatus = (event) => {
        setSelectedStatus(event.value);
    };

    // Function to load default data from props
    const loadDefaultData = () => {
        if (selectedMetric !== props.metricName) setSelectedMetric(props.metricName);
        if (selectedPeriod !== props.period) setSelectedPeriod(props.period);
        if (selectedPeriodName !== props.periodName) setSelectedPeriodName(props.periodName);
        if (selectedFyq !== props.fyq) setSelectedFyq(props.fyq);
        if (selectedStatus !== props.status) setSelectedStatus(props.status);
        if (selectedApproval !== props.approvalStatus) setSelectedApproval(props.approvalStatus);
        if (selectedCountry !== props.geography) setSelectedCountry(props.geography);
        if (allow !== props.allowShare) setAllow(props.allowShare); // Assuming there's an `allowShare` prop
    };

    // Modified onOpen function to load default data when modal opens
    const handleOpen = () => {
        loadDefaultData();
        onOpen();
    };

    // Effect to update related quarters whenever period or fiscal year details change
    useEffect(() => {
        const selectedOptionNew = `FY${selectedPeriodName.slice(-2)}`;
        const relatedQuarters = fiscalYearQtrDetail
            .filter(item => item.label.includes(selectedOptionNew))
            .sort((a, b) => {
                const quarterA = parseInt(a.label.split(' ')[1].replace('Q', ''));
                const quarterB = parseInt(b.label.split(' ')[1].replace('Q', ''));
                return quarterB - quarterA;
            });

        setQtrListUp(relatedQuarters);
    }, [selectedPeriodName, fiscalYearQtrDetail,]);

    const updateMetric = () => {
        setIsLoading(true);
        const body = {
            "id": parseInt(props.id),
            "name": selectedMetric,
            "value": $('#updateValue').val(),
            "fiscal_year": parseInt(selectedPeriod),
            "fiscal_quarter": parseInt(selectedFyq),
            "geography": parseInt(selectedCountry),
            "metric_status": selectedStatus
        };
        API.put(`/metric-data`, body, { headers: { 'Authorization': 'Bearer ' + token } })
            .then(response => {
                API.get(`/metric-data?metric_id=${metricId}`)
                    .then(response1 => {
                        props.getUpdatedMetricData(response1.data);
                        onClose();
                        setIsLoading(false);
                    })
                    .catch(error => {
                        setIsLoading(false);
                        setErrorMessage('Error fetching updated metric data');
                    });
            })
            .catch(error => {
                setIsLoading(false);
                setErrorMessage('Error updating metric data');
            });
    };

    const onHandleChange = (event) => {
        setAllow(event.target.checked);
    };

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            <Button mr={'10px'} className='editNButton' id={props.id} onClick={handleOpen}>
                <EditIcon id={props.id} onClick={handleOpen} />
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW={'680px'}>
                    <ModalHeader>Metric Data</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {errorMessage && <Text color="red.500">{errorMessage}</Text>}
                        <SimpleGrid columns={[1, null, 2]} spacing='10px'>
                            <Box>
                                <FormControl mb={'15px'}>
                                    <FormLabel fontWeight={'bold'}>Metric</FormLabel>
                                    <Input defaultValue={selectedMetric} disabled />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl mb={'15px'}>
                                    <FormLabel fontWeight={'bold'}>Value</FormLabel>
                                    <Input id="updateValue" defaultValue={props.value} />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl mb={'15px'}>
                                    <FormLabel fontWeight={'bold'}>Period (FY)</FormLabel>
                                    <Select
                                        options={fiscalYearDetail}
                                        onChange={changePeriod}
                                        defaultValue={fiscalYearDetail && fiscalYearDetail.find(option => option.label === props.periodName)}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl mb={'15px'}>
                                    <FormLabel fontWeight={'bold'}>Period (FQ)</FormLabel>
                                    <Select
                                        options={qtrListUp}
                                        onChange={changeFyq}
                                        value={qtrListUp && qtrListUp.find(option => option.value === selectedFyq) || null}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl mb={'15px'}>
                                    <FormLabel fontWeight={'bold'}>Status</FormLabel>
                                    <Select
                                        options={statusOptions}
                                        onChange={changeStatus}
                                        defaultValue={statusOptions && statusOptions.find(option => option.value === selectedStatus)}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl mb={'15px'}>
                                    <FormLabel fontWeight={'bold'}>Geography</FormLabel>
                                    <Select
                                        options={countriesList}
                                        onChange={changeCountry}
                                        defaultValue={
                                            countriesList &&
                                            countriesList.find(option => {
                                                let formattedLabel;
                                                if (option.label === "Georgia (United States of America)") {
                                                    formattedLabel = option.label
                                                        .replace(/\s?\(United States of America\)/i, " ")
                                                        .replace(/^./, str => str.toUpperCase());
                                                } else {
                                                    formattedLabel = option.label
                                                        .replace("(United States of America)", "")
                                                        .trim();
                                                }
                                                return formattedLabel === props.geographyName;
                                            })
                                        }
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl display='flex' alignItems='center' mt={'0px'}>
                                    <FormLabel fontWeight={'bold'} htmlFor='share_data_externally' mb='0px'>
                                        Allow share
                                    </FormLabel>
                                    <Switch
                                        colorScheme='teal'
                                        id='share_data_externally'
                                        isChecked={allow}
                                        onChange={onHandleChange}
                                    />
                                </FormControl>
                            </Box>
                        </SimpleGrid>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                        <Button colorScheme="blue" onClick={updateMetric}>Save</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditMetricData;
