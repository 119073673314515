import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from '../executiveleadershipreviews.module.css'
import API from '../../../../../../../Services/API'
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import Select from 'react-select';
import LoaderSpinner from '../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import {useDisclosure} from '@chakra-ui/react'
import HeadingOne from './HeadingOne/HeadingOne';
import MetricInfo from './MetricInfo/MetricInfo';
import FiscalYear from './FiscalYear/FiscalYear';
import DeleteAction from './DeleteAction/DeleteAction';
import ErrorWidget from '../../../../../../Widgets/ErrorWidget/ErrorWidget';

const SlideOne = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState(false)
    const [elrSectionOne, setElrSectionOne] = useState()
    const [elrMetric, setElrMetric] = useState()
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [viewOneId, setViewOneId] = useState(null);
    const [impactStories, setImpactStories] = useState(null);
    const [elr, setElr] = useState(null);
    const [noData, setNoData] = useState(0);
    const editor = useRef(null);
    const [status, setStatus] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const [elrFile, setElrFile] = useState(null);
    const [submitMessage, setSubmitMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [heading, setHeading] = useState(props.slideData?.label || '');
    const [infoMessage, setInfoMessage] = useState(null);

    useEffect(()=>{
        setHeading(props.slideData?.label)
    },[props.editable, props.slideData,])

    function bannerUpload(e) {
        const selectedFile = e.target.files[0]
        setFile(selectedFile)
    
        const reader = new FileReader()
        reader.onloadend = () => {
            setPreview(reader.result)
        }
        if (selectedFile) {
            reader.readAsDataURL(selectedFile)
        } else {
            setPreview(null)
        }
    }
    
    const changeHeading = (e) => {
        setHeading(e.target.value)
    }
    

    const saveHeader = () => {
        setIsLoading(true)
        setInfoMessage(null)
        setSuccessMessage(null)
        setErrorMessage(null)
    
        // Validation for required fields
        if (!heading) {
            setErrorMessage('Banner heading is required')
            setIsLoading(false)
            return
        }

        if ((heading !== props.slideData?.label) || (file !== null)) {
            const formData = new FormData()
            if(file !== null){
                formData.append('banner', file)
            }
            formData.append('label', heading)  // Use the updated heading state here
            formData.append('id', parseInt(props.slideData?.id))
            formData.append('action', parseInt(props.pillarId))
            formData.append('approval_status', 'Ready to Submit')
    
            API.put(`/elr-dashboard`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                // props.getUpdatedImpactStory()
                props.slideOneUpdate()
                setIsLoading(false)
                setSuccessMessage('Header saved successfully')
            }).catch(error => {
                setErrorMessage(error.response.data.msg)
                setIsLoading(false)
            })
        } else {
            setInfoMessage('No changes are made')
            setIsLoading(false)
        }
    }

    const getError = (error) =>{
        setErrorMessage(error)
    }

    const submitForApproval = () => {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('id', parseInt(props.slideData && props.slideData.id))
        formData.append('approval_status','Submitted')
        API.put(`/elr-dashboard`, formData, {
            headers: {
            'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            // props.getUpdatedImpactStory()
            props.slideOneUpdate()
            setIsLoading(false)
        }).catch(error=>{
            setErrorMessage(error.response.data.msg)
            setIsLoading(false)
        })
    }

    return (
        <>
            {
                isLoading ? <LoaderSpinner /> : null
            }

            {
                successMessage !== null ? <ErrorWidget message={successMessage && successMessage} /> : null
            }
            {
                errorMessage !== null ? <ErrorWidget message={errorMessage && errorMessage} /> : null
            }
            {
                infoMessage ? <ErrorWidget message={infoMessage && infoMessage} /> : null
            }
            <Box border={'1px solid #e2e8f0'}>
                {
                    props.editable ?
                        <Box>
                            <DeleteAction
                                id={props.slideData && props.slideData.id}
                                label = {props.slideData && props.slideData.label}
                                // getUpdatedImpactStory={props.getUpdatedImpactStory}
                                slideOneUpdate={props.slideOneUpdate}
                            />
                        </Box>
                    : null
                }
                <Heading className={Styles.headingBg}>
                   {
                        props.slideData.banner !=null && 
                        <Image src={props.slideData && props.slideData.banner} alt={props.slideData && props.slideData.label} crossOrigin="anonymous" position={'absolute'} width={'100%'} />
                    }
                    {
                        props.editable ?
                            <SimpleGrid columns={[3]}>
                                <Box p={'10px'}>
                                    <Input
                                        placeholder='Please provide banner heading'
                                        defaultValue={heading}
                                        mb={'5px'}
                                        backgroundColor={'#ffffff'}
                                        id={'headerLabel'}
                                        value={heading}
                                        onChange={changeHeading}
                                    />
                                </Box>
                                <Box p={'10px'}>
                                    <Input type="file" onChange={bannerUpload} padding={"4px"} />
                                </Box>
                                <Box p={'10px'}>
                                    <Button className='buttonPrimary' onClick={saveHeader} isLoading={isLoading}> Save </Button>
                                </Box>
                            </SimpleGrid>
                        : <Text as='p' className={Styles.centerAlign}>{props.slideData && props.slideData.label}</Text>
                    }
                </Heading>
                <Box>
                    <Grid h={props.editable ? 'auto' : 'auto'} templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={0}>
                        <GridItem rowSpan={2} bg={props.editable ? '#ffffff' : '#00a0da'}>
                            <FiscalYear
                                editable = {props.editable}
                                heading={props.slideData && props.slideData.heading1}
                                description={props.slideData && props.slideData.description1}
                                pillarId={props.pillarId}
                                id={props.slideData && props.slideData.id}
                                fiscalYear={props.fiscalYear}
                                fiscalQtr={props.fiscalQtr}
                                getError={getError}
                                slideType={'slide1'}
                                slideOneUpdate={props.slideOneUpdate}
                            />

                            <HeadingOne
                                editable = {props.editable}
                                heading={props.slideData && props.slideData.heading1}
                                description={props.slideData && props.slideData.description1}
                                pillarId={props.pillarId}
                                id={props.slideData && props.slideData.id}
                                slideOneUpdate={props.slideOneUpdate}
                                getError={getError}
                                index={1}
                            />
                            <HeadingOne
                                editable = {props.editable}
                                heading={props.slideData && props.slideData.heading2}
                                description={props.slideData && props.slideData.description2}
                                pillarId={props.pillarId}
                                id={props.slideData && props.slideData.id}
                                slideOneUpdate={props.slideOneUpdate}
                                getError={getError}
                                index={2}
                            />
                            <HeadingOne
                                editable = {props.editable}
                                heading={props.slideData && props.slideData.heading3}
                                description={props.slideData && props.slideData.description3}
                                pillarId={props.pillarId}
                                id={props.slideData && props.slideData.id}
                                slideOneUpdate={props.slideOneUpdate}
                                getError={getError}
                                index={3}
                            />
                            <HeadingOne
                                editable = {props.editable}
                                heading={props.slideData && props.slideData.heading4}
                                description={props.slideData && props.slideData.description4}
                                pillarId={props.pillarId}
                                id={props.slideData && props.slideData.id}
                                slideOneUpdate={props.slideOneUpdate}
                                getError={getError}
                                index={4}
                            />
                        </GridItem>
                        <MetricInfo 
                            pillarId={props.pillarId}
                            firstFiscalYear={props.firstFiscalYear}
                            firstFiscalQtr={props.firstFiscalQtr}
                            editable = {props.editable}
                            id={props.slideData && props.slideData.id}
                            actionStatus={props.actionStatus}
                            getStatus={props.getStatus}

                            fiscalYearId={props.fiscalYearId}
                            fiscalQtrId={props.fiscalQtrId}
                            metricResult={props.metricResult && props.metricResult}
                        />
                    </Grid>
                    {
                        props.itemStatus === 'Ready to Submit' ? 
                            <>
                                <Box float={'right'} mt={'5px'}> 
                                    <Button className='buttonPrimary' onClick={submitForApproval} > Submit for approval </Button>
                                </Box>
                            </>
                        : null
                    }
                </Box>
            </Box>
            
        </>
    )
}

export default SlideOne