import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import Styles from './pdfSectionTwo.module.css'
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import API from '../../../../../../../../../../Services/API';

const PdfSectionTwo = (props) => {
  return (
    <>
      <Box width={'80%'} mb={'20px'}>
          <Flex>
              {/* <Avatar src={props.banner} imgProps={{ crossOrigin: "anonymous" }} /> */}
              <Box className={Styles.avatar}>
                  {props.banner ? <Image src={props.banner} crossOrigin="anonymous" alt="Description" /> : null}
              </Box>
              <Box ml='3'>
                  <Text fontWeight='bold' mb={'5px'}>
                      {props.heading}
                  </Text>
                  <Text fontSize='sm'>{props.description}</Text>
              </Box>
          </Flex>
      </Box>
    </>
  )
}

export default PdfSectionTwo