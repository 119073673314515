import React, { useState, useEffect, useMemo, useRef } from 'react';
import API from '../../../Services/API';
import Styles from './geographicImpact.module.css';
import {  Switch, FormControl, FormLabel, Text, Button } from "@chakra-ui/react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Heading, Card, CardBody,  Tooltip,Box, Progress } from '@chakra-ui/react';
import Select from 'react-select';
import {
    ComposableMap,
    Geographies,
    Geography,
    ZoomableGroup,
} from 'react-simple-maps';
import worldData from './world.geojson';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import NumberFormat, { formatNumber } from './NumberFormat';
import LoaderSpinner from '../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import MapFOrUSstate from './MapFOrUSstate';
const GeographicImpact = () => {
    const token = window.localStorage.getItem("accessToken");
    const [isLoading, setIsLoading] = useState(false);
    const [countryData, setCountryData] = useState([]);
    const [fiscalYearList, setFiscalYearList] = useState([]);
    const [fiscalQtrList, setFiscalQtrList] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [regionParentData, setRegionParentData] = useState([]);
    const [selectedYearOption, setSelectedYearOption] = useState(null);
    const [selectedQtrOption, setSelectedQtrOption] = useState(null);
    const [selectedMetricValue, setSelectedMetricValue] = useState(null);
    const [metric, setMetric] = useState([]);
    const [pillarID, setPillarID] = useState(null);
    const [selectionType, setSelectionType] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [highlightedState, setHighlightedState] = useState(null);

    const { id } = useParams();
    const navigate = useNavigate();
    const defaultScale = 130;

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '12px',
            fontSize: '12px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '12px',
        }),
    };

    const highlightedCountries = useMemo(() => 
        countryData.map((item) => ({ code: item.parent_id || item.country_code, value: item.value, statecode:item.country_code, metric_type:item.metric__datatype })), 
        [countryData]
    );

    // const yearOptions = useMemo(() => [
    //     ...fiscalYearList.map(year => ({ value: year.id, label: year.name, name: 'year' })),
    //     ...fiscalQtrList.map(quarter => ({ value: quarter.id, label: quarter.name, name: 'qtr' }))
    // ], [fiscalYearList, fiscalQtrList]);


    function extractFiscalYear(quarterString) {
      const [fiscalYear] = quarterString.split(" ");
      return fiscalYear;
    }
  
    // Flattened options for fiscal year and quarter in a single array
    // const flattenedOptions = fiscalYearList.slice()
    // .reverse() // Reverse the order of fiscal years
    // .reduce((acc, year) => {
    //   // Add fiscal year as an option
    //   acc.push({ value: `year_${year.id}`, label: year.name, name: 'year' });
  
    //   // Add quarters as options, matching fiscal years, in reverse order
    //   const quarterOptions = fiscalQtrList
    //     .filter(quarter => extractFiscalYear(quarter.name) === "FY" + year.name.slice(-2))
    //     .slice()
    //     .reverse() // Reverse the order of fiscal quarters
    //     .map(quarter => ({
    //       value: `qtr_${quarter.id}`,
    //       label: quarter.name,
    //       name: 'qtr'
    //     }));
  
    //   return acc.concat(quarterOptions); // Safely concatenate quarters
    // }, []);

    const flattenedOptions = useMemo(() => {
      return fiscalYearList.slice().reverse().reduce((acc, year) => {
        acc.push({ value: `year_${year.id}`, label: year.name, name: 'year' });
        const quarterOptions = fiscalQtrList
          .filter(quarter => quarter.name.includes(year.name.slice(-2))) // Matching fiscal year and quarters
          .map(quarter => ({
            value: `qtr_${quarter.id}`,
            label: quarter.name,
            name: 'qtr'
          }));
        return acc.concat(quarterOptions);
      }, []);
    }, [fiscalYearList, fiscalQtrList]);



    const sortedOptions = [...flattenedOptions].sort((a, b) => {
      // Sort by fiscal year (FY20, FY21, etc.)
      const yearA = a.label.match(/FY(\d+)/);
      const yearB = b.label.match(/FY(\d+)/);
      
      // Extract fiscal year numbers for comparison
      const yearNumA = yearA ? parseInt(yearA[1], 10) : 0;
      const yearNumB = yearB ? parseInt(yearB[1], 10) : 0;
  
      // If both are in the same fiscal year
      if (yearNumA === yearNumB) {
          // Prioritize year over quarters
          if (a.name === 'year' && b.name === 'qtr') return -1;
          if (a.name === 'qtr' && b.name === 'year') return 1;
  
          // Sort quarters in descending order (Q4, Q3, Q2, Q1)
          const quarterA = a.label.match(/Q(\d+)/);
          const quarterB = b.label.match(/Q(\d+)/);
  
          if (quarterA && quarterB) {
              return parseInt(quarterB[1], 10) - parseInt(quarterA[1], 10);
          }
      }
  
      // Sort fiscal years in descending order
      return yearNumB - yearNumA;
  });
  



    const handleZoomIn = () =>{
      
      setZoom(zoom * 1.5)};
    // const handleZoomIn = () => setZoom(zoom * 1.5);
    const handleZoomOut = () => zoom >= 1.5 && setZoom(zoom / 1.5);


    
    const handleChangeMetric = async (event) => {
        if (!event) {
            setCountryData([]);
            return;
        }
        setIsLoading(true);
        setSelectedMetricValue(event.value);
        try {
            const response = await API.get(`/map`, {
                headers: { 'Authorization': 'Bearer ' + token },
                params: {
                    pillar_id: pillarID,
                    // ...(selectionType === 'year' && { fiscal_year: selectedYearOption }),
                    // ...(selectionType === 'qtr' && { fiscal_qtr: selectedQtrOption }),
                    metric_id: event.value
                }
            });
            setCountryData(response.data);
        } catch (error) {
            console.error("Error fetching map data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeYear = async (event) => {
      if (!event) {
          setCountryData([]);
          return;
      }
      setMetric([])
      setIsLoading(true);
      setSelectionType(event.name);
      const [prefix, value] = event.value.split('_');
      try {
        if (prefix === 'year') {
          setSelectedYearOption(event.value);
          const metricResponse = await API.get(`/metric-period`, {
            headers: { 'Authorization': 'Bearer ' + token },
            params: {pillar_id: pillarID ,fiscal_year: value }
          })
          setMetric(metricResponse.data);
        }else if (prefix === 'qtr'){
          const metricResponse = await API.get(`/metric-period`, {
            headers: { 'Authorization': 'Bearer ' + token },
            params: { pillar_id: pillarID , fiscal_qtr: value }
          })
          setMetric(metricResponse.data);
        }
      } catch (error) {
          console.error("Error fetching map data:", error);
      } finally {
          setIsLoading(false);
        }
  };


    useEffect(() => { 
        setMetric([])
        const fetchData = async () => {
            try {
                const projectModulesResponse = await API.get('/project-modules', {
                    headers: { 'Authorization': 'Bearer ' + token }
                });
                const regionCategories = projectModulesResponse.data.filter(item => item.category === 'region');
                setRegionData(regionCategories);
                setRegionParentData(regionCategories);

                const pillarResponse = await API.get(`/pillar?module_id=${id}`, {
                    headers: { 'Authorization': 'Bearer ' + token }
                });
                setPillarID(pillarResponse.data.id);
                const fiscalYearResponse = await API.get(`/fiscal-year-detail`, {
                    headers: { 'Authorization': 'Bearer ' + token }
                });
                setFiscalYearList(fiscalYearResponse.data[0].fiscalyear);
                setFiscalQtrList(fiscalYearResponse.data[1].fiscalquarter);
            } catch (error) {
                console.error("Error fetching initial data:", error);
            }
        };

        fetchData();
    }, [id, token]);

    const isHighlighted = (countryCode) =>
        highlightedCountries.some((country) => country.code === countryCode);
   
    const options = metric.map(m => ({
        value: m.metric,
        label: m.metric_name
    }));


    const [isStateLevel, setIsStateLevel] = useState(false);
    const [isStateLevel1sttime, setIsStateLevel1sttime] = useState(1);
  
    const handleToggle = () => {
      setIsStateLevel(!isStateLevel);
     if(isStateLevel){ setIsStateLevel1sttime(0)}
    };
  
  useEffect(()=>{
   if(isStateLevel==false && isStateLevel1sttime==0) {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
      setIsStateLevel1sttime(1)
    }, 5000);}
  },[isStateLevel])

  const metricSelectRef = useRef(null);
  const yearSelectRef = useRef(null);

  useEffect(() => {
    if (metricSelectRef.current) {
      metricSelectRef.current.clearValue();
    }
    if (yearSelectRef.current) {
      yearSelectRef.current.clearValue();
    }
  }, [id,]);
  useEffect(() => {
    if (metricSelectRef.current) {
      metricSelectRef.current.clearValue();
    }
  }, [selectedYearOption,selectedQtrOption, metric ]);
  
    return (
      <>
        {isLoading && <FullScreenLoader/>}
        <Heading
          as="h3"
          size="lg"
          mb={"25px"}
          fontSize={"19px"}
          mt={"20px"}
          className={Styles.customHeadingH3}
        >
          Geographic Impact
        </Heading>
        <LevelSwitch isStateLevel={isStateLevel} handleToggle={handleToggle} setIsStateLevel={setIsStateLevel}/>
        <Card backgroundColor={"rgb(201 210 211)"} p={"0px"}>
          <CardBody p={"0px"}>
            <Box className={Styles.filterDropDown}>
              <Select
               ref={yearSelectRef}
                styles={customStyles}
                className={`${Styles.marginRight} ${Styles.selectBox}`}
                onChange={handleChangeYear}
                options={sortedOptions}
                placeholder="Select Year"
                isClearable
                />
              <Select
              ref={metricSelectRef}
              className={Styles.selectBox}
                styles={customStyles}
                onChange={handleChangeMetric}
                options={options}
                placeholder="Select Metric"
                isClearable
              />
            </Box>
           
            <Box className={Styles.mapContainer}>
              {isStateLevel===false ? (
              <ComposableMap
                style={{ width: "100%", height: "auto" }}
                projection="geoMercator"
                projectionConfig={{
                  scale: defaultScale * zoom,
                  center: [10, 10], // Adjust center to ensure the map is centered
                }}
              >
                
                  <ZoomableGroup>
                    <Geographies geography={worldData}>
                      {({ geographies }) =>
                        geographies
                          .filter(
                            (geo) => geo.properties.ADMIN !== "Antarctica"
                          )
                          .map((geo) => {
                            const countryCode =
                            geo.properties.ISO_A2 || geo.properties.iso_a2;
                            const fillColor = isHighlighted(countryCode)
                            ? "#00a0da"
                            : "#ffffff";
                            
                            const countryValue = highlightedCountries.find(
                              (country) => country.code === countryCode
                            )?.value;
                            const Mtericdatatype = highlightedCountries.find(
                              (country) => country.code === countryCode
                            )?.metric_type;
                            const newValue = formatNumber(
                              countryValue ? countryValue : 0
                            );
                            const regionMatch = regionData.find(
                              (region) => region.country_code === countryCode
                            );
                            return (
                              <Tooltip
                                key={geo.rsmKey}
                                label={
                                  <div>
                                    <div>
                                      {geo.properties.ADMIN}  {newValue !=0 && ":"} {newValue !=0 && `${Mtericdatatype ? getMetricdatatypeSymbol(Mtericdatatype):""} ${newValue}`} {" "}
                                    </div>
                                  </div>
                                }
                              >
                                <Geography
                                  geography={geo}
                                  fill={fillColor}
                                  stroke="f7f7f7"
                                  strokeWidth={0}
                                  style={{
                                    default: {
                                      outline: "none",
                                    },
                                    pressed: {
                                      outline: "none",
                                    },
                                  }}

                                  onMouseEnter={(event) => {
                                   
                                    setHighlightedState(geo.properties.postal);
                                    
                                  }}
                                  onMouseLeave={() => {
                                   
                                    setHighlightedState(null);
                                  }}
                                />
                              </Tooltip>
                            );
                          })
                      }
                    </Geographies>
                  </ZoomableGroup>
              </ComposableMap>
                ):
                <>
               <MapFOrUSstate countryData={countryData} zoom={zoom} setIsLoading={setIsLoading}/>
                </>
              }
            </Box>
          </CardBody>
          <Box className={Styles.zoomControls}>
              <button className={Styles.zoomIn} onClick={handleZoomIn}>
                {" "}
                <AddIcon w={3} h={3}   />{" "}
              </button>
              <button className={Styles.zoomOut} onClick={handleZoomOut}>
                {" "}
                <MinusIcon w={3} h={3} />{" "}
              </button>
            </Box>
        </Card>
      </>
    );
};

export default GeographicImpact;



const LevelSwitch = ({isStateLevel,handleToggle,setIsStateLevel}) => {


  return (
    <FormControl display="flex" alignItems="center">
      <Text mr={2} mb={2} color={isStateLevel ? "blue.500" : "gray.500"}>
        State Level
      </Text>
      <Switch
        id="level-switch"
        isChecked={!isStateLevel}
        onChange={handleToggle}
        colorScheme="blue"
        size="sm"
      />
      <Text mb={2}  ml={2} color={!isStateLevel ? "blue.500" : "gray.500"}>
        Country Level
      </Text>
    </FormControl>
  );
};



export function FullScreenLoader() {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bg="#000000e3"
      zIndex="9999"
      px={4} 
    >
      <Text fontSize="lg" mb={4} textAlign="center" color={"#fff"}>
        We are loading the maps to provide the best experience. Thank you for your patience.
      </Text>
      <Box width="100%" maxW="600px">
      <Progress size='xs' isIndeterminate />
      </Box>
    </Box>
  );
}






export function getMetricdatatypeSymbol(caseNumber) {
  switch (caseNumber) {
      case "money":
          return '$';
      case "percent":
          return '%';
      case "number":
          return '#';
      
  }
}