import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery'
import JoditEditor from 'jodit-react';
import { 
    Heading, 
    Card, 
    FormControl, 
    FormLabel, 
    Input, 
    Image, 
    Button, 
    CardBody, 
    CardFooter, 
    Spinner,
    Box
} from '@chakra-ui/react';
import API from '../../../../../../../Services/API';
import LoaderSpinner from '../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../Widgets/ErrorWidget/ErrorWidget';

const SlideThree = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const editor = useRef(null);
    const [elrFile, setElrFile] = useState(null);
    const [label, setLabel] = useState(props.label || '');
    const [description, setDescription] = useState(props.description || '');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);

    useEffect(() => {
        setLabel(props.label || '');
        setDescription(props.description || '');
        setTimeout(() => {
            $(".jodit-tabs__wrapper .jodit-tab:nth-child(2)").addClass('jodit-tab_active');
        }, 100); 
    }, [props.label, props.description]);

    const handleBannerUpload = (e) => {
        const selectedFile = e.target.files[0];
        setElrFile(selectedFile);
    };

    const handleSave = async (event) => {
        setIsLoading(true);
        setInfoMessage(null);
        setSuccessMessage(null);
        setErrorMessage(null);

        if (label !== props.label || description !== props.description || elrFile) {
            const formData = new FormData();
            formData.append('label', label);
            formData.append('description', description);
            formData.append('action', parseInt(props.pillarId));
            formData.append('id', event.target.id);
            //formData.append('approval_status', 'Submitted');
            formData.append('approval_status','Ready to Submit')

            if (elrFile) {
                formData.append('banner', elrFile);
            }

            try {
                await API.put(`/executive-level-review`, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                props.getUpdate();
                setSuccessMessage('Changes updated successfully.');
                props.getslide3Update();
            } catch (error) {
                setErrorMessage(error.response?.data?.msg || 'An error occurred');
            }
        } else {
            setInfoMessage('No changes are made');
        }
        setIsLoading(false);
    };


    return (
        <>
            {isLoading && <LoaderSpinner />}
            
            {errorMessage !== null ? <ErrorWidget message={errorMessage} /> :  null}
            {successMessage !== null ? <ErrorWidget message={successMessage} /> :  null}
            {infoMessage !== null ? <ErrorWidget message={infoMessage} /> :  null}

            <CardBody className={'elrForm'}>
                <FormControl isRequired>
                    <FormLabel fontWeight="bold">Label</FormLabel>
                    <Input
                        type="text"
                        id={`elr_label${props.id}`}
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                        mb="15px"
                    />
                </FormControl>
                <FormControl>
                    <FormLabel fontWeight="bold">Icon</FormLabel>
                    <Input
                        type="file"
                        id={`bannerUpload${props.id}`}
                        onChange={handleBannerUpload}
                        padding="4px"
                    />
                    {props.banner && (
                        <Image
                            src={props.banner}
                            width="60px"
                            mt="15px"
                            alt={props.slideData?.heading1}
                        />
                    )}
                </FormControl>
                <FormControl>
                    <FormLabel fontWeight="bold">Description</FormLabel>
                    <JoditEditor
                        ref={editor}
                        value={description}
                        tabIndex={1}
                        onChange={(newContent) => setDescription(newContent)}
                    />
                </FormControl>
            </CardBody>
            <CardFooter>
                <Button
                    className="buttonPrimary"
                    onClick={handleSave}
                    id={props.id}
                    isLoading={isLoading}
                >
                    Save
                </Button>
            </CardFooter>
        </>
    );
};

export default SlideThree;