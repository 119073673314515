import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import CustomStyles from './Heading.module.css'


import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'

const HeadingOne = (props) => {
    return (
        <>
            {
                <Box className={CustomStyles.sideBox}>
                    <Heading as={'h3'} mb={'10px'} className={CustomStyles.newH3}> {props.heading} </Heading>
                    <Text as={'p'}>{props.description}</Text>
                </Box>
            }
        </>    
    )
}

export default HeadingOne