import {React, useEffect, useState} from 'react'
import { Chips } from "primereact/chips";
import Styles from './createUser.module.css'
import API from '../../../../Services/API';
import { PhoneIcon, AddIcon, WarningIcon, } from '@chakra-ui/icons'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    FormControl,
    Text
} from '@chakra-ui/react'

import NewPopup from './NewPopup/NewPopup'

const CreateUser = ({ pattern, chips, placeholder, save, maxlength, title, forwardedRef }) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [value, setValue] = useState([]);
    const [emails, setEmails] = useState()

    const sendInvite = async (e) => {
        try {
            const body = {
                email: value
            };
    
            const response = await API.post('/user-regestration', body, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            const successList = response?.data?.Success || [];
            const failureList = response?.data?.Failure || [];
    
            if (successList.length < 1 && failureList.length < 1) {
                alert('Please enter a valid email address and hit enter.');
            } else if (successList.length > 0 && failureList.length < 1) {
                alert('Invitation email sent to the provided email(s).');
                onClose();
                setValue([]);
            } else if (successList.length < 1 && failureList.length > 0) {
                alert('User already registered or there is an issue. Please try again later.');
                setValue([]);
            }
        } catch (error) {
            console.error('Error sending invite:', error);
            alert('An unexpected error occurred. Please try again later.');
        }
    };

    const getEmail = (e) =>{
        setValue(e.value)
    }

    return (
        <>
            <Button mb={'15px'} className={Styles.invite+ " primaryButtonNew"} onClick={onOpen}> + Invite </Button>
           

            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex}>
                    <ModalHeader>Add email addresses</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <Text className={Styles.note}> Type email address and press Enter ... </Text>
                            <Chips className={Styles.chipsNew} value={value} onChange={(e) => setValue(e.value)} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter} backgroundColor={'transparent'}>
                        <Button mr={3} onClick={onClose} backgroundColor={'transparent'}>
                            Cancel
                        </Button>
                        <Button className='primaryButtonNew' variant='ghost' onClick={sendInvite} backgroundColor={'#ffffff'}>Invite</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CreateUser;
