import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import Styles from './chart.module.css';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const PieChartComponent = ({ metricData }) => {
  if (!Array.isArray(metricData)) {
    console.error('Invalid data:', metricData);
    return <Text>No data available</Text>;
  }

  const processedData = {
    ccr: [],
    breakdown: [],
    fundingRound: [],
    globalImpact: []
  };

  metricData.forEach(item => {
    if (typeof item.name === 'string') {
      if (item.name.includes("CCR GIG by focus area")) {
        processedData.ccr.push(item);
      } else if (item.name.includes("Breakdown by investment phase")) {
        processedData.breakdown.push(item);
      } else if (item.name.includes("Breakdown by Funding Round")) {
        processedData.fundingRound.push(item);
      } else if (item.name.includes("Global Impact Cash Grants")) {
        processedData.globalImpact.push(item);
      }
    }
  });

  const processName = (name) => {
    if (typeof name !== 'string') return '';
    const parts = name.split(' - ');
    return parts.length > 1 ? parts.pop().trim() : name;
  };

  const generateSeriesAndLabels = (data) => {
    const labels = data.map(item => processName(item.name));
    const series = data.map(item =>
      item.chart_value?.chart_top_value?.reduce((acc, curr) => acc + (curr.value || 0), 0) || 0
    );

    const dataTypes = data.map(item => (item.datatype === 'percent' ? " % " : item.datatype === 'money' ? " $ " : null));

    const validIndexes = series.map((value, index) => (value > 0 ? index : -1)).filter(index => index !== -1);
    
    const validSeries = validIndexes.map(index => series[index]);
    const sumOfSeries = validSeries.reduce((acc, curr) => acc + curr, 0);

    return {
      labels: validIndexes.map(index => labels[index]),
      series: validSeries,
      dataTypes: validIndexes.map(index => dataTypes[index]),
      sumOfSeries,
      ids: validIndexes.map(index => data[index].id), // Assuming `data` has `id` property
      names: validIndexes.map(index => data[index].name) // Assuming `data` has `name` property
    };
  };

  const getCustomColors = (length) => {
    const colors = ["#6CB4EE", "#F0F8FF", "#89CFF0", "#318CE7", "#B2FFFF", "#C4D8E2"];
    return colors.slice(0, length);
  };

  const generateOptions = (labels, series, dataTypes, sumOfSeries, ids, names) => {
    const customColors = getCustomColors(labels.length);

    const legendLinks = ids.map((id, index) => `/metric/${id}/${encodeURIComponent(names[index])}`);

    return {
      animationEnabled: true,
      title: {
        verticalAlign: "top",
        fontSize: 14,
        fontWeight: "bold",
        margin: 20,
      },
      toolTip: {
        fontSize: 12,
        fontFamily: "Helvetica, Arial, sans-serif",
        horizontalAlign: "center",
        verticalAlign: "bottom",
        cursor: "pointer",
        itemWrap: false,
        labelWrap: false,
        // maxWidth: 200,
        fontWeight: 'normal',
        itemTextColor: "rgb(55, 61, 63)",
        enabled: true,
        contentFormatter: function (e) {
          return e.entries.map(entry => 
            `${entry.dataPoint.label}: <strong>${entry.dataPoint.y}${entry.dataPoint.dataType} (${entry.dataPoint.percentage}%)</strong>`
          ).join('<br/>');
        }
      },
      legend: {
        fontSize: 12,
        fontFamily: "Helvetica, Arial, sans-serif",
        horizontalAlign: "center",
        verticalAlign: "bottom",
        cursor: "pointer",
        itemWrap: false,
        labelWrap: false,
        // maxWidth: 200,
        fontWeight: 'normal',
        itemTextColor: "rgb(55, 61, 63)",
        itemclick: function(e) {
          // Navigate to the URL corresponding to the clicked legend item
          if (e.dataPoint && e.dataPoint.link) {
            window.open(e.dataPoint.link, '_self');
          }
        }
      },
      data: [{
        type: "pie",
        showInLegend: true,
        legendText: "{label}",
        toolTipContent: "{label}: <strong>{y} ({percentage}%)</strong>",
        indexLabel: "{percentage}%",
        indexLabelPlacement: "inside",
        dataPoints: labels.map((label, index) => ({
          y: series[index],
          label: label,
          dataType: dataTypes[index],
          percentage: ((series[index] / sumOfSeries) * 100).toFixed(2),
          color: customColors[index],
          link: legendLinks[index] // Add the link for the legend item
        }))
      }]
    };
  };

  const ccrData = generateSeriesAndLabels(processedData.ccr);
  const breakdownData = generateSeriesAndLabels(processedData.breakdown);
  const fundingRoundData = generateSeriesAndLabels(processedData.fundingRound);
  const globalImpactData = generateSeriesAndLabels(processedData.globalImpact);

  const charts = [
    { title: "CCR GIG by Focus Area", data: ccrData },
    { title: "Breakdown by Investment Phase", data: breakdownData },
    { title: "Breakdown by Funding Round", data: fundingRoundData },
    { title: "Global Impact Cash Grants", data: globalImpactData }
  ];

  const hasAnyValidData = charts.some((chart) => chart.data.series.length > 0);

  return (
    <Box className={Styles.chartContainer}>
      {hasAnyValidData && (
        <Heading as={'h2'} className={Styles.cardTitleSub}>
          Cisco Crisis Response Overall
        </Heading>
      )}

      <Flex direction="row" wrap="wrap" overflowX="auto" p={0} justify="flex-start" alignItems="flex-start">
        {charts.map((chart, index) => {
          const hasValidData = chart.data.series.length > 0;
          return hasValidData ? (
            <Box
              key={index}
              flex="0 0 auto"
              p={0}
              minWidth="269.8px"
              maxWidth="300px"
              height="350px"  // Fixed height for all charts
              className={Styles.chartBox}
            >
              <Box p={4} height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Text fontSize="lg" mb={4} className={Styles.chartTitle}>{chart.title}</Text>
                <CanvasJSChart 
                  options={generateOptions(chart.data.labels, chart.data.series, chart.data.dataTypes, chart.data.sumOfSeries, chart.data.ids, chart.data.names)} 
                  className={Styles.canvasChart}
                />
              </Box>
            </Box>
          ) : null;
        })}
      </Flex>
    </Box>
  );
};

export default PieChartComponent;