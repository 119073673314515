import React from 'react'

export const formatNumber = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
};

const NumberFormat = ({ value }) => {
    const formattedNumber = formatNumber(value);
    return <span>{formattedNumber}</span>;
}

export default NumberFormat
