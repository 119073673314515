import React, { useEffect, useState } from 'react';
import Styles from './SectionOne.module.css';
import API from '../../../../../../../../../Services/API';
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import HeadingOne from './HeadingOne/HeadingOne';
import MetricInfo from './MetricInfo/MetricInfo';

const SectionOne = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const [elr, setElr] = useState([]);

    useEffect(() => {
        API.get(`/elr-dashboard?action=${props.pillarId}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            setElr(response.data);
        }).catch(error => {
            console.error("Error fetching data: ", error);
        });
    }, [props.pillarId, token]);

    

    return (
        <Box w={'100%'}>
            <Box
                border="1px solid #ccc"
                boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                padding="20px"
                mt={'20px'}
                pb={'5px'}
            >
                {/* <Heading className={Styles.slideHeading} mb={'15px'} background={'#062341'} color={'#ffffff'} padding={'10px'}>
                    Executive Leader Reporting
                </Heading> */}
                {elr && elr.map((item, index) => (
                    <Box key={index} border={'1px solid #e2e8f0'} mb="4">
                        <Heading className={Styles.headingBg}>
                            <Image
                                src={item.banner}
                                alt={item.label}
                                crossOrigin="anonymous"
                                position={'absolute'}
                                width={'100%'}       
                            />
                            <Text as='p' className={Styles.centerAlign}>{item.label}</Text>
                        </Heading>
                        <Box>
                            <Grid h={'auto'} templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={0}>
                                <GridItem rowSpan={2} bg={'#00a0da'}>
                                    <HeadingOne
                                        heading={item.heading1}
                                        description={item.description1}
                                        index={1}
                                    />
                                    <HeadingOne
                                        heading={item.heading2}
                                        description={item.description2}
                                        index={2}
                                    />
                                    <HeadingOne
                                        heading={item.heading3}
                                        description={item.description3}
                                        index={3}
                                    />
                                    <HeadingOne
                                        heading={item.heading4}
                                        description={item.description4}
                                        index={4}
                                    />
                                </GridItem>
                                <MetricInfo 
                                    pillarId={props.pillarId} 
                                    firstFiscalYear={item.firstFiscalYear}
                                    firstFiscalQtr={item.firstFiscalQtr}
                                    editable = {item.editable}
                                    id={item.id}
                                    actionStatus={item.action_status}
                                    getStatus={item.getStatus}

                                    fiscalyearName = {item.fiscal_year_name}
                                    metricResult={item.get_elr_metric_data && item.get_elr_metric_data}
                                />
                            </Grid>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default SectionOne;