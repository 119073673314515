import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { Link, useParams } from 'react-router-dom';
import { Box, Card, CardBody, Image, Heading, Stack, SimpleGrid, Input, Button, Text } from '@chakra-ui/react';
import API from "../../../../../../Services/API";
import BreadCrumbs from "../../../../../../Components/Widgets/BreadCrumbs/BreadCrumbs";
import LoaderSpinner from "../../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import DeleteBlog from "../../../../ImpactGallery/DeleteBlog/DeleteBlog";
import { EditContext } from "../../../../../../Context/BreadcrumbsContext";
import PlaceholderImg from '../../../../../../../assets/images/placeholder-1.png';
import Styles from './listview.module.css';
import ErrorWidget from "../../../../../../Components/Widgets/ErrorWidget/ErrorWidget";

const ListView = () => {
  const token = window.localStorage.getItem("accessToken");
  const { edit, setEdit } = useContext(EditContext);
  const [isLoading, setIsLoading] = useState(false);
  const { tagid, tagname } = useParams();
  const [editable, setEditable] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const [message, setMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const tagInputRef = useRef(null);

  // Fetching tag details and associated blogs
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const tagResponse = await API.get(`/tag-detail/?id=${tagid}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setPageTitle(tagResponse.data.results[0].name);

        const blogResponse = await API.get(`/get-blog?tag_id=${tagid}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setBlogList(blogResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (tagid) fetchData();
  }, [tagid, token]);

  const updateTag = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("id", parseInt(tagid));
      formData.append("name", tagInputRef.current.value);

      await API.put(`/tag-detail/`, formData, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      const updatedTagResponse = await API.get(`/tag-detail/?id=${tagid}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPageTitle(updatedTagResponse.data.results[0].name);
      setMessage(true);
    } catch (error) {
      console.error("Error updating tag:", error);
      setErrorMessage(error.response.data.name ? error.response.data.name :error.response.data.error)
    } finally {
      setIsLoading(false);
    }
  };

  // Memoize the condition to check if the blog list is empty
  const isBlogListEmpty = useMemo(() => blogList.length === 0, [blogList]);

  // Render each blog card
  const BlogCard = ({ item }) => (
    <Box>
      {edit && <DeleteBlog id={item.id} getUpdatedList={setBlogList} getDeleteMessage={setDeleteMessage} />}
      <Link
        className={edit ? Styles.customLinkButtonEdit : Styles.customLinkButton}
        to={`${item.id}/${item.title}`}
      >
        <Card maxW="sm">
          <CardBody p={0}>
            <Image
              src={item.cover_image || PlaceholderImg}
              alt={item.title}
              borderRadius="lg"
              w="100%"
              className={Styles.blogImage}
            />
            <Stack mt="6" spacing="3">
              <Heading size="sm" p="0 10px" className={Styles.listHeading}>
                {item.title}
              </Heading>
            </Stack>
          </CardBody>
        </Card>
      </Link>
    </Box>
  );

  return (
    <>
      {isLoading && <LoaderSpinner />}
      <BreadCrumbs geteditStatus={setEditable} title={pageTitle} />
      {message && <ErrorWidget message={'Updated page title successfully'} />}
      {deleteMessage && <ErrorWidget message={'Deleted Impact Gallery successfully'} />}
      {errorMessage && <ErrorWidget message={errorMessage && errorMessage} />}

      {edit && (
        <Card>
          <CardBody>
            <Input ref={tagInputRef} defaultValue={tagname} id="listview_tagName" />
            <Button className={Styles.save} onClick={updateTag}>Save</Button>
          </CardBody>
        </Card>
      )}

      <SimpleGrid columns={[1, 2, 3, 5]} spacing="20px" mt="10px">
        {blogList.map((item, index) => (
          <BlogCard key={index} item={item} />
        ))}
      </SimpleGrid>
    </>
  );
};

export default ListView;
