import React, { useState, useEffect, useRef, useMemo } from 'react';
import Select from 'react-select';
import Styles from './metricdashboard.module.css'
import $ from 'jquery'
import API from '../../../../Services/API';
import { Link } from 'react-router-dom';
import { Heading, Card, CardBody, Box, Text, Stack, StackDivider, Button, Tabs, TabList, TabPanels, Tab, TabPanel, SimpleGrid} from '@chakra-ui/react'
import OPSMetrics from './OPSMetrics/OPSMetrics'
import Financials from './Financials/Financials';
import ImpactMetrics from './ImpactMetrics/ImpactMetrics'
import BoardMetrics from './BoardMetrics/BoardMetrics'
import AllMetrics from './AllMetrics/AllMetrics'
import SentimentInsights from './SentimentInsights/SentimentInsights';

const MetricDashboard = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading]  = useState(false)
    const [activeTab, setActiveTab] = useState('operational');
    const [fiscalYearList, setFiscalYearList] = useState([])
    const [fiscalQtrList, setFiscalQtrList] = useState([])
    const[filterStatus, setFilterStatus] = useState(0);
    const [filteredMetric, setFilteredMetric] = useState('null');
    const [metricType, setMetricType] = useState('operational');
    const [selectedOption, setSelectedOption] = useState(null);
    const [matchedData, setMatchedData] = useState([]);
    const userData = localStorage.getItem('user');
    const parsedUserData = userData ? JSON.parse(userData) : null;
    
    const [selectedType, setSelectedType] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(()=>{
        API.get(`/fiscal-year-detail`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setFiscalYearList(response.data[0].fiscalyear)
            setFiscalQtrList(response.data[1].fiscalquarter)
        }).catch(error=>{
            setIsLoading(false)
        })
    },[])

    const handleChange = (event) => {
        if (!event) {
            setSelectedOption(null);
            setFilteredMetric([]);
            setFilterStatus(0);
            setSelectedType(null)
            setSelectedValue(null)
            setIsLoading(false);
            return;
        }else{
            setSelectedType(event.name)
            setSelectedValue(event.value.split("_")[1])
            setFilterStatus(1)
        }
    };

    const getMetricType = (metrictype) =>{
        setMetricType(metrictype)
    }
    
    const emptyMetric = () =>{
        setFilterStatus(0)
        setFilteredMetric([])
    }

    const renderTabContent = () => {
        switch (activeTab) {
            case 'operational': return <OPSMetrics selectedValue={selectedValue} selectedType={selectedType} edit={props.editable} />;
            case 'financials': return <Financials  selectedValue={selectedValue} selectedType={selectedType} edit={props.editable} />;
            case 'impactmetrics': return <ImpactMetrics  selectedValue={selectedValue} selectedType={selectedType} edit={props.editable} />;
            case 'allmetrics': return <AllMetrics  selectedValue={selectedValue} selectedType={selectedType} edit={props.editable} />;
            case 'sentimentinsights': return <SentimentInsights />;
            default : return null;
        }
    };

    const flattenedOptions = useMemo(() => {
      return fiscalYearList.slice().reverse().reduce((acc, year) => {
        acc.push({ value: `year_${year.id}`, label: year.name, name: 'year' });
        const quarterOptions = fiscalQtrList
          .filter(quarter => quarter.name.includes(year.name.slice(-2)))
          .map(quarter => ({
            value: `qtr_${quarter.id}`,
            label: quarter.name,
            name: 'qtr'
          }));
        return acc.concat(quarterOptions);
      }, []);
    }, [fiscalYearList, fiscalQtrList]);

    const sortedOptions = [...flattenedOptions].sort((a, b) => {
        const yearA = a.label.match(/FY(\d+)/);
        const yearB = b.label.match(/FY(\d+)/);
        const yearNumA = yearA ? parseInt(yearA[1], 10) : 0;
        const yearNumB = yearB ? parseInt(yearB[1], 10) : 0;
        if (yearNumA === yearNumB) {
            if (a.name === 'year' && b.name === 'qtr') return -1;
            if (a.name === 'qtr' && b.name === 'year') return 1;
            const quarterA = a.label.match(/Q(\d+)/);
            const quarterB = b.label.match(/Q(\d+)/);
            if (quarterA && quarterB) {
                return parseInt(quarterB[1], 10) - parseInt(quarterA[1], 10);
            }
        }
        return yearNumB - yearNumA;
    });

    return (
        <>
            <Heading className={Styles.cardTitle}>Dashboard</Heading> 
            <Text> Here you can see all the SIIO metrics for the selected quarter.</Text>
            <Box mb={'15px'} width={'100%'} maxW={'520px'}>
                <Select
                    onChange={handleChange}
                    options={sortedOptions}
                    isClearable
                />
            </Box>
            <Box mt={'15px'}>
                <Card>
                    <CardBody>
                        <Box className={Styles.tabGroup}>
                            <Button onClick={() => setActiveTab('operational')} className={activeTab === 'operational' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>OPS Metrics</Button>
                            <Button onClick={() => setActiveTab('financials')} className={activeTab === 'financials' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Financials</Button>
                            <Button onClick={() => setActiveTab('impactmetrics')} className={activeTab === 'impactmetrics' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Impact Metrics</Button>
                            <Button onClick={() => setActiveTab('allmetrics')} className={activeTab === 'allmetrics' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>All Metrics</Button>
                            {
                                parsedUserData && parsedUserData.email === 'btippens@cisco.com' ?
                                    <Button onClick={() => setActiveTab('sentimentinsights')} className={activeTab === 'sentimentinsights' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Sentiment Insights</Button> 
                                : null
                            }
                        </Box>
                        <Box className={Styles.tabContent}>
                            {renderTabContent()}
                        </Box>
                    </CardBody>
                </Card>
            </Box>
        </>
    )
}

export default MetricDashboard