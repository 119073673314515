import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from '../../executiveleadershipreviews.module.css'
import API from '../../../../../../Services/API';
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'

const SectionThree = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [profileImage, setProfileImage] = useState(null);
    const [heading, setHeading] = useState(props.label)
    const [description, setDescription] = useState(props.sublabel)

    function sectionImageUpload(e) {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        // Create a file reader to read the file and generate a preview URL
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        } else {
            setPreview(null);
        }
    }
    const changeSubLabel = (event) =>{
        setDescription(event.target.value)
    }

    const saveAction = (event) =>{
        setIsLoading(true)
        const formData = new FormData()
        if(file != null){
            formData.append('footerbanner'+props.index, file)
        }
        formData.append('footer'+props.index, description)
        formData.append('id', parseInt(event.target.id))
        formData.append('pillar', parseInt(props.pillarId))
        API.put(`/impact-story`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/impact-story?pillar=${props.pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                props.getSecondSlideUpdate(response.data)
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
                
                props.getError(error.response.data.msg)
            })
        })
    }

    return (
        <>
            <Box>
                {
                    props.editable ?
                    <FormControl border={'1px solid #cccccc'} p={'10px'} borderRadius={'10px'} mb={'15px'}>
                        <FormLabel fontWeight={'600'} mb={'5px'}> Upload Icon </FormLabel>
                        <Input type="file" title='footer1' onChange={sectionImageUpload} padding={"4px"} mb={'5px'} />
                        <FormLabel fontWeight={'600'} mb={'5px'}> Description </FormLabel>
                        <Textarea placeholder='Please provide description 1' defaultValue={props.description}  onChange={changeSubLabel}  />
                        <Button className='' title='footer1' onClick={saveAction} mt={'10px'} float={'right'} id={props.id} backgroundColor={'#ffffff'} color={'#00a0da'}> Save </Button>
                        <Box className='clearfix'></Box>
                    </FormControl> : 
                    <Box>
                        <Flex>
                            <Avatar src={props.banner} />
                            <Box ml='3'>
                                <Text fontSize='sm'>{props.description}</Text>
                            </Box>
                        </Flex>
                    </Box>
                }
            </Box>
            
        </>
    )
}

export default SectionThree