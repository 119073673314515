import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import Styles from '../preview.module.css'
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import API from '../../../../../../Services/API';
import LoaderSpinner from '../../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const HeadingOne = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading] = useState(false)
    const [heading, setHeading] = useState(props.heading)
    const [description, setDescription] = useState(props.description)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const saveAction = (event) =>{
        setIsLoading(true)
        const formData = new FormData()
        formData.append('heading'+props.index, heading)
        formData.append('description'+props.index, description)
        formData.append('id', parseInt(event.target.id))
        formData.append('action', parseInt(props.pillarId))

        API.put(`/elr-dashboard`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            props.getUpdateData()
            setSuccessMessage("Changes updated successfully.")
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })
    }
    const changeHeading = (event) =>{
        setHeading(event.target.value)
    }
    
    const changeDescription = (event) =>{
        setDescription(event.target.value)
    }

    return (
        <>
            {
                isLoading ? <LoaderSpinner /> : null
            }
            {
                props.editable ? 
                <Box p={'5px'}>
                    {successMessage !== null ? <Box color={'#00a0da'}> {successMessage} </Box> : null}
                    {errorMessage !== null ? <Box color={'red'}> {errorMessage && errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()} </Box> : null}
                    <FormLabel fontWeight={'600'}> Heading </FormLabel>
                    <Input placeholder={'Please provide heading '+props.index} defaultValue={heading} mb={'10px'} onChange={changeHeading} />
                    <FormLabel fontWeight={'600'}> Description </FormLabel>
                    <Input placeholder={'Please provide description '+props.index} defaultValue={description} mb={'10px'} onChange={changeDescription} />
                    <Button className='buttonPrimary' float={'right'} id={props.id} onClick={saveAction}> Save </Button>
                    <Box className='clearfix'></Box>
                </Box>
                : 
                <Box className={Styles.sideBox}>
                    <Heading as={'h3'} mb={'10px'}> {props.heading} </Heading>
                    <Text as={'p'}>{props.description}</Text>
                </Box>
            }
        </>    
    )
}

export default HeadingOne