import React, { useState, useEffect, useContext } from 'react';
import API from '../../../Services/API';
import Styles from '../mytasks.module.css';
import { EditContext } from "../../../Context/BreadcrumbsContext";
import { Box, Button } from '@chakra-ui/react';
import ExecutiveLeaderReporting from './ExecutiveLeaderReporting/ExecutiveLeaderReporting';
import ImpactStories from './ImpactStories/ImpactStories';
import ExecutiveLevelReview from './ExecutiveLevelReview/ExecutiveLevelReview';

const ElrPending = () => {
    const token = window.localStorage.getItem("accessToken");
    const [editable, setEditable] = useState(false);
    const { edit, setEdit } = useContext(EditContext);
    const [profile, setProfile] = useState(null);
    const [commsUserType, setCommsUserType] = useState(false);
    const [actionOfficeUserType, setActionOfficeUserType] = useState(false);
    const [superUserType, setSuperUserType] = useState(false);
    const [activeTab, setActiveTab] = useState('executiveleaderreporting'); // Default value

    const renderTabContent = () => {
        switch (activeTab) {
            case 'executiveleaderreporting':
                return <ExecutiveLeaderReporting />;
            case 'impactstories':
                return <ImpactStories />;
            case 'executivelevelreview':
                return <ExecutiveLevelReview />;
            default:
                return null;
        }
    };

    const fetchUserProfile = async () => {
        try {
            const response = await API.get(`/user-management`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setProfile(response.data);
            setCommsUserType(response.data.comms_user);
            setSuperUserType(response.data.is_superuser)
            setActionOfficeUserType(response.data.action_office_user)

        } catch (error) {
            console.error('Error fetching user management data:', error);
        }
    };

    // Update activeTab after commsUserType is fetched
    useEffect(() => {
        if (commsUserType && !actionOfficeUserType && !superUserType) {
            setActiveTab('impactstories');
        }
    }, [commsUserType]);

    useEffect(() => {
        fetchUserProfile();
    }, []);

    return (
        <Box>
            <Box>
                {commsUserType && !actionOfficeUserType && !superUserType ? (
                    <Button
                        onClick={() => setActiveTab('impactstories')}
                        className={activeTab === 'impactstories' ? `${Styles.active} ${Styles.tabStyle}` : Styles.tabStyle}
                    >
                        Impact Stories
                    </Button>
                ) : (
                    <>
                        <Button
                            onClick={() => setActiveTab('executiveleaderreporting')}
                            className={activeTab === 'executiveleaderreporting' ? `${Styles.active} ${Styles.tabStyle}` : Styles.tabStyle}
                        >
                            Executive Leader Reporting
                        </Button>
                        <Button
                            onClick={() => setActiveTab('impactstories')}
                            className={activeTab === 'impactstories' ? `${Styles.active} ${Styles.tabStyle}` : Styles.tabStyle}
                        >
                            Impact Stories
                        </Button>
                        <Button
                            onClick={() => setActiveTab('executivelevelreview')}
                            className={activeTab === 'executivelevelreview' ? `${Styles.active} ${Styles.tabStyle}` : Styles.tabStyle}
                        >
                            Executive Level Review
                        </Button>
                    </>
                )}
            </Box>
            <Box className={Styles.tabContent}>{renderTabContent()}</Box>
        </Box>
    );
};

export default ElrPending;
