import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import Styles from '../../executiveleadershipreviews.module.css'
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import API from '../../../../../../../../Services/API';
import LoaderSpinner from '../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../../Widgets/ErrorWidget/ErrorWidget';
const SectionOne = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [profileImage, setProfileImage] = useState(null);
    const [heading, setHeading] = useState(props.label)
    const [description, setDescription] = useState(
        (props.sublabel !== 'null' && props.sublabel !== null) ? props.sublabel : ''
      );
    const [errorMessage, setErrorMessage] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    const [infoMessage, setInfoMessage] = useState(null);

    function sectionImageUpload(e) {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        } else {
            setPreview(null);
        }
    }
    
    const saveContent = (event) =>{
        setIsLoading(true)
        setInfoMessage(null)
        setSuccessMessage(null)
        setErrorMessage(null)
        
        if((heading !== props.label) || (description !== props.sublabel) || (file !== null) ){
            const formData = new FormData()
            if(file != null){
                formData.append('banner', file)
            }
            formData.append('label', heading)
            formData.append('sublabel', description)
            formData.append('id', parseInt(event.target.id))
            formData.append('action', parseInt(props.pillarId))
            formData.append('approval_status','Ready to Submit')
            API.put(`/impact-story`, formData, {
                headers: {
                'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                API.get(`/impact-story?action=${props.pillarId}`, {
                    headers: {
                    'Authorization': 'Bearer ' + token
                    }
                }).then(response=>{
                    props.getSecondSlideUpdate(response.data)
                    props.getslide2Update()
                    setSuccessMessage('Changes updated successfully.')
                    setIsLoading(false)
                }).catch(error=>{
                    setErrorMessage(error.response.data.msg)
                    setIsLoading(false)
                })            
            }).catch(error =>{
                setErrorMessage(
                    error.response.data.msg ? error.response.data.msg :
                    error.response.data.sublabel ? error.response.data.sublabel : ''
                )
                setIsLoading(false)
            })
        }else{
            setInfoMessage('No changes are made')
            setIsLoading(false)
        }
    }

    const changeLabel = (event) =>{
        setHeading(event.target.value)
    }
    
    const changeSubLabel = (event) =>{
        setDescription(event.target.value)
    }

    

    return (
        <>
            {
                isLoading ? <LoaderSpinner /> : null
            }

            
            {
                errorMessage !== null ? <ErrorWidget message={errorMessage} /> : null
            }
            {
                successMessage !== null ?  <ErrorWidget message={successMessage} /> : null
            }
            {
                infoMessage !== null ? <ErrorWidget message={infoMessage} /> : null
            }

            {
                props.editable ? 
                <Box p={'5px'}>
                    <FormControl border={'1px solid #cccccc'} p={'10px'} borderRadius={'10px'} mb={'15px'}>
                        <FormLabel fontWeight={'600'} mb={'5px'}> Label </FormLabel>
                        <Input defaultValue={heading} placeholder='Please provide label' mb={'5px'} onChange={changeLabel} />
                        <FormLabel fontWeight={'600'} mb={'5px'}> Section Image </FormLabel>
                        <Input type="file" title='mainSection' onChange={sectionImageUpload} padding={"4px"} mb={'5px'} />
                        <FormLabel fontWeight={'600'} mb={'5px'}> Profile Name </FormLabel>
                        <Input defaultValue={description} placeholder='Please provide sublabel' mb={'5px'} onChange={changeSubLabel} />
                        <Button className='buttonPrimary' title='mainsection' onClick={saveContent} mt={'10px'} float={'right'} id={props.id}> Save </Button>
                        <Box className='clearfix'></Box>
                    </FormControl>
                </Box>:
                <>
                    <Heading className={Styles.leftGridHeading}>
                        {props.label}
                    </Heading>
                    <Image className={Styles.profileImage} src={props.banner} alt={props.sublabel} crossOrigin="anonymous" />
                    <Text as={'p'} className={Styles.profileName}> {description} </Text>
                </>
            }
        </>
    )
}

export default SectionOne