import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from '../executiveleadershipreviews.module.css'
import API from '../../../../../Services/API';
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import Select from 'react-select';
import LoaderSpinner from '../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import {useDisclosure} from '@chakra-ui/react'
import HeadingOne from './HeadingOne/HeadingOne';
import MetricInfo from './MetricInfo/MetricInfo';
import FiscalYear from './FiscalYear/FiscalYear';



const SlideOne = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState(false)
    const [elrSectionOne, setElrSectionOne] = useState()
    const [elrMetric, setElrMetric] = useState()
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [viewOneId, setViewOneId] = useState(null);
    const [impactStories, setImpactStories] = useState(null);
    const [elr, setElr] = useState(null);
    const [noData, setNoData] = useState(0);
    const editor = useRef(null);
    const [status, setStatus] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const [elrFile, setElrFile] = useState(null);
    const [submitMessage, setSubmitMessage] = useState(null);

    

    function bannerUpload(e) {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        // Create a file reader to read the file and generate a preview URL
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        } else {
            setPreview(null);
        }
    }

    const saveHeader = () =>{
        setIsLoading(true)
        const formData = new FormData()
        if(file != null){
            formData.append('banner', file)
        }
        formData.append('label', $("#headerLabel").val())
        formData.append('id', parseInt(props.slideData && props.slideData.id))
        formData.append('pillar', parseInt(props.pillarId))
        API.put(`/elr-dashboard`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/elr-dashboard?pillar=${props.pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                setElrSectionOne(response.data)
                setPreview(response.data && response.data[0].banner)
                setViewOneId(response.data && response.data[0].id)
                setStatus(response.data && response.data[0].approval_status)
                setIsLoading(false)
                setNoData(0)
            }).catch(error=>{
                 
                setIsLoading(false)
            })
        }).catch(error=>{
            setErrorMessage(error.response.data.msg)
            setIsLoading(false)
        })
    }

    const getError = (error) =>{
        setErrorMessage(error)
    }

    return (
        <>
            {
                errorMessage !== null ? <Box mb={'10px'} color={'red'}> {errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()} </Box> : null
            }
            <Box border={'1px solid #e2e8f0'}>
                <Heading className={Styles.headingBg} style={{backgroundImage: `url(${props.slideData && props.slideData.banner})`}}>
                    {
                        props.editable ?
                            <SimpleGrid columns={[3]}>
                                <Box p={'10px'}>
                                    <Input placeholder='Please provide banner heading' defaultValue={props.slideData && props.slideData.label} mb={'5px'} backgroundColor={'#ffffff'} id={'headerLabel'} />
                                </Box> 
                                <Box p={'10px'}>
                                    <Input type="file" onChange={bannerUpload} padding={"4px"}/>
                                </Box>
                                <Box p={'10px'}>
                                    <Button className='buttonPrimary' onClick={saveHeader}> Save </Button>
                                </Box>
                            </SimpleGrid> 
                        : <Text as='p' className={Styles.centerAlign}>{props.slideData && props.slideData.label}</Text>
                    }
                </Heading>
                <Box>
                    <Grid h={props.editable ? 'auto' : 'auto'} templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={0}>
                        <GridItem rowSpan={2} bg={props.editable ? '#ffffff' : '#00a0da'}>
                            <FiscalYear
                                editable = {props.editable}
                                heading={props.slideData && props.slideData.heading1}
                                description={props.slideData && props.slideData.description1}
                                pillarId={props.pillarId}
                                id={props.slideData && props.slideData.id}
                                slideOneUpdate={props.slideOneUpdate}
                                getError={getError}
                                slideType={'slide1'}
                            />

                            <HeadingOne
                                editable = {props.editable}
                                heading={props.slideData && props.slideData.heading1}
                                description={props.slideData && props.slideData.description1}
                                pillarId={props.pillarId}
                                id={props.slideData && props.slideData.id}
                                slideOneUpdate={props.slideOneUpdate}
                                getError={getError}
                                index={1}
                            />
                            <HeadingOne
                                editable = {props.editable}
                                heading={props.slideData && props.slideData.heading2}
                                description={props.slideData && props.slideData.description2}
                                pillarId={props.pillarId}
                                id={props.slideData && props.slideData.id}
                                slideOneUpdate={props.slideOneUpdate}
                                getError={getError}
                                index={2}
                            />
                            <HeadingOne
                                editable = {props.editable}
                                heading={props.slideData && props.slideData.heading3}
                                description={props.slideData && props.slideData.description3}
                                pillarId={props.pillarId}
                                id={props.slideData && props.slideData.id}
                                slideOneUpdate={props.slideOneUpdate}
                                getError={getError}
                                index={3}
                            />
                            <HeadingOne
                                editable = {props.editable}
                                heading={props.slideData && props.slideData.heading4}
                                description={props.slideData && props.slideData.description4}
                                pillarId={props.pillarId}
                                id={props.slideData && props.slideData.id}
                                slideOneUpdate={props.slideOneUpdate}
                                getError={getError}
                                index={4}
                            />
                        </GridItem>
                        <MetricInfo pillarId={props.pillarId} />
                    </Grid>
                </Box>
            </Box>
            
        </>
    )
}

export default SlideOne