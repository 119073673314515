import React, { useState, useEffect } from 'react';
import { Box, FormControl, FormLabel, Input, Textarea, Button, Flex, Image, Text } from '@chakra-ui/react';
import LoaderSpinner from '../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../../Widgets/ErrorWidget/ErrorWidget';
import API from '../../../../../../../../Services/API';
import Styles from '../../executiveleadershipreviews.module.css';

const SectionThree = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState(props.description);
    const [messages, setMessages] = useState({ error: null, success: null, info: null });

    useEffect(() => {
        // Cleanup function to prevent memory leaks
        return () => {
            setIsLoading(false);
        };
    }, []);

    const sectionImageUpload = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        
        // Validate file type (optional)
        if (selectedFile && !selectedFile.type.startsWith('image/')) {
            setMessages({ ...messages, error: 'Please upload a valid image file.' });
            setFile(null);
            return;
        }
        
        const reader = new FileReader();
        reader.onloadend = () => setPreview(reader.result);
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        } else {
            setPreview(null);
        }
    };

    const changeSubLabel = (event) => {
        setDescription(event.target.value);
    };

    const saveAction = async (event) => {
        setIsLoading(true);
        setMessages({ error: null, success: null, info: null });

        // Check if there are no changes made
        const isDescriptionUnchanged = description === props.description;
        const isFileUnchanged = file === null;

        if (isDescriptionUnchanged && isFileUnchanged) {
            setMessages({ ...messages, error: 'No changes have been made to save.' });
            setIsLoading(false);
            return;
        }


        const formData = new FormData();
        if (file) {
            formData.append('footerbanner' + props.index, file);
        }
        formData.append('footer' + props.index, description);
        formData.append('id', parseInt(event.target.id));
        formData.append('action', parseInt(props.pillarId));
        formData.append('approval_status', 'Ready to Submit');

        try {
            await API.put(`/impact-story`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            });
            
            const response = await API.get(`/impact-story?action=${props.pillarId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            });

            props.getSecondSlideUpdate(response.data);
            props.getslide2Update();
            setMessages({ ...messages, success: 'Changes updated successfully.' });
            setFile(null); // Reset file state
            setDescription(props.sublabel); // Reset description state
        } catch (error) {
            setMessages({ ...messages, error: error.response?.data?.msg || 'An error occurred.' });
            props.getError(error.response?.data?.msg);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {messages.error && <ErrorWidget message={messages.error} />}
            {messages.success && <ErrorWidget message={messages.success} />}
            {messages.info && <ErrorWidget message={messages.info} />}
            <Box>
                {props.editable ? (
                    <FormControl border="1px solid #cccccc" p="10px" borderRadius="10px" mb="15px">
                        <FormLabel fontWeight="600" mb="5px">Upload Icon</FormLabel>
                        <Input type="file" title="footer1" onChange={sectionImageUpload} padding="4px" mb="5px" />
                        <FormLabel fontWeight="600" mb="5px">Description</FormLabel>
                        <Textarea placeholder="Please provide description 1" value={description} onChange={changeSubLabel} />
                        <Button title="footer1" onClick={saveAction} mt="10px" float="right" id={props.id} backgroundColor="#ffffff" color="#00a0da">Save</Button>
                        <Box className="clearfix"></Box>
                    </FormControl>
                ) : (
                    <Box className={Styles.ftrMnContainer}>
                        <Flex align="center" id="middleContent">
                            {props.banner && <Image src={props.banner} boxSize="50px" crossOrigin="anonymous" alt="Description" border="none" />}
                            <Text fontSize="sm" mb="0">{props.description}</Text>
                        </Flex>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default SectionThree;
