import React, { useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { IconButton, Box } from '@chakra-ui/react';
import {Heading, Tooltip, Select, SimpleGrid, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import { ViewIcon, CloseIcon } from '@chakra-ui/icons';
import Styles from '../../../MetricData/metricdata.module.css';
import API from '../../../../../Services/API';
import Preview from './Preview';

const View = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const user =  JSON.parse(window.localStorage.getItem('user'))
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [edit, setEdit] = useState(false)
    const [paneltitle, setPaneltitle] = useState(props.title);

    const togglePane = () => {
        setIsPaneOpen(prevState => !prevState);
    };

    const enableEdit = (event) =>{
        setEdit(event.target.checked)
    }

    // API call to be triggered when the pane closes
    const handleApiCallOnClose = async () => {
        API.get(`/executive-level-review`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
          props.getNewUpdate(response)
        }).catch(error=>{
          props.getErrorUpdateNew(error)
        })
    };

    // Handle the pane close event and make the API call
    const handleClosePane = () => {
        setEdit(false);
        togglePane(); // This will close the pane
        handleApiCallOnClose(); // Call the API after closing the pane
    };

    const getUpdatedTitle = (updatedTitle) =>{
        setPaneltitle(updatedTitle)
    }

    return (
        <>
            <Box className={'vieElr'}>
                <Tooltip id={props.id} label="View" aria-label="View"> 
                    <IconButton
                        variant='outline'
                        colorScheme='teal'
                        aria-label='View details'
                        fontSize='14px'
                        icon={<ViewIcon />}
                        className={`${Styles.approve} ${Styles.hoverEffectApprove}`}
                        id={props.id}
                        onClick={togglePane}
                        ml={'5px'}
                    />       
                </Tooltip>
                
                <SlidingPane
                    closeIcon={<span className='closeTooltip'> <CloseIcon /> </span>} 
                    isOpen={isPaneOpen}
                    onRequestClose={handleClosePane} // Call custom handler when closing the pane
                    from="right" // Pane direction
                    paneClassName={Styles.paneClass} // Optional: Add a custom class for additional styling
                    title={paneltitle}
                >
                    <Box>
                        <Heading className={Styles.slideHeading}> 
                            <FormControl display='flex' alignItems='center'>
                                <FormLabel htmlFor='email-alerts' mb='20px' fontSize={'25px'}>
                                    Executive Level Review
                                </FormLabel>
                                {
                                    user && user.action_office_user? (
                                        <Switch id='email-alerts' onChange={enableEdit} />
                                    ) : null
                                }
                            </FormControl>
                        </Heading>
                        <Preview
                            id={props.id}
                            pillar={props.pillar}
                            title={props.label} 
                            editable={edit && edit} 
                            getUpdatedTitle={getUpdatedTitle}
                        />
                    </Box>
                </SlidingPane>
            </Box>            
        </>
    );
};

export default View;