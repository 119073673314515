import React, { useState, useEffect } from 'react';
import { Box, Text, VStack, List, ListItem, Divider, Spinner } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import API from '../../Services/API';
import LoaderSpinner from '../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const SearchResults = () => {
    const token = window.localStorage.getItem("accessToken");
    const location = useLocation(); // Access the current location
    const [query, setQuery] = useState(''); // Store the current search query
    const [results, setResults] = useState({}); // Store grouped search results
    const [metrics, setMetrics] = useState([]);
    const [pillars, setPillars] = useState([]);
    const [pillarActions, setPillarActions] = useState([]);
    const [blogList, setBlogList] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state

    // Extract the search query from the URL
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const searchQuery = params.get('q') || ''; // Get the "q" query parameter
        setQuery(searchQuery);
    }, [location]);

    // Fetch all data on component mount
    useEffect(() => {
        const fetchData = async () => {
        setIsLoading(true); // Start loading
        try {
            const [metricsRes, pillarsRes, actionsRes, blogsRes] = await Promise.all([
            API.get('/all-metric-dashboard', { headers: { Authorization: `Bearer ${token}` } }),
            API.get('/sio-pillar', { headers: { Authorization: `Bearer ${token}` } }),
            API.get('/get-pillar-action', { headers: { Authorization: `Bearer ${token}` } }),
            API.get('/blog', { headers: { Authorization: `Bearer ${token}` } }),
            ]);
            setMetrics(metricsRes.data);
            setPillars(pillarsRes.data);
            setPillarActions(actionsRes.data);
            setBlogList(blogsRes.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
        };
        fetchData();
    }, [token]);

    // Filter results based on the query
    useEffect(() => {
        if (query) {
        const groupedResults = {
            Metrics: metrics.filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase())
            ),
            "SIIO Organization": pillars.filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase())
            ),
            Actions: pillarActions.filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase())
            ),
            "Impact Gallery": blogList.filter((item) =>
            item.title.toLowerCase().includes(query.toLowerCase())
            ),
        };
        setResults(groupedResults);
        } else {
        setResults({});
        }
    }, [query, metrics, pillars, pillarActions, blogList]);

    return (
        <Box p={4}>
            {isLoading ? <LoaderSpinner /> : null}
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
                Search Results
            </Text>
            <Text fontSize="md" mb={4}>
                Showing results for: <strong>{query}</strong>
            </Text>

            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                    <Spinner size="xl" color="teal.500" />
                </Box>
            ) : Object.keys(results).length > 0 ? (
                <VStack align="start" spacing={4}>
                    {Object.entries(results).map(([group, items], index) => (
                        <Box key={index} w="100%">
                            <Text fontSize="lg" fontWeight="bold" color="teal.600">
                                {group}
                            </Text>
                            <List spacing={2}>
                                {items.map((item, itemIndex) => (
                                    <ListItem
                                        key={itemIndex}
                                        p={2}
                                        borderRadius="md"
                                        _hover={{ bg: 'teal.50', cursor: 'pointer' }}
                                    >
                                        <a
                                            href={
                                                group === "Actions"
                                                ? `/action/${item.module}/${encodeURIComponent(item.name)}`
                                                : group === "Impact Gallery"
                                                ? `/viewpost/${item.id}/${encodeURIComponent(item.title)}`
                                                : `/metric/${item.id}/${encodeURIComponent(item.name)}`
                                            }
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {group === "Impact Gallery" ? item.title : item.name}
                                        </a>
                                    </ListItem>
                                ))}
                            </List>
                            {index < Object.entries(results).length - 1 && <Divider my={4} />}
                        </Box>
                    ))}
                </VStack>
            ) : (
                <Text fontSize="lg" color="gray.500">
                    No results found for "{query}".
                </Text>
            )}
        </Box>
    );
};

export default SearchResults;
