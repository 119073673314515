import React, { useState, useEffect } from 'react';
import API from '../../../../Services/API';
import Styles from './regionmap.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Card, CardBody, Box, Button, Tooltip } from '@chakra-ui/react';
import {
    ComposableMap,
    Geographies,
    Geography,
    ZoomableGroup
} from 'react-simple-maps';
import worldData from './world.geojson';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import NumberFormat, { formatNumber } from './NumberFormat'

const RegionMap = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const [countryData, setCountryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();
    const defaultScale = 180;
    const [zoom, setZoom] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [fiscalYearList, setFiscalYearList] = useState()
    const [fiscalQtrList, setFiscalQtrList] = useState()
    const [countries, setCountries] = useState()
    const [pillarActions, setPillarActions] = useState()


    const handleZoomIn = () => {
        setZoom(prevZoom => Math.min(prevZoom * 1.5, 10)); // Set max zoom level
    };

    const handleZoomOut = () => {
        setZoom(prevZoom => Math.max(prevZoom / 1.5, 1)); // Set min zoom level
    };

    useEffect(() => {
        API.get(`/blog-count`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setCountryData(response.data.data);
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching country data:', error);
            setLoading(false);
        });
        API.get(`/fiscal-year-detail`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setFiscalYearList(response.data[0].fiscalyear)
            setFiscalQtrList(response.data[1].fiscalquarter)
        }).catch(error=>{
            setLoading(false);
        })
        API.get(`/country-list`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            setCountries(response.data)
        }).catch(error=>{
            //setIsLoading(false)
        })
        API.get(`/get-pillar-action`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
            setPillarActions(response.data && response.data)
            setLoading(false)
        }).catch(error=>{
            setLoading(false)
        })

    }, [id, token]);

    const highlightedCountries = countryData.map((item) => (
        { code: item.country__country_code, value: item.blog_count, country: item.country }
    ));

    const isHighlighted = (countryCode) => highlightedCountries.some((country) => country.code === countryCode);
    

    const handleCountryClick = (countryProperties) => {
        const countryCode = countryProperties.ISO_A2 || countryProperties.iso_a2;
        const countryName = countryProperties.ADMIN;
        const countryValue = highlightedCountries.find((country) => country.code === countryCode)?.value || '0';
        const countryId = highlightedCountries.find((country) => country.code === countryCode)?.country || '0';

        //alert(`You clicked on ${countryName} with a value of ${countryValue} and ${countryId}`);
        // Optionally navigate to a different route
        //portfolio/ig/${id}/${name}/
        navigate(`/portfolio/ig/${id}/impactgallery/country/${countryId}/${countryName}`);
    };

    

    return (
        <>
            <Heading as='h3' size='lg' mb='25px' fontSize='19px' mt='20px' className={Styles.customHeadingH3}>
                Geographic Impact 
            </Heading>
            <Card backgroundColor='rgb(201 210 211)' p='0px'>
                <CardBody p='0px'>
                    
                    <Box className={Styles.zoomControls}>
                        <button className={Styles.zoomIn} onClick={handleZoomIn}> <AddIcon w={3} h={3} /> </button>
                        <button className={Styles.zoomOut} onClick={handleZoomOut}> <MinusIcon w={3} h={3} /> </button>
                    </Box>
                    {loading ? (
                        <Box>Loading...</Box>
                    ) : (
                        <ComposableMap
                            style={{ flex: 1, width: '100%', height: '600px',}}
                            projection="geoMercator"
                            projectionConfig={{
                            scale: defaultScale * zoom,
                            center: [0, 0],
                            }}
                        >
                            <ZoomableGroup>
                                <Geographies geography={worldData}>
                                    {({ geographies }) =>
                                        geographies.filter(geo => geo.properties.ADMIN !== "Antarctica").map((geo) => {
                                            const countryCode = geo.properties.ISO_A2 || geo.properties.iso_a2; // Check both upper and lower case
                                            const isSelected = isHighlighted(countryCode);
                                            const countryValue = highlightedCountries.find((country) => country.code === countryCode)?.value || '0';
                                            const strokeColor = isSelected ? '#000000' : '#D6D6DA';
                                            const fillColor = isHighlighted(countryCode) ? '#00a0da' : '#ffffff';
                                            const newValue = formatNumber(countryValue ? countryValue : 0);
                                            const isCountryHighlighted = isHighlighted(countryCode);

                                            return (
                                                <>
                                                    {
                                                        isCountryHighlighted ? (
                                                            <Tooltip overflow={"none"}  background={"transparent"} color={"#2b2b2b"} fontSize={"14px"} boxShadow={"none"} label={geo.properties.ADMIN +' - '+ newValue} key={geo.rsmKey}>
                                                                <Geography
                                                                    key={geo.rsmKey}
                                                                    geography={geo}
                                                                    data-tip="Static Tooltip Content"
                                                                    data-for="country-tooltip"
                                                                    style={{
                                                                        default: {
                                                                            fill: fillColor,
                                                                            outline: 'none',
                                                                        },
                                                                        hover: isCountryHighlighted ? {
                                                                            fill: '#D2D2D2',
                                                                            outline: 'none',
                                                                            filter: "drop-shadow(0px 1px 1px #2c2c2c50)",
                                                                            cursor:'pointer'
                                                                        } : {
                                                                            fill: fillColor,
                                                                        },
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        ) 
                                                    : 
                                                        <Geography
                                                            key={geo.rsmKey}
                                                            geography={geo}
                                                            data-tip="Static Tooltip Content"
                                                            data-for="country-tooltip"
                                                            // style={{
                                                            //     default: {
                                                            //         fill: fillColor,
                                                            //         outline: 'none',
                                                            //     },
                                                            //     hover: {
                                                            //         fill: '#D2D2D2',
                                                            //         outline: 'none',
                                                            //         filter:"drop-shadow(0px 1px 1px #2c2c2c50)",
                                                
                                                            //     },
                                                            // }}
                                                            style={{
                                                                default: {
                                                                    fill: fillColor,
                                                                    outline: 'none',
                                                                },
                                                                hover: isCountryHighlighted ? {
                                                                    fill: '#D2D2D2',
                                                                    outline: 'none',
                                                                    filter: "drop-shadow(0px 1px 1px #2c2c2c50)",
                                                                    cursor:'pointer'
                                                                } : {
                                                                    fill: fillColor,
                                                                },
                                                            }}
                                                        />
                                                    }
                                                </>
                                                // <Tooltip key={geo.rsmKey} label={`${geo.properties.ADMIN} - ${countryValue}`}>
                                                //     <Geography
                                                //         geography={geo}
                                                //         style={{
                                                //             default: {
                                                //                 fill: fillColor,
                                                //                 outline: 'none',
                                                //             },
                                                //             hover: {
                                                //                 fill: '#D2D2D2',
                                                //                 outline: 'none',
                                                //                 filter: "drop-shadow(0px 1px 1px #2c2c2c50)",
                                                //             },
                                                //         }}
                                                //         onClick={isSelected ? () => handleCountryClick(geo.properties) : undefined}
                                                //     />
                                                // </Tooltip>
                                            );
                                        })
                                    }
                                </Geographies>
                            </ZoomableGroup>
                        </ComposableMap>
                    )}
                </CardBody>
                
            </Card>
        </>
    );
};

export default RegionMap;