import React, { useState, useRef, useEffect } from 'react';
import Styles from './userActions.module.css';
import API from '../../../../Services/API';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import {
    Td,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useDisclosure
} from '@chakra-ui/react';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget'

const UserActions = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const token = window.localStorage.getItem("accessToken");
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const passwordRef = useRef();
    const [originalValues, setOriginalValues] = useState({});
    const [message, setMessage] = useState(null)

    // Store the original values when the modal opens
    useEffect(() => {
        if (isOpen) {
            setOriginalValues({
                firstName: props.firstName,
                lastName: props.lastName,
                // Password is not stored for security reasons
            });
        }
    }, [isOpen, props.firstName, props.lastName]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const deleteUser = async () => {
        setIsLoading(true);
        try {
            await API.delete(`/user-management?user_id=${props.id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const response = await API.get(`/list-user`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            props.getUpdatedUserInfo(response.data);
            // toast({
            //     title: 'Delete User',
            //     description: "User deleted successfully",
            //     status: 'success',
            //     duration: 9000,
            //     isClosable: true,
            // });
            setMessage('User deleted successfully')
        } catch (error) {
            // toast({
            //     title: 'Error',
            //     description: "Failed to delete user.",
            //     status: 'error',
            //     duration: 9000,
            //     isClosable: true,
            // });
            setMessage('Failed to delete user')
        } finally {
            setIsLoading(false);
            onClose();
        }
    };

    const updateUser = async () => {
        setIsLoading(true);
        
        
        // Get current values
        const currentFirstName = firstNameRef.current.value;
        const currentLastName = lastNameRef.current.value;
        const currentPassword = passwordRef.current.value;

        // Check if there are any changes
        const hasChanges = 
            currentFirstName !== originalValues.firstName ||
            currentLastName !== originalValues.lastName ||
            currentPassword !== '';

        if (!hasChanges) {
            setIsLoading(false);
            // toast({
            //     title: 'No Changes Detected',
            //     description: "No changes were made to the user details.",
            //     status: 'info',
            //     duration: 9000,
            //     isClosable: true,
            // });
            setMessage('No changes were made to the user details.')
            return; // Exit early if no changes detected
        }

        const formData = new FormData();
        if (file) {
            formData.append('profile_picture', file);
        }
        formData.append('user_id', props.id);
        formData.append('first_name', currentFirstName);
        formData.append('last_name', currentLastName);
        if (currentPassword) {
            formData.append('password', currentPassword);
        }

        try {
            const response = await API.put(`/user-management`, formData, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const updatedUser = response.data;

            // Update local storage only if it's the current user
            if (props.id === JSON.parse(window.localStorage.getItem('user')).id) {
                window.localStorage.setItem('user', JSON.stringify(updatedUser));
            }
            props.getUpdatedUserInfo(updatedUser);
            // toast({
            //     title: 'Updating User',
            //     description: "User updated successfully.",
            //     status: 'success',
            //     duration: 9000,
            //     isClosable: true,
            // });
            setMessage('User updated successfully')
            onClose();
        } catch (error) {
            // toast({
            //     title: 'Error',
            //     description: "Failed to update user.",
            //     status: 'error',
            //     duration: 9000,
            //     isClosable: true,
            // });
            setMessage('Failed to update user')
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {
                message && <ErrorWidget message={message} />
            }
            <Td pb={'0px'} pt={'0px'} isNumeric>
                <Button mr={'10px'} onClick={onOpen}><EditIcon /></Button>
                <Button onClick={deleteUser}><DeleteIcon /></Button>
            </Td>

            <Modal isOpen={isOpen} onClose={onClose} className={Styles.userActions} closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex}>
                    <ModalHeader> Update User Details </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>First Name</FormLabel>
                            <Input type='text' ref={firstNameRef} mb={'15px'} defaultValue={props.firstName} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Last Name</FormLabel>
                            <Input type='text' ref={lastNameRef} mb={'15px'} defaultValue={props.lastName} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Profile Picture</FormLabel>
                            <Input type="file" onChange={handleFileChange} mb={'15px'} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Password</FormLabel>
                            <Input type='password' ref={passwordRef} mb={'15px'} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter} backgroundColor={'transparent'}>
                        <Button mr={3} onClick={onClose}>Cancel</Button>
                        <Button className='primaryButtonNew' onClick={updateUser}>Save</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default UserActions;
