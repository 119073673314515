import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../Services/API';
import { Input, Stack, Button, Image } from '@chakra-ui/react';
import JoditEditor from 'jodit-react';
import LoaderSpinner from '../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const Form = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState(props.faqInfo?.prop_label || "");
    const [description, setDescription] = useState(props.faqInfo?.description || "");
    const editor = useRef(null);
    const { id } = useParams();

    const bannerUpload = (e) => {
        setFile(e.target.files[0]);
    };

    const updateFaqInfo = () => {
        if (!title || !description) {
            alert("Title and description cannot be empty.");
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        if (file) {
            formData.append('banner', file);
        }
        formData.append('prop_label', title);
        formData.append('description', description);

        if (props.faqInfoLength && props.faqInfoLength > 0) {
            formData.append('id', props.faqInfo?.id);

            API.put(`/faq-info/`, formData, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then(() => {
                API.get(`/faq-info/`, {
                    headers: { 'Authorization': 'Bearer ' + token }
                }).then((response) => {
                    props.getUpdateAbout(response.data.results && response.data.results[0]);
                });

                const formData1 = new FormData();
                formData1.append('id', id);
                formData1.append('name', title);
                
                API.put(`/project-modules`, formData1, {
                    headers: { 'Authorization': 'Bearer ' + token }
                }).then(() => {
                    setIsLoading(false);
                }).catch(error => {
                    console.error("Error updating project modules:", error);
                    alert("Error updating project modules.");
                    setIsLoading(false);
                });
            }).catch(error => {
                console.error("Error updating FAQ info:", error);
                alert("Error updating FAQ info.");
                setIsLoading(false);
            });
        } else {
            API.post(`/faq-info/`, formData, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then(() => {
                setIsLoading(false);
            }).catch(error => {
                console.error("Error creating FAQ info:", error);
                alert("Error creating FAQ info.");
                setIsLoading(false);
            });
        }
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            <Stack spacing={3} mb={'30px'}>
                <Input
                    id="pagetitle"
                    placeholder="Please Enter Title"
                    size="md"
                    backgroundColor="#ffffff"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <Input type="file" onChange={bannerUpload} padding={"4px"} />
                {props.faqInfo?.banner && (
                    <Image
                        src={props.faqInfo.banner}
                        alt={props.faqInfo.prop_label}
                    />
                )}
                <JoditEditor
                    id="description"
                    ref={editor}
                    value={description}
                    onChange={(newContent) => setDescription(newContent)} // This will update state on every change
                    tabIndex={1}
                />
                <Button className='primaryButtonNew' w={100} onClick={updateFaqInfo}>
                    Save
                </Button>
            </Stack>
        </>
    );
};

export default Form;