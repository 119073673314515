import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from './sectionThree.module.css'
import API from '../../../../../../../../../Services/API';
import {Heading, Select, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';

const SectionThree = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [elr, setElr] = useState(null);

    useEffect(()=>{
        API.get(`/executive-level-review?action=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setElr(response.data)
            
        }).catch(error=>{
             
            
        })
    },[])

    return (
        <>
            <SimpleGrid spacing={4} columns={[3]}>
                {
                    elr && elr.map((elr, i)=>
                        <Card>
                            <>
                                <CardHeader>
                                    <Image src={elr.banner} width={'60px'} alt={elr.label} crossOrigin="anonymous" />
                                    <Heading size='md' className={Styles.boxHeading}> {elr.label}</Heading>
                                </CardHeader>
                                <CardBody className='checkbody'>
                                    {elr.description != null  ? parse(elr.description) : ''}
                                </CardBody>
                            </>
                        </Card>
                    )
                }
            </SimpleGrid>
        </>
    )
}

export default SectionThree