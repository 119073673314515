import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Styles from './permissons.module.css'
import API from '../../../Services/API'
import { Dropdown } from 'react-bootstrap';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Select from 'react-select';
import { CloseIcon } from '@chakra-ui/icons'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Box,
    Card,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Checkbox, 
    Stack,
    Textarea 
} from '@chakra-ui/react'
import PermissionsForm from './PermissionsForm';



const Permissons = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const location = useLocation();
    const pillarId = window.localStorage.getItem("pillarId")
    const {id} = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [addAnother, setAddAnother] = useState(false)
    const [permissionType, setPermissionType] = useState()
    const [groupList, setGroupList] = useState()
    const [groupUserList, setGroupUserList] = useState()

    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
    });

    const openSlidingPane = () => {
        setState({ isPaneOpen: true })
    }

    const getUpdate = (updateAction) =>{
        setState({ isPaneOpen: updateAction })
    }
    const [newPath, setNewPath] = useState(null)
    const [impctglry, setImpctglry] = useState(null)

    useEffect(() => {
      const decodedPathname = decodeURIComponent(location.pathname);
      const segments = decodedPathname.split('/'); // Split the path by '/'
      const windowLocation = window.location.pathname;
      const metricPath = windowLocation.split('/').slice(0, 2).join('/');
      const ImpactMetricSegments = windowLocation.split('/');
      const getImpactMetricPath = `/${ImpactMetricSegments[2]}`

      setNewPath(metricPath)

      if (segments.length > 4) {
        setImpctglry(segments[4])
      }

      API.get(`/project-modules`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response => {
        const windowLocation = window.location.pathname;
        const metricPath = windowLocation.split('/').slice(0, 2).join('/');
        const faqPath = windowLocation.split('/').slice(0, 3).join('/');
        if(windowLocation === "/" || windowLocation === "/dashboard"){
          const homeData = response.data.find((module) => module.unique_name === 'home');
          API.get(`/get-specific-modules?module_id=${homeData?.id}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then(response => {
            const permission = response?.data?.permission;
            setPermissionType(permission);
          }).catch(error=>{
      
          })
        }else if(metricPath === '/metric'){
          const windowLocation = window.location.pathname;
          const pathParts = windowLocation.split('/');
          const firstExtractedId = pathParts[2];
          API.get(`/get-specific-modules?metric_id=${firstExtractedId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then(response => {
            const permission = response?.data?.permission;
            setPermissionType(permission);
          }).catch(error=>{
      
          });
        }else if(faqPath === '/help/faq'){
          const help = windowLocation.split('/')
          const helpId = help[3];
          API.get(`/get-specific-modules?module_id=${helpId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then(response => {
            const permission = response?.data?.permission;
            setPermissionType(permission);
          }).catch(error=>{
      
          });
        }else if(metricPath === '/portfolio'){
          const portfolio = windowLocation.split('/')
          const portfolioId = portfolio[3];
          API.get(`/get-specific-modules?module_id=${portfolioId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then(response => {
            const permission = response?.data?.permission;
            setPermissionType(permission);
          }).catch(error=>{
      
          });
        }else{
          const pathParts = windowLocation.split('/');
          const firstExtractedId = pathParts[2];
          const secondExtractedId = pathParts[4];
          const pillarId = parseInt(firstExtractedId)
          const actionId = parseInt(secondExtractedId)
          if((getImpactMetricPath !== '/impactmetrics') ){
            if(pillarId){
              API.get(`/get-specific-modules?module_id=${actionId ? actionId : pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
              }).then(response => {
                const permission = response?.data?.permission;
                setPermissionType(permission);
              }).catch(error=>{
      
              });
            }else if(actionId){
              API.get(`/get-specific-modules?module_id=${actionId ? actionId : pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
              }).then(response => {
                const permission = response?.data?.permission;
                setPermissionType(permission);
              }).catch(error=>{
      
              });
            }
          }
        }
      }).catch(error=>{
      
      });
    }, [location.pathname]);

    
    return (
        <>
            {
              (permissionType && permissionType === 'editor') ?
              (
                <li className="permissionLock">
                    <Button className={Styles.pButton} onClick={openSlidingPane} title='Permissions'> 
                        <i className="mdi mdi-lock-outline" onClick={() => setState({ isPaneOpen: true })}></i>
                    </Button>
                </li>
              ) : null
            }
            <SlidingPane
                className="some-custom-class"
                overlayClassName="some-custom-overlay-class"
                isOpen={state.isPaneOpen}
                title="Permissions"
                subtitle=""
                width="50%"
                onRequestClose={() => {
                    // triggered on "<" on left top click or on outside click
                    setState({ isPaneOpen: false });
                }}
            >
                <Button onClick={() => setState({ isPaneOpen: false })} float={'right'}> <CloseIcon /> </Button>
                <PermissionsForm pillarId={props.pillarId} getUpdate={getUpdate} />
            </SlidingPane>
        </>
    )
}

export default Permissons