import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from './pdfsection.module.css'
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import API from '../../../../../../../../../../Services/API';

const PdfSectionOne = (props) => {
  const token = window.localStorage.getItem("accessToken")
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [profileImage, setProfileImage] = useState(null);
  const [heading, setHeading] = useState(props.label)
  const [description, setDescription] = useState(props.sublabel)
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  return (
    <>
      <Heading className={Styles.leftGridHeading} mb={'20px'}>
        {props.label}
      </Heading>
      <Image className={Styles.profileImage} src={props.banner} alt={props.label} crossOrigin="anonymous" />
      <Text as={'p'} className={Styles.profileName} mt={'20px'}> {props.sublabel} </Text>
    </>
  )
}

export default PdfSectionOne