import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import Styles from './preview.module.css';
import API from '../../../../../Services/API';
import {Heading, Box,  SimpleGrid,  Grid,  GridItem, Image,  Text,  Input,  Button, Spinner,  useToast} from '@chakra-ui/react';
import MetricInfo from '../../../../../Components/ProjectModule/Actions/ViewAction/ActionTabs/ExecutiveLeadershipReviews/SlideOne/MetricInfo/MetricInfo';
import HeadingOne from './HeadingOne/HeadingOne';

const Preview = (props) => {
  const token = window.localStorage.getItem('accessToken');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const toast = useToast();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    API.get(`/elr-dashboard?id=${props.id}`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then((response) => {
      setSelectedData(response?.data[0]);
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
      console.error('Failed to fetch data', error);
      toast({
        title: 'Error fetching data',
        description: 'Unable to load the data. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    });
  }, [props.pillarId, props.id]);

  function bannerUpload(e) {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size < 5000000) { // Example size check (5MB)
      setFile(selectedFile);
      // Create a file reader to read the file and generate a preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreview(null);
      toast({
        title: 'Invalid file',
        description: 'File is too large or invalid. Please select a smaller file.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  const saveHeader = () => {
    setIsLoading(true);
    const formData = new FormData();
    if (file != null) {
      formData.append('banner', file);
    }
    formData.append('label', $("#headerLabel").val());
    formData.append('id', parseInt(props.id));
    formData.append('action', parseInt(props.pillar));
    API.put(`/elr-dashboard`, formData, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then((response) => {
        props.getUpdatedTitle($("#headerLabel").val())
        setIsLoading(false);
    }).catch((error) => {
        setIsLoading(false);
        console.error('Failed to save header', error);
        setErrorMessage(error.response.data.msg)
      });
  };
  const getError = (error) =>{
    setErrorMessage(error)
  }

  const getUpdateData = () =>{
    API.get(`/elr-dashboard?id=${props.id}`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      setSelectedData(response?.data[0])
    }).catch(error=>{
      setIsLoading(false)
    })    
  }

  return (
    <>
        {
            errorMessage && errorMessage ? <Box> {errorMessage && errorMessage} </Box> : null
        }
      <Box border={'1px solid #e2e8f0'}>
        <Heading className={Styles.headingBg}>
          <Image
            src={selectedData && selectedData.banner}
            alt={selectedData && selectedData.label}
            crossOrigin="anonymous"
            position={'absolute'}
            width={'100%'}
          />
          {props.edit ? (
            <SimpleGrid columns={[3]}>
              <Box p={'10px'}>
                <Input
                  placeholder="Please provide banner heading"
                  defaultValue={selectedData && selectedData.label}
                  mb={'5px'}
                  backgroundColor={'#ffffff'}
                  id={'headerLabel'}
                />
              </Box>
              <Box p={'10px'}>
                <Input type="file" onChange={bannerUpload} padding={'4px'} />
              </Box>
              <Box p={'10px'}>
                <Button className="buttonPrimary" onClick={saveHeader} isLoading={isLoading}>
                  Save
                </Button>
              </Box>
            </SimpleGrid>
          ) : (
            <Text as="p" className={Styles.centerAlign}>
              {selectedData && selectedData.label}
            </Text>
          )}
        </Heading>
        <Box>
          {isLoading ? (
            <Spinner />
          ) : (
            <Grid h={'auto'} templateRows="repeat(1, 1fr)" templateColumns="repeat(4, 1fr)" gap={0}>
              <GridItem rowSpan={2} bg={props.edit ? '#ffffff' : '#00a0da'}>
                <Box className={Styles.sideBox}>
                    <HeadingOne
                      editable = {props.edit}
                      heading={selectedData && selectedData.heading1}
                      description={selectedData && selectedData.description1}
                      pillarId={props.pillar}
                      id={props.id}
                      getError={getError}
                      index={1}
                      getUpdateData={getUpdateData}
                    />
                    <HeadingOne
                      editable = {props.edit}
                      heading={selectedData && selectedData.heading2}
                      description={selectedData && selectedData.description2}
                      pillarId={props.pillar}
                      id={props.id}
                      getError={getError}
                      index={2}
                      getUpdateData={getUpdateData}
                    />
                    <HeadingOne
                      editable = {props.edit}
                      heading={selectedData && selectedData.heading3}
                      description={selectedData && selectedData.description3}
                      pillarId={props.pillar}
                      id={props.id}
                      getError={getError}
                      index={3}
                      getUpdateData={getUpdateData}
                    />
                    <HeadingOne
                      editable = {props.edit}
                      heading={selectedData && selectedData.heading4}
                      description={selectedData && selectedData.description4}
                      pillarId={props.pillar}
                      id={props.id}
                      getError={getError}
                      index={4}
                      getUpdateData={getUpdateData}
                    />
                </Box>
              </GridItem>
              <MetricInfo pillarId={props.pillar} actionStatus={props.actionStatus} metricResult={props.metricResult && props.metricResult} />
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Preview;