import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from '../executiveleadershipreviews.module.css'
import API from '../../../../../Services/API';
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import Select from 'react-select';
import LoaderSpinner from '../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Checkbox, 
    Stack,
} from '@chakra-ui/react'

const AddSlideTwo = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState(false)
    const [elrSectionOne, setElrSectionOne] = useState()
    const [elrMetric, setElrMetric] = useState()
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [viewOneId, setViewOneId] = useState(null);
    const [impactStories, setImpactStories] = useState(null);
    const [elr, setElr] = useState(null);
    const [noData, setNoData] = useState();
    const editor = useRef(null);
    const [errorMessage, setErrorMessage] = useState(null)

    const createSlideTwo = () =>{
        setIsLoading(true)
        const formData = new FormData()
        if(file != null){
            formData.append('banner', file)
        }
        formData.append('label', $("#headerLabelImpact").val())
        formData.append('approval_status','Ready to Submit')
        formData.append('pillar', props.pillarId)
        API.post(`/impact-story`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/impact-story?pillar=${props.pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                props.getSecondSlideUpdate(response.data)
                onClose()
                setIsLoading(false)
            }).catch(error=>{
                setErrorMessage(error.response.data.msg)
            })
    
            API.get(`/elr-sum-metric?pillar=${props.pillarId}&status=on-track`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                setElrMetric(response.data)
                onClose()
                setIsLoading(false)
                
            }).catch(error=>{
                 
                onClose()
                setIsLoading(false)
            })
        }).catch(error=>{
            setErrorMessage(error.response.data.msg)
        })
    }

    return (
        <>
            { isLoading ? <LoaderSpinner /> : null}
            <Button className={'addSlideOne defaultButton marginTRNew'} float={'right'} mr={'15x'} mt={'15px'} onClick={onOpen}> Create Impact Story  </Button>
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex} maxW={'580px'}>
                    <ModalHeader>Add Slide Two</ModalHeader>
                    <Text pl={'20px'}> {errorMessage && errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()} </Text>
                    <ModalCloseButton onClose={onClose} />
                    <ModalBody>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Label</FormLabel>
                            <Input type='text' id={'headerLabelImpact'} placeholder='Please provide slide heading' mb={'15px'} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter} backgroundColor={'transparent'}>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='blue' onClick={createSlideTwo}>Add</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AddSlideTwo