import React, { useState, useEffect, useRef, useMemo } from 'react';
import API from '../../../../../../Services/API';
import {Card, CardHeader, CardBody, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Box, Input, Textarea, Button, Image} from '@chakra-ui/react'
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, useDisclosure} from '@chakra-ui/react'
import { EditIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const DeleteMetric = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState()
    const [deleteMessage, setDeleteMessage] = useState(false)



    const deleteAction = (event) => {
        event.stopPropagation();
        onClose()
        setIsLoading(true)
        const body = {
          "id": parseInt(props.id),
          "visible": false,
        }
        API.put(`/metric-data`,body, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
            API.get(`/metric-data?metric_id=${props.metricId}`)
            .then(response1 => {
                props.getUpdatedMetricData(response1.data)
                setIsLoading(false)
                props.deleteUpdatedMessage(true)
            }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)
        })
    }
  return (
    <>
        {isLoading ? <LoaderSpinner /> : null}
        <Button className='deleteNButton' id={props.id}  onClick={onOpen}>
            <DeleteIcon id={props.id} onClick={onOpen} /> 
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete Confirmation</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Are you sure you want to delete <Text as='span' fontWeight={'bold'}>{props.name}</Text> ? This action cannot be reversed.
                </ModalBody>
                <ModalFooter backgroundColor={'transparent'}>
                    <Button mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button className='buttonPrimary' id={props.id} onClick={deleteAction}>Confirm </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
  )
}

export default DeleteMetric