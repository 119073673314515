import React, { useEffect, useRef } from "react";
import { useToast, Box, CloseButton, Flex } from "@chakra-ui/react";

const ErrorWidget = (props) => {
  const toast = useToast();
  const toastIdRef = useRef();

  useEffect(() => {
    if (!toast.isActive(toastIdRef.current)) {
      toastIdRef.current = toast({
        position: "top-right",
        duration: 10000,
        render: ({ onClose }) => (
          <Box
            color="white"
            p={3}
            bg="blue.500" // Custom blue background
            borderRadius="md"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <strong>Notification Alert:</strong>
                <p>{props.message}</p>
              </Box>
              <CloseButton onClick={onClose} />
            </Flex>
          </Box>
        ),
      });
    }
  }, [toast, props.message]);

  return <></>;
};

export default ErrorWidget;
