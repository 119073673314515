import React, { useEffect, useState } from 'react';
import Styles from './permissons.module.css';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import API from '../../../Services/API';
import { Button, FormControl, FormLabel, Tag, TagLabel, TagCloseButton, Box } from '@chakra-ui/react';
import LoaderSpinner from '../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const PermissionsForm = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { id, actionid } = useParams();

    // State management
    const [isLoading, setIsLoading] = useState(false);
    const [groupList, setGroupList] = useState();
    const [permissionType, setPermissionType] = useState();
    const [addAnother, setAddAnother] = useState(false);
    const [editor, setEditor] = useState([]);
    const [contributor, setContributor] = useState([]);
    const [viewer, setViewer] = useState([]);
    const [editorIds, setEditorIds] = useState([]);
    const [contributorIds, setContributorIds] = useState([]);
    const [viewerIds, setViewerIds] = useState([]);
    const [uniqName, setUniqName] = useState(null);
    const [isGlobalView, setIsGlobalView] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [groupListPermission, setGroupListPermission] = useState(null);

    // Fetching data from API
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const [modulePermissions, projectModules, userPermissions, userGroup, pillarPermissions] = await Promise.all([
                    API.get(`/module-permissions?module_id=${actionid || id}`, { headers: { Authorization: 'Bearer ' + token }}),
                    API.get(`/project-modules?module_id=${actionid || id}`, { headers: { Authorization: 'Bearer ' + token }}),
                    API.get(`/user-permissions`, { headers: { Authorization: 'Bearer ' + token }}),
                    API.get(`/user-group`, { headers: { Authorization: 'Bearer ' + token }}),
                    API.get(`/user-permissions?module_id=${props.pillarId}`, { headers: { Authorization: 'Bearer ' + token }})
                ]);

                setGroupListPermission(modulePermissions.data);
                setUniqName(projectModules.data[0]?.unique_name);
                setIsGlobalView(projectModules.data[0]?.is_global_view);
                setPermissionType(Object.keys(userPermissions.data));
                setGroupList(userGroup.data);
                
                const { editor, contributor, viewer } = pillarPermissions.data;
                setEditor(editor);
                setContributor(contributor);
                setViewer(viewer);
                setEditorIds(editor.map(item => item.id));
                setContributorIds(contributor.map(item => item.id));
                setViewerIds(viewer.map(item => item.id));

                setIsLoading(false);
            } catch (error) {
                if (error.response?.data.code === 'token_not_valid') {
                    window.localStorage.clear();
                    window.location.href = '/login';
                }
                setErrorMessage("Oops, something went wrong 😓!");
                setIsLoading(false);
            }
        };
        
        fetchData();
    }, [actionid, id, token, props.pillarId]);

    // Handle permission saving
    const addPermission = () => {
        setIsLoading(true);
        const body = {
            'module_id' : parseInt(actionid ? actionid : id),
            'request_coming_from' : uniqName,
            'editor' : editorIds,
            'contributor' : contributorIds,
            'viewer' : viewerIds,
            'is_global_view' : isGlobalView,
        };

        API.post(`/user-permissions`, body, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            setIsLoading(false);
            props.getUpdate(false);
        }).catch(error => {
            setErrorMessage(error.response?.data?.msg || "Permission saving failed 🤷‍♂️");
            setIsLoading(false);
        });
    };

    const handleChange = (event, actionMeta) => {
        if (actionMeta.name === "editor") {
            const ids = event.map(item => item.id);
            setEditorIds(ids);
        } else if (actionMeta.name === "contributor") {
            const ids = event.map(item => item.id);
            setContributorIds(ids);
        } else if (actionMeta.name === "viewer") {
            const ids = event.map(item => item.id);
            setViewerIds(ids);
        }
    };

    const handleDelete = (groupId, groupType) => {
        setIsLoading(true);
        
        // Construct the request body
        const body = JSON.stringify({ id: groupId });
    
        // Make the DELETE API request
        API.delete(`/user-permissions`, {
            headers: { 
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'  // Specify the content type
            },
            data: body  // Add the raw JSON body to the request
        })
        .then(response => {
            // Remove the group from the state after a successful delete
            if (groupType === "editor") {
                setEditor(editor.filter(item => item.id !== groupId));
                setEditorIds(editorIds.filter(id => id !== groupId));
            } else if (groupType === "contributor") {
                setContributor(contributor.filter(item => item.id !== groupId));
                setContributorIds(contributorIds.filter(id => id !== groupId));
            } else if (groupType === "viewer") {
                setViewer(viewer.filter(item => item.id !== groupId));
                setViewerIds(viewerIds.filter(id => id !== groupId));
            }
            alert('Deleted permission successfully')
            props.getUpdate(false);
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
        });
    };

    // Helper for transforming group data into select options
    const selectOptions = (data) => data?.map(item => ({ id: item.id, value: item.name, label: item.name }));

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {errorMessage && <Box color="red">{errorMessage}</Box>}
            
            <FormControl float="left" width="100%">
                {
                    permissionType && permissionType.map(item => 
                        item !== 'is_global_view' && (
                            <React.Fragment key={item}>
                                <FormLabel className={Styles.selectFormLabel}>{item}</FormLabel>
                                {
                                    groupListPermission?.map(groupItem => 
                                        groupItem.type === item && (
                                            <Tag key={groupItem.user_group_name} mr="5px" mb="10px"> 
                                                <TagLabel>{groupItem.user_group_name}</TagLabel>
                                                <TagCloseButton onClick={() => handleDelete(groupItem.id, item)} />
                                            </Tag>
                                        )
                                    )
                                }
                                <Select 
                                    defaultValue={
                                        item === "contributor" ? selectOptions(contributor) : 
                                        item === "viewer" ? selectOptions(viewer) : 
                                        item === "editor" ? selectOptions(editor) : null
                                    }
                                    options={selectOptions(groupList)}
                                    isMulti 
                                    isSearchable
                                    className={Styles.mbSelect}
                                    name={item}
                                    onChange={handleChange}
                                />
                            </React.Fragment>
                        )
                    )
                }
            </FormControl>
            
            <FormControl float="left" width="100%">
                <Button 
                    variant="ghost" 
                    onClick={addPermission} 
                    className={Styles.SaveButton} 
                    isLoading={isLoading}
                    isDisabled={isLoading}
                >
                    Save Permissions
                </Button>
            </FormControl>
        </>
    );
};

export default PermissionsForm;
