import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from './pdfsectionthree.module.css'
import API from '../../../../../../../../../../Services/API';
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea, Table, Tr, Td } from '@chakra-ui/react'

const PdfSectionThree = (props) => {
  return (
    <Box className={Styles.scImpact}>
      <Box padding="0">
        <Flex
          key={props.id}
          direction={["column", "row"]}
          align="center"
          //justify="space-between"
          mb="0"
          p="4"
          borderRadius="md"
        >
          <Image
            src={props.banner}
            alt={props.description}
            boxSize="50px"
            objectFit="cover"
            borderRadius="md"
            crossOrigin="anonymous"
          />
          <Box ml={'10px'} textAlign={["left", "left"]}>
            <Text fontSize="m" fontWeight="bold" mb="2">
              {props.description}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default PdfSectionThree