import React, { useState, useEffect } from 'react';
import { Button, FormControl, FormLabel, Input, Box, Text, SimpleGrid, Switch } from '@chakra-ui/react';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import API from '../../../../../../Services/API';
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

const CreateAspiration = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { metricId } = useParams();
    const [fiscalYearList, setFiscalYearList] = useState([]);
    const [fiscalQtrList, setFiscalQtrList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [allow, setAllow] = useState(false);
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [selectedFiscalYearList, setSelectedFiscalYearList] = useState("");
    const [formData, setFormData] = useState({
        name: '',
        value: '',
        targetFiscalYear: '',
        targetFiscalQuarter: '',
    });
    const [validationError, setValidationError] = useState({
        name: '',
        value: '',
        targetFiscalYear: '',
        targetFiscalQuarter: '',
    });

    useEffect(() => {
        API.get(`/fiscal-year-detail`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            setFiscalYearList(response.data[0].fiscalyear.map(year => ({ value: year.id, label: year.name })));
            setFiscalQtrList(response.data[1].fiscalquarter.map(qtr => ({ value: qtr.id, label: qtr.name })));
        }).catch(error => {
            setIsLoading(false);
        });
    }, []);

    const validateForm = () => {
        const errors = {
            name: '',
            value: '',
            targetFiscalYear: '',
            targetFiscalQuarter: '',
        };
        let valid = true;

        if (!formData.name) {
            errors.name = 'Name is required';
            valid = false;
        }
        if (!formData.value) {
            errors.value = 'Value is required';
            valid = false;
        }
        if (!formData.targetFiscalYear && !formData.targetFiscalQuarter) {
            errors.targetFiscalYear = 'Target Fiscal Year or Quarter is required';
            valid = false;
        }

        setValidationError(errors);
        return valid;
    };

    const newAspiration = () => {
        if (!validateForm()) {
            return;
        }
    
        setIsLoading(true);
        const body = {
            "metric": metricId,
            "name": formData.name,
            "value": parseInt(formData.value),
            "targetfiscalyear": formData.targetFiscalYear.value,
            "targetfiscalquarter": formData.targetFiscalQuarter.value,
            "allow_export_externally": allow
        };
    
        API.post(`/metric-target`, body, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            API.get(`/metric-target?metric_id=${metricId}`)
                .then(response1 => {
                    props.getUpdatedAspiration(response1.data);
                    setMessage(true);
                    setIsLoading(false);
                    setIsPaneOpen(false);
    
                    // Reset the form
                    setFormData({
                        name: '',
                        value: '',
                        targetFiscalYear: '',
                        targetFiscalQuarter: '',
                    });
                    setValidationError({
                        name: '',
                        value: '',
                        targetFiscalYear: '',
                        targetFiscalQuarter: '',
                    });
                    setSelectedFiscalYearList("");
                    setAllow(false);
                }).catch(error => {
                    setIsLoading(false);
                });
        }).catch(error => {
            setIsLoading(false);
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFiscalYearChange = (selectedOption) => {
        setFormData({
            ...formData,
            targetFiscalYear: selectedOption,
            targetFiscalQuarter: null  // Reset targetFiscalQuarter when fiscal year changes
        });
        setSelectedFiscalYearList(selectedOption ? selectedOption.label.substring(0, 4) : "");
    };

    const handleFiscalQuarterChange = (selectedOption) => {
        setFormData({
            ...formData,
            targetFiscalQuarter: selectedOption
        });
    };

    const handleSwitchChange = (event) => {
        setAllow(event.target.checked);
    };

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            {message ? <Box className="successMessage">Created aspiration successfully</Box> : null}
            <Button float="right" className="buttonPrimary" onClick={() => setIsPaneOpen(true)}>New Aspiration</Button>
            <SlidingPane
                isOpen={isPaneOpen}
                title="Create Aspiration"
                onRequestClose={() => setIsPaneOpen(false)}
                width="50%"
            >
                <SimpleGrid columns={[1, 2]} spacing="20px" mt="10px">
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold">Name</FormLabel>
                            <Input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                mb="15px"
                                className={validationError.name ? 'redBorder' : ''}
                            />
                            <Text color="red">{validationError.name}</Text>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold">Value</FormLabel>
                            <Input
                                type="text"
                                name="value"
                                value={formData.value}
                                onChange={handleInputChange}
                                mb="15px"
                                className={validationError.value ? 'redBorder' : ''}
                            />
                            <Text color="red">{validationError.value}</Text>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold">Target Fiscal Year</FormLabel>
                            <Select
                                options={fiscalYearList}
                                value={formData.targetFiscalYear}
                                onChange={handleFiscalYearChange}
                                placeholder="Select fiscal year"
                                className={validationError.targetFiscalYear ? 'redBorder' : ''}
                            />
                            <Text color="red">{validationError.targetFiscalYear}</Text>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold">Target Fiscal Quarter</FormLabel>
                            <Select
                                options={fiscalQtrList.filter(qtr => selectedFiscalYearList && qtr.label.startsWith(selectedFiscalYearList))}
                                value={formData.targetFiscalQuarter}
                                onChange={handleFiscalQuarterChange}
                                placeholder="Select fiscal quarter"
                                className={validationError.targetFiscalQuarter ? 'redBorder' : ''}
                            />
                            <Text color="red">{validationError.targetFiscalQuarter}</Text>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl display="flex" alignItems="center" mt="0px">
                            <FormLabel htmlFor="share_data_externally" mb="0" fontWeight="bold">Share Data Externally</FormLabel>
                            <Switch id="share_data_externally" mt="10px" onChange={handleSwitchChange} />
                        </FormControl>
                    </Box>
                </SimpleGrid>
                <Box>
                    <FormControl>
                        <Button colorScheme="blue" onClick={newAspiration} mt="20px" float="right">Add Aspiration</Button>
                    </FormControl>
                </Box>
            </SlidingPane>
        </>
    );
};

export default CreateAspiration;
