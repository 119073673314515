import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../countryview.module.css'
import API from '../../../../Services/API';
import { Link, useParams } from 'react-router-dom'
import {
    Card, CardHeader, CardBody, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Box, Input, Textarea, Button, Image,
    Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Stack} from '@chakra-ui/react'
const Metric = () => {
    const token = window.localStorage.getItem("accessToken")
    const {countryId} = useParams();
    const [metric, setMetric] = useState()

    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
        API.get(`/metric-data?geography_id=${countryId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            
            setMetric(response.data)
            // setCountryInfo(response.data[0])
            // setIsLoading(false)
        }).catch(error=>{
            
        })
    },[countryId])
    return (
        <>
            <Card>
                <CardHeader pt={'0'} pb={'0'}>
                    <Heading className={Styles.customHeadingH3}> Metric Data </Heading>
                </CardHeader>
                <CardBody>
                    <TableContainer>
                        <Table size='sm' className={Styles.table}>
                            <Thead>
                                <Tr backgroundColor={'#d2ecfc'}>
                                    <Th>Metric</Th>
                                    <Th>Strategy</Th>
                                    <Th>Value</Th>
                                    <Th>Period</Th>
                                    <Th isNumeric>Approval</Th>
                                    <Th>Status</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    metric && metric.length > 0 ? (
                                        metric && metric.map((metridata, index)=>
                                            <Tr>
                                                <Td textTransform={'capitalize'}>{metridata.name}</Td>
                                                <Td textTransform={'capitalize'}>{metridata.strategy_name}</Td>
                                                <Td>
                                                    { 
                                                        metridata.datatype === 'money' ? " $ " : 
                                                        metridata.datatype === 'number' ? " # " : null
                                                    } 
                                                    {metridata.value}
                                                    {
                                                        metridata.datatype === 'percent' ? " % " : null
                                                    }
                                                </Td>
                                                <Td textTransform={'capitalize'}>{metridata.fiscal_year_name}</Td>
                                                <Td textTransform={'capitalize'} isNumeric>{metridata.approval_status}</Td>
                                                <Td textTransform={'capitalize'}>{metridata.metric_status.replace(/-/g, " ")}</Td>
                                            </Tr>
                                        )
                                    ) : 
                                    <Tr>
                                        <Td colSpan={6}> <Box fontSize={'14px'}> There is no data to show </Box> </Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </CardBody>
            </Card>
        </>
    )
}

export default Metric