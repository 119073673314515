import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../executiveleadershipreviews.module.css'
import API from '../../../../../../../Services/API'
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Checkbox, 
    Stack,
} from '@chakra-ui/react'
import { DeleteIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import LoaderSpinner from '../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';


const DeleteElr = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState()
    const [errorMessage, setErrorMessage] = useState(null)
    
    async function deleteElr () {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('id', props.id);   // Add the ID to the form data
        formData.append('visible', false); // Set visibility to 'hidden'
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        try {
            const putResponse = await API.put(`/executive-level-review`, formData, {
                headers: headers
            });
            props.getUpdateElr();
            onClose()
            
        } catch (error) {
            if (error.response?.status === 500) {
                setErrorMessage('Something went wrong. Please refresh the page.');
            } else {
                setErrorMessage(error.message || 'An error occurred');
            }
        } finally {
            // Stop loading
            setIsLoading(false);
        }
    }
    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            <Button className='newDeleteButtonElr' onClick={onOpen}><DeleteIcon  onClick={onOpen} /> </Button>
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex} maxW={'580px'}>
                    <ModalHeader>Delete ELR</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure you want to delete it? This action cannot be reversed.
                        {errorMessage !== null ? <Box color={'red'}> {errorMessage && errorMessage} </Box> : null}

                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter} backgroundColor={'transparent'}>
                        <Button  mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='blue' onClick={deleteElr}>Confirm</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DeleteElr