import React, { useEffect, useState, useMemo } from 'react';
import Select from 'react-select';
import Styles from './userGroupList.module.css';
import API from '../../../../Services/API';
import { Card, CardBody, Heading, Stack, StackDivider, Box, Text, SimpleGrid, useToast, Wrap, WrapItem, Avatar } from '@chakra-ui/react';
import DeleteUser from './DeleteUser/DeleteUser';
import PermissionLoader from './PermissionLoader';
import userPlaceholder from '../../../../../assets/images/userPlaceholder.svg';

const UserCard = ({ groupUser, groupName, getUpdatedList }) => (
  <Box>
    <Card>
      <DeleteUser
        id={groupUser.id}
        groupName={groupName}
        personName={groupUser.user}
        getUpdatedList={getUpdatedList}
      />
      <CardBody paddingBottom={'0px'}>
        <Wrap>
          <WrapItem mb={'0px'}>
            <Avatar size="sm" name={groupUser.user} src={userPlaceholder} mr={'10px'} />
            <Box>
              <Text as={'p'} display={'block'} color={'blue'} marginBottom={'0'}>
                {groupUser.user}
              </Text>
              <Box className={Styles.lineBreak}>{groupUser.username}</Box>
              <Box className={Styles.lineBreak} textTransform={'capitalize'}>
                {groupUser.account_permission}
              </Box>
            </Box>
          </WrapItem>
        </Wrap>
      </CardBody>
    </Card>
  </Box>
);

const UserGroupList = (props) => {
  const token = window.localStorage.getItem('accessToken');
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState();
  const [selectedUser, setSelectedUser] = useState(null); // State to control selected user
  const toast = useToast();

  useEffect(() => {
    setIsLoading(true);
    API.get('/list-user', {
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    })
      .then((response) => {
        setUsers(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: 'Error fetching users',
          description: 'There was an error fetching the user list.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  }, [token]);

  const addUser = (event, groupId) => {
    if (!event) return;

    setIsLoading(true);
    const body = {
      user: parseInt(event.value),
      user_group: parseInt(groupId),
    };
    API.post('/user-group-relation', body, {
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    })
      .then(() => {
        getUpdatedList(); // Refresh the user group list
        setSelectedUser(null); // Reset the dropdown selection
        setIsLoading(false);
        toast({
          title: 'Added user to Group',
          description: `Added user to ${groupId}.`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const getUpdatedList = () => {
    API.get('/user-group-relation', {
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    })
      .then((response) => {
        props.getUpdatedGroupUser(response.data.results);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formattedOptions = useMemo(
    () =>
      users?.map((user) => ({
        value: user.id,
        label: user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.email,
      })),
    [users]
  );

  return (
    <Card>
      <CardBody>
        {isLoading && <PermissionLoader />}
        <Stack divider={<StackDivider />} spacing="4">
          {props.groupList &&
            props.groupList.map((group, index) => (
              <Box key={index}>
                <Heading size="xs" textTransform="uppercase">
                  {group.name} {group.is_supergroup && <Text as={'span'} color={'blue'}>(Super group)</Text>}
                </Heading>
                <SimpleGrid columns={[1, 2, 3, 4]} spacing="20px" mt={'10px'}>
                  {props.groupUserList &&
                    props.groupUserList.map(
                      (groupUser, groupIndex) =>
                        groupUser.user_group === group.name && (
                          <UserCard
                            key={groupIndex}
                            groupUser={groupUser}
                            groupName={group.name}
                            getUpdatedList={getUpdatedList}
                          />
                        )
                    )}
                  <Box>
                    <Stack spacing={3}>
                      <Select
                        options={formattedOptions}
                        onChange={(selectedOption) => {
                          setSelectedUser(selectedOption); // Update selected user
                          addUser(selectedOption, group.id);
                        }}
                        value={selectedUser} // Set value to selectedUser state
                        placeholder="Select a user"
                        isClearable
                        id={group.id}
                      />
                    </Stack>
                  </Box>
                </SimpleGrid>
              </Box>
            ))}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default UserGroupList;
