import React, { useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { IconButton, Box, useToast } from '@chakra-ui/react';
import { Heading, FormControl, Switch, FormLabel, Tooltip } from '@chakra-ui/react';
import { ViewIcon, CloseIcon } from '@chakra-ui/icons';
import Styles from '../../../MetricData/metricdata.module.css';
import API from '../../../../../Services/API';
import Preview from './Preview';
import PropTypes from 'prop-types';

const View = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const user = JSON.parse(window.localStorage.getItem('user'));
    const [edit, setEdit] = useState(false);
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [paneltitle, setPaneltitle] = useState(props.title);
    const toast = useToast();

    const togglePane = () => {
        setIsPaneOpen(prevState => !prevState);
    };

    const enableEdit = (event) => {
        setEdit(event.target.checked);
    };

    const handleApiCallOnClose = async () => {
        try {
            const response = await API.get(`/impact-story`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            props.getNewUpdate(response);
        } catch (error) {
            props.getErrorUpdateNew(error);
            toast({
                title: "Error fetching data.",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleClosePane = () => {
        setEdit(false); // Reset edit state to false
        togglePane(); // This will close the pane
        handleApiCallOnClose(); // Call the API after closing the pane
    };

    const getUpdatedTitle = (updatedTitle) => {
        setPaneltitle(updatedTitle);
    };

    return (
        <Box className={'vieElr'}>
            <Tooltip id={props.id} label="View" aria-label="View">
                <IconButton
                    variant='outline'
                    colorScheme='teal'
                    aria-label='View details'
                    fontSize='14px'
                    icon={<ViewIcon />}
                    className={`${Styles.approve} ${Styles.hoverEffectApprove}`}
                    id={props.id}
                    onClick={togglePane}
                    ml={'5px'}
                />
            </Tooltip>

            <SlidingPane
                closeIcon={<span className='closeTooltip'> <CloseIcon /> </span>}
                isOpen={isPaneOpen}
                onRequestClose={handleClosePane}
                from="right"
                paneClassName={Styles.paneClass}
                title={paneltitle}
            >
                <Box>
                    <Heading className={Styles.slideHeading}>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel htmlFor='email-alerts' mb='20px' fontSize={'25px'}>
                                Impact Stories
                            </FormLabel>
                            {user && user.action_office_user ? <Switch id='email-alerts' onChange={enableEdit} /> : null}
                        </FormControl>
                    </Heading>
                    <Preview
                        id={props.id}
                        pillar={props.pillar}
                        title={props.label}
                        editable={edit}
                        getUpdatedTitle={getUpdatedTitle}
                    />
                </Box>
            </SlidingPane>
        </Box>
    );
};

View.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    pillar: PropTypes.string,
    label: PropTypes.string,
    getNewUpdate: PropTypes.func.isRequired,
    getErrorUpdateNew: PropTypes.func.isRequired,
};

export default View;
