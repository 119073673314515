import React, { useState, useEffect, useMemo } from 'react';
import API from '../../../Services/API';
import Styles from './regionmap.module.css';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { Heading, Card, CardBody, Box, Tooltip, FormControl, Text, Switch } from '@chakra-ui/react';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import worldData from './world.geojson';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import NumberFormat, { formatNumber } from './NumberFormat';
import MapFOrUSstate from '../../ProjectModule/GeographicImpact/MapFOrUSstate';
import { FullScreenLoader } from '../../ProjectModule/GeographicImpact/GeographicImpact';

const RegionMap = (props) => {
  const token = window.localStorage.getItem("accessToken");
  const [countryData, setCountryData] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const defaultScale = 130;
  const [zoom, setZoom] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);
  const offset = { x: 10, y: 10 };
  const [regionData, setRegionData] = useState([]);
  const [regionParentData, setRegionParentData] = useState([]);

  const handleZoomIn = () => setZoom(zoom * 1.5);
  const handleZoomOut = () => zoom >= 1.5 && setZoom(zoom / 1.5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const regionResponse = await API.get(`/project-modules`, {
          headers: { 'Authorization': 'Bearer ' + token },
        });
        const regionCategories = regionResponse.data.filter(item => item.category === 'region');
        setRegionData(regionCategories);
        setRegionParentData(regionCategories);

        const moduleResponse = await API.get(`/project-modules?module_id=${id}`, {
          headers: { 'Authorization': 'Bearer ' + token },
        });
        const subModuleResponse = await API.get(`/get-sub-modules?module_id=${id}`, {
          headers: { 'Authorization': 'Bearer ' + token },
        });
        const mapResponse = await API.get(`/map?region=${id}`, {
          headers: { 'Authorization': 'Bearer ' + token },
        });
        setCountryData(mapResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id, token]);

  const highlightedCountries = useMemo(
    () => countryData?.map(item => ({ code: item.country_code, value: item.value, metric_type:item.metric__datatype })) || [],
    [countryData]
  );

  const isHighlighted = (countryCode) => highlightedCountries.some(country => country.code === countryCode);

  const handleMouseMove = (e) => setPosition({ x: e.clientX, y: e.clientY });

  const handleMouseEnter = (countryProperties) => {
    setShowTooltip(true);
    const countryName = countryProperties.ADMIN;
    const countryValue = countryProperties.value;
    const content = `${countryName}: ${countryValue || '0'}`;
    setTooltipContent(content);
  };

  const handleMouseLeave = () => setShowTooltip(false);

  const handleCountryClick = (countryProperties) => {
    const countryCode = countryProperties.ISO_A2;
    const countryName = countryProperties.ADMIN;
    const country = highlightedCountries.find(country => country.code === countryCode);
    alert(`You clicked on ${countryName} and ${country?.value || '0'}`);
  };


  const [isStateLevel, setIsStateLevel] = useState(false);
  const [isStateLevel1sttime, setIsStateLevel1sttime] = useState(1);

  const handleToggle = () => {
    setIsStateLevel(!isStateLevel);
   if(isStateLevel){ setIsStateLevel1sttime(0)}
  };

useEffect(()=>{
 if(isStateLevel==false && isStateLevel1sttime==0) {
  setIsLoading(true)
  setTimeout(() => {
    setIsLoading(false)
    setIsStateLevel1sttime(1)
  }, 5000);}
},[isStateLevel])


  return (
    <>
     {isLoading && <FullScreenLoader/>}
      <Heading as='h3' size='lg' mb='25px' fontSize='19px' mt='20px' className={Styles.customHeadingH3}>
        Geographic Impact
      </Heading>
      <LevelSwitch isStateLevel={isStateLevel} handleToggle={handleToggle} setIsStateLevel={setIsStateLevel}/>
      
      <Card backgroundColor='rgb(201 210 211)' p='0px'>
        <CardBody p='0px'>
          <Box className={Styles.zoomControls}>
            <button className={Styles.zoomIn} onClick={handleZoomIn}>
              <AddIcon w={3} h={3} />
            </button>
            <button className={Styles.zoomOut} onClick={handleZoomOut}>
              <MinusIcon w={3} h={3} />
            </button>
          </Box>
          <Box className={Styles.mapContainer} style={{overflow:"hidden"}}>
          {isStateLevel===false ? (
            <ComposableMap
              style={{ width: '100%', height: 'auto' }}
              projection="geoMercator"
              projectionConfig={{
                scale: defaultScale * zoom,
                center: [10, 10], // Adjust center to ensure Norway is visible
              }}
            >
              <ZoomableGroup>
                <Geographies geography={worldData}>
                  {({ geographies }) =>
                    geographies.filter(geo => geo.properties.ADMIN !== "Antarctica").map((geo) => {
                      const countryCode = geo.properties.ISO_A2;
                      const fillColor = isHighlighted(countryCode) ? '#00a0da' : '#ffffff';
                      const countryValue = highlightedCountries.find(country => country.code === countryCode)?.value;
                      const Mtericdatatype = highlightedCountries.find(
                        (country) => country.code === countryCode
                      )?.metric_type;
                      const newValue = formatNumber(countryValue || 0);
                      const regionMatch = regionData.find(region => region.country_code === countryCode);
                      const regionId = regionMatch?.id || '';
                      const regionParentId = regionMatch?.parent_id || '';
                      const regionMatchParent = regionParentData.find(region => region.id === regionParentId);
                      const regionParentCode = regionMatchParent?.name || '';

                      const isSelected = isHighlighted(countryCode);
                      const strokeColor = isSelected ? '#000000' : '#D6D6DA';
                      const strokeWidth = 0.5;

                      return (
                        <Link to={`/region/${regionParentId}/${regionParentCode}/country/${regionId}/${geo.properties.ADMIN}`} key={geo.rsmKey}>
                          <Tooltip
                            overflow="none"
                            background="#2b2b2b"
                            color="#fff"
                            fontSize="14px"
                            boxShadow="none"
                            label={`${geo.properties.ADMIN}${newValue !=0 ? ":":""} ${newValue !=0 ? newValue:""}`}
                          >
                            <Geography
                              key={geo.rsmKey}
                              geography={geo}
                              fill={fillColor}
                              stroke="f7f7f7"
                              strokeWidth={0}
                              // style={{
                              //   default: { fill: fillColor, outline: 'none' },
                              //   // hover: { fill: '#D2D2D2', outline: 'none', filter: "drop-shadow(0px 1px 1px #2c2c2c50)" },
                              // }}
                              onClick={() => handleCountryClick(geo.properties)}
                            />
                          </Tooltip>
                        </Link>
                      );
                    })
                  }
                </Geographies>
              </ZoomableGroup>
            </ComposableMap>
             ):
             <>
            <MapFOrUSstate countryData={countryData} zoom={zoom} setIsLoading={setIsLoading}/>
             </>}
          </Box>
        </CardBody>
      </Card>
    </>
  );
};

export default RegionMap;




const LevelSwitch = ({isStateLevel,handleToggle,setIsStateLevel}) => {


  return (
    <FormControl display="flex" alignItems="center">
      <Text mr={2} mb={2} color={isStateLevel ? "blue.500" : "gray.500"}>
        State Level
      </Text>
      <Switch
        id="level-switch"
        isChecked={!isStateLevel}
        onChange={handleToggle}
        colorScheme="blue"
        size="sm"
      />
      <Text mb={2}  ml={2} color={!isStateLevel ? "blue.500" : "gray.500"}>
        Country Level
      </Text>
    </FormControl>
  );
};

