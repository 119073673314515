import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from './qr.module.css'
import $ from 'jquery'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select';
import API from '../../../../../../Services/API';
import {Card, CardHeader, CardBody, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Box, Input, Textarea, Button, Image} from '@chakra-ui/react'
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, useDisclosure} from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import CreateReport from './CreateReport/CreateReport';


const QuarterlyReporting = (props) => {
  const token = window.localStorage.getItem("accessToken")
  const [reports, setReports] = useState()
  const [fiscalYearList, setFiscalYearList] = useState()
  const [fiscalQtrList, setFiscalQtrList] = useState()
  const [message, setMessage] = useState(false)
  const [selectedFiscalQtrList, setSelectedFiscalQtrList] = useState([])

  

  useEffect(()=>{
    document.documentElement.scrollTo(0, 0);
    API.get(`/quarterly-report?action_id=${props.actionId}`, {
      headers: {
          'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      setReports(response.data)
    }).catch(error=>{
      
    })
    API.get(`/fiscal-year-detail`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      setFiscalYearList(response.data[0].fiscalyear)
      setFiscalQtrList(response.data[1].fiscalquarter)
    }).catch(error=>{
      
    })
  },[props.actionId])

  const fiscalYear = [
    ...(fiscalYearList && fiscalYearList.length > 0 ? fiscalYearList.map(year => ({ value: year.id, label: year.name, name: 'year'})) : []),
  ];

  const fiscalQtr = [
    ...(fiscalQtrList && fiscalQtrList.length > 0 ? fiscalQtrList.map(quarter => ({ value: quarter.id, label: quarter.name, name: 'qtr'})) : [])
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '12px', 
      fontSize: '12px',
      width: 300,
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '12px',
    }),
  };

  const filterFiscalYear = (selectedOption) => {
    // const values = selectedOption ? selectedOption.map(option => option.value) : [];
    // if(selectedOption.length !== 0){
    //   API.get(`/quarterly-Fiscal-quarter-filter?fiscal_year=${JSON.stringify(values)}&action_id=${props.actionId}`, {
    //     headers: {
    //       'Authorization': 'Bearer ' + token
    //     }
    //   }).then(response=>{
    //     setReports(response.data)
    //   })
    // }else{
    //   API.get(`/quarterly-report?action_id=${props.actionId}`, {
    //     headers: {
    //         'Authorization': 'Bearer ' + token
    //     }
    //   }).then(response=>{
    //     setReports(response.data)
    //   })
    // }
  };

  const filterFiscalQtr = (selectedOption) => {
    const values = selectedOption ? selectedOption.map(option => option.value) : [];
    if(selectedOption.length !== 0){
      API.get(`/quarterly-Fiscal-quarter-filter?fiscal_quarter=${JSON.stringify(values)}&action_id=${props.actionId}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response=>{
        setReports(response.data)
      })
    }else{
      API.get(`/quarterly-report?action_id=${props.actionId}`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
      }).then(response=>{
        setReports(response.data)
      })
    }
  };

  const getUpdatedReports = (reports) => {
    setMessage(true)
    setReports(reports)
  }

  return (
    <>
      <Box mb={'10px'} style={{ display: 'flex', width: '100%' }}>
        <Select
          options={fiscalYear}
          onChange={filterFiscalYear}
          styles={customStyles}
          className={Styles.mr}
          placeholder={'Select fiscal year'}
          isMulti
          isClearable
        />
        <Select
          options={fiscalQtr}
          onChange={filterFiscalQtr}
          styles={customStyles}
          className={Styles.mr}
          placeholder={'Select fiscal QTR'}
          isMulti
          isClearable
        />
        <Box className='clearfix'></Box>
        {
          props.editable ? 
            <CreateReport 
              fiscalYear={fiscalYear}
              fiscalQtr={fiscalQtr}
              actionId={props.actionId}
              getUpdatedReports={getUpdatedReports}
            /> 
          : null
        }
        
      </Box>
      {message ? <Box> Successfully created report </Box> : null}
      <TableContainer>
        <Table>
          <Thead>
            <Tr className={Styles.mnTr}>
              <Th>Core Action Strategy</Th>
              <Th colSpan={'2'}>Goals</Th>
              <Th>Achievements to Date</Th>
              <Th>Next Steps</Th>
              <Th>Communications Opportunities</Th>
              <Th>Support Needed</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              reports && reports.length > 0 ? (
                reports.map((report, index) => (
                  <Tr key={index} className={Styles.mnTr}>
                    <Td>{report.startegy_name}</Td>
                    <Td>{report.fiscal_year_name}</Td>
                    <Td>{report.fiscal_quarter_name}</Td>
                    <Td>{report.achievements_to_date}</Td>
                    <Td>{report.next_steps}</Td>
                    <Td>{report.communications_opportunities}</Td>
                    <Td>{report.support_needed}</Td>
                    <Td>{report.approval_status}</Td>
                  </Tr>
                ))
              ) : (
                <Tr className={Styles.mnTr}>
                  <Td colSpan="8">No reports available</Td>
                </Tr>
              )
            }
          </Tbody>
        </Table>
      </TableContainer>
    </>
    
  )
}

export default QuarterlyReporting