import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from '../executiveleadershipreviews.module.css'
import API from '../../../../../Services/API';
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import AddSlideTwo from '../AddSlideTwo/AddSlideTwo';
import SectionOne from './SectionOne/SectionOne';
import SectionTwo from './SectionTwo/SectionTwo';
import SectionThree from './SectionThree/SectionThree';
import FiscalYear from '../SlideOne/FiscalYear/FiscalYear';

const SlideTwo = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading] = useState(false)
    const [impactStories, setImpactStories] = useState(null);

    useEffect(()=>{
        API.get(`/impact-story?pillar=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setImpactStories(response.data)
            setIsLoading(false)
        }).catch(error=>{
             
            setIsLoading(false)
        })
    },[props.pillarId])

    

    const getSecondSlideUpdate = (slideTwo) =>{
        setImpactStories(slideTwo)
    }

    return (
        <>
            <Box className={Styles.container}>
                {
                    props.editable ?
                    <Box m={'10px'}>
                        <AddSlideTwo pillarId={props.pillarId} getSecondSlideUpdate={getSecondSlideUpdate} />
                        <Box className='clearfix'></Box>
                    </Box> : null
                }
                {
                    impactStories && impactStories.length > 0 ? (
                        impactStories && impactStories.map((story, i) => 
                            <>
                                <Box className={Styles.content}> 
                                    <Grid
                                        templateRows='repeat(2, 1fr)'
                                        templateColumns='repeat(3, 1fr)'
                                        gap={0}
                                    >
                                        <GridItem rowSpan={2} colSpan={1}>
                                            <Box className={Styles.leftGrid}>
                                                <SectionOne 
                                                    id={story.id}
                                                    label = {story.label}
                                                    banner={story.banner}
                                                    sublabel={story.sublabel}
                                                    editable={props.editable}
                                                    getSecondSlideUpdate={getSecondSlideUpdate}
                                                    pillarId={props.pillarId}
                                                />
                                            </Box>
                                            <Box>
                                                <FiscalYear
                                                    editable = {props.editable}
                                                    heading={props.slideData && props.slideData.heading1}
                                                    description={props.slideData && props.slideData.description1}
                                                    pillarId={props.pillarId}
                                                    id={story.id}
                                                    slideOneUpdate={props.slideOneUpdate}
                                                    slideType={'slide2'}
                                                />
                                            </Box>
                                        </GridItem>
                                        <GridItem rowSpan={2} colSpan={2}>
                                            <SectionTwo 
                                                id={story.id}
                                                editable={props.editable}
                                                getSecondSlideUpdate={getSecondSlideUpdate}
                                                pillarId={props.pillarId}
                                                heading={story.heading1}
                                                banner={story.banner1}
                                                description={story.description1}
                                                index={1}
                                            />
                                            <SectionTwo 
                                                id={story.id}
                                                editable={props.editable}
                                                getSecondSlideUpdate={getSecondSlideUpdate}
                                                pillarId={props.pillarId}
                                                heading={story.heading2}
                                                banner={story.banner2}
                                                description={story.description2}
                                                index={2}
                                            />
                                            <SectionTwo 
                                                id={story.id}
                                                editable={props.editable}
                                                getSecondSlideUpdate={getSecondSlideUpdate}
                                                pillarId={props.pillarId}
                                                heading={story.heading3}
                                                banner={story.banner3}
                                                description={story.description3}
                                                index={3}
                                            />
                                        </GridItem>
                                    </Grid>
                                </Box>
                                <Box className={Styles.footer+" footernew"} backgroundColor={'#00a0da'} textAlign={'left'} color={'#ffffff'}>
                                    <SimpleGrid columns={[1, 2, 3]} spacing='20px'>
                                        <SectionThree
                                            id={story.id}
                                            editable={props.editable}
                                            pillarId={props.pillarId}
                                            getSecondSlideUpdate={getSecondSlideUpdate}
                                            banner={story.footerbanner1}
                                            description={story.footer1}
                                            index={1}
                                        />
                                        <SectionThree
                                            id={story.id}
                                            editable={props.editable}
                                            pillarId={props.pillarId}
                                            getSecondSlideUpdate={getSecondSlideUpdate}
                                            banner={story.footerbanner2}
                                            description={story.footer2}
                                            index={2}
                                        />
                                        <SectionThree
                                            id={story.id}
                                            editable={props.editable}
                                            pillarId={props.pillarId}
                                            getSecondSlideUpdate={getSecondSlideUpdate}
                                            banner={story.footerbanner3}
                                            description={story.footer3}
                                            index={3}
                                        />
                                    </SimpleGrid>
                                </Box>
                            </>
                        )
                    ) :  null
                }
            </Box>
        </>
    )
}

export default SlideTwo