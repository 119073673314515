import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import Styles from '../AddImpactMetric/addimpact.module.css'
import API from '../../../../../../Services/API';
import {DeleteIcon} from '@chakra-ui/icons'
import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure,Button,} from '@chakra-ui/react'
import { SketchPicker } from 'react-color';
import ErrorWidget from '../../../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const DeleteMetric = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState()
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null)

    const addMetric = (event) =>{
        setSuccessMessage(null)
        setIsLoading(true)
        const formData = new FormData()
        formData.append('id', props.id)
        formData.append('visible', false)
        API.put(`/metric`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setSuccessMessage('Successfully deleted Impact metric.')
            API.get(`/metric?query=impact`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
              }).then(response1=>{
                props.getUpdate(response1.data)
                onClose()
                setIsLoading(false)
                
              }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)
        })
    }

    return (
        <>
            {
                successMessage ? <ErrorWidget message={successMessage} /> : null
            }
            <Button className={Styles.deleteButton} onClick={onOpen}>
                <DeleteIcon onClick={onOpen} />
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex}>
                    <ModalHeader>Delete impact metric</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure? You can't undo this action afterwards.
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter}>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='blue' onClick={addMetric}>Confirm</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal> 
        </>
    )
}

export default DeleteMetric