import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../qr.module.css'
import $ from 'jquery'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select';
import API from '../../../../../../../Services/API';
import {Card, CardHeader, CardBody, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Box, Input, Textarea, Button, Image, SimpleGrid} from '@chakra-ui/react'
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, useDisclosure} from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Checkbox, 
    Stack,
} from '@chakra-ui/react'
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import LoaderSpinner from '../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const CreateReport = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [selectedFiscalYear, setSelectedFiscalYear] = useState()
    const [selectedFiscalQtr, setSelectedFiscalQtr] = useState()
    const [strategy, setStrategy] = useState()
    const [selectedStrategy, setSelectedStrategy] = useState()
    const [isLoading, setIsLoading] = useState(false)
    
    useEffect(()=>{
        API.get(`/strategy`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
            
          setStrategy(response.data)
        }).catch(error=>{
            setIsLoading(false)
        })
      },[props.actionId])

    const addReport = (event) =>{
        setIsLoading(true)
        const body = {
            "strategy": selectedStrategy,
            "fiscalyear": selectedFiscalYear,
            "fiscalquarter": selectedFiscalQtr,
            "action": props.actionId,
            "next_steps": $('#next_step').val(),
            "communications_opportunities" : $('#comm_opp').val(),
            "support_needed": $('#support_need').val(),
            "approval_status": 'pending'
        }
        API.post(`/quarterly-report`, body, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/quarterly-report?action_id=${props.actionId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
              }).then(response=>{
                onClose()
                props.getUpdatedReports(response.data)
                setIsLoading(false)
              }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)
        })
    }

    const customStyles = {
        control: (provided) => ({
          ...provided,
          borderRadius: '12px', 
          fontSize: '12px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '12px',
        }),
    };

    const filterFiscalYear = (event) =>{
        setSelectedFiscalYear(event.value)
    }
    const filterFiscalQtr = (event) =>{
        setSelectedFiscalQtr(event.value)
    }
    const selectStrategy = (event) =>{
        setSelectedStrategy(event.value)
    }
    const strategyOptions = [
        ...(strategy && strategy.length > 0 ? strategy.map(strategy => ({ value: strategy.id, label: strategy.name})) : []),
    ]

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            <Box>
                <Button className='defaultButton mrgn' onClick={onOpen}> <AddIcon mr={'10px'} onClick={onOpen} /> Add Report </Button>
                <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                    <ModalOverlay className={Styles.overLay} />
                    <ModalContent className={Styles.contentZindex}>
                        <ModalHeader>Add new report</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Report Name</FormLabel>
                                <Input type='text' id={'report_name'}/>
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Select Strategy </FormLabel>
                                <Select
                                    options={strategyOptions}
                                    onChange={selectStrategy}
                                    styles={customStyles}
                                    placeholder={'Select fiscal year'}
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Select fiscal year </FormLabel>
                                <Select
                                    options={props.fiscalYear}
                                    onChange={filterFiscalYear}
                                    styles={customStyles}
                                    placeholder={'Select fiscal year'}
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Select fiscal QTR </FormLabel>
                                <Select
                                    options={props.fiscalQtr}
                                    onChange={filterFiscalQtr}
                                    styles={customStyles}
                                    placeholder={'Select fiscal qtr'}
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Next Steps </FormLabel>
                                <Textarea placeholder='Provide next steps' id='next_step' />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Communications Opportunities</FormLabel>
                                <Textarea placeholder='Provide Communications Opportunities' id='comm_opp' />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Support Needed</FormLabel>
                                <Textarea placeholder='Provide Support Needed' id='support_need' />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter className={Styles.modelFooter} backgroundColor={'transparent'}>
                            <Button variant='ghost' mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='blue' onClick={addReport}>Add</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </>
    )
}

export default CreateReport