import React from 'react';
import Styles from './tabletocsv.module.css';
import {
    Stack,
    Button
} from '@chakra-ui/react';

function TableToCSV({ tableData, filename }) {
    const convertToCSV = (data) => {
        const csv = [];
        const rows = data.querySelectorAll('tr');
        rows.forEach(row => {
            const rowData = [];
            const cells = row.querySelectorAll('td, th');
            cells.forEach((cell, index) => {
                if (index > 0) { // Skip the first cell
                    rowData.push(cell.textContent.trim());
                }
            });
            if (rowData.length > 0) {
                csv.push(rowData.join(','));
            }
        });
        return csv.join('\n');
    };

    const downloadCSV = () => {
        const csvContent = convertToCSV(tableData);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Stack spacing={4} direction='row' justifyContent='flex-end' mb={'15px'}>
            <Button
                className={Styles.downloadCsv}
                size='lg'
                onClick={downloadCSV}
                aria-label="Download Logs"
            >
                Download Logs
            </Button>
        </Stack>
    );
}

export default TableToCSV;