import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../executiveleadershipreviews.module.css'
import { DownloadIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const GeneratePdf = (props) => {
    const generatePdf = () => {
        const input1 = document.getElementById('pdf-content0');
        const input2 = document.getElementById(props.name);

        if (!input1 || !input2) {
            console.error('One or more elements not found');
            return;
        }

        const createCanvas = (element) => {
            return html2canvas(element, {
                useCORS: true,
                allowTaint: true,
                backgroundColor: null,
                logging: true,
                scale: 2,
                ignoreElements: (element) => element.id === 'excludeIcon',
            });
        };

        Promise.all([createCanvas(input1), createCanvas(input2)])
            .then(([canvas1, canvas2]) => {
                // Create a new canvas to combine both canvases
                const combinedCanvas = document.createElement('canvas');
                const combinedContext = combinedCanvas.getContext('2d');

                const width = Math.max(canvas1.width, canvas2.width);
                const height = canvas1.height + canvas2.height;

                combinedCanvas.width = width;
                combinedCanvas.height = height;

                combinedContext.drawImage(canvas1, 0, 0);
                combinedContext.drawImage(canvas2, 0, canvas1.height);

                const pdf = new jsPDF({
                    orientation: 'landscape', // Set document orientation to landscape
                    unit: 'mm',
                    format: 'a4',
                });

                const pageWidth = pdf.internal.pageSize.getWidth();
                const pageHeight = pdf.internal.pageSize.getHeight();

                // Calculate scaling factor to fit content within A4 page dimensions
                const scaleX = pageWidth / combinedCanvas.width;
                const scaleY = pageHeight / combinedCanvas.height;
                const scale = Math.min(scaleX, scaleY);

                const imgWidth = combinedCanvas.width * scale;
                const imgHeight = combinedCanvas.height * scale;

                // Calculate the positions to center the image
                const xPos = (pageWidth - imgWidth) / 2;
                const yPos = (pageHeight - imgHeight) / 2;

                pdf.addImage(combinedCanvas, 'PNG', xPos, yPos, imgWidth, imgHeight);

                // Save the PDF
                pdf.save("download.pdf");
            })
            .catch((error) => {
                console.error("Error generating PDF: ", error);
            });
    };

    return (
        <>
            {props.status === 'Approved by Action Office' ? <DownloadIcon onClick={generatePdf} className={Styles.downloadSlideone} id={'excludeIcon'} /> : null }
        </>
    )
}

export default GeneratePdf