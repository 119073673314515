import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import API from "../../../../Services/API";

const SentimentInsights = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [pageName, setPageName] = useState(null)
    const {id} = useParams();
    
    useEffect(() => {
        API.get(`/pillar?module_id=${id}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setPageName(response.data.name)
        })
    },[props.pillarId])

    return (
        <>
            {
                pageName && pageName === 'Social Justice' ? 
                <>
                    <iframe
                        src="https://external.sprinklr.com/research/insights/listening/dashboard/65d657d33bbe3b0257a6feca/tab/37?id=3C48C13676D10C397448FA4CCEE613E6C648DDAD7F98BA64C4F3811F4F6665D3"
                        width="100%"
                        height="600px"
                        style={{ border: 'none' }}
                        scrolling="yes"
                        frameBorder="0"
                        allow="encrypted-media"
                        title="Dashboard Iframe"
                    ></iframe>
                </> : 
                pageName && pageName === 'Cisco Crisis Response' ? 
                <>
                    <iframe
                        src="https://external.sprinklr.com/research/insights/listening/dashboard/653fdefead2e2f75a1099e41/tab/8?id=6AF7E0A0B5ACBEB47FFE89B0B48511E7857CE6A717C6BC94DC56918340A1CC89"
                        width="100%"
                        height="600px"
                        style={{ border: 'none' }}
                        scrolling="yes"
                        frameBorder="0"
                        allow="encrypted-media"
                        title="Dashboard Iframe"
                    ></iframe>
                </> :
                pageName && pageName === 'SII & Cisco Foundation' ? 
                <>
                    <iframe
                        src="https://external.sprinklr.com/research/insights/listening/dashboard/652458c92e42130a1102e4f9/tab/7?id=913BDECAF250F42FA3C7E8DB91CE2B7210B6925EF41D6A25790E536B72DE39E9"
                        width="100%"
                        height="600px"
                        style={{ border: 'none' }}
                        scrolling="yes"
                        frameBorder="0"
                        allow="encrypted-media"
                        title="Dashboard Iframe"
                    ></iframe>
                </> : 
                pageName && pageName === 'Community Impact' ? 
                <>
                    <iframe
                        src="https://external.sprinklr.com/research/insights/listening/dashboard/6537f64a7b75dc6ad153fec7/tab/8?id=E74754D9B753F61219252468B9D6E7AB834A5F4AB22F65A6F500A350D4EF06B8"
                        width="100%"
                        height="600px"
                        style={{ border: 'none' }}
                        scrolling="yes"
                        frameBorder="0"
                        allow="encrypted-media"
                        title="Dashboard Iframe"
                    ></iframe>
                </> :
                'It seems there’s nothing to display based on your current selection.'
            }
        </>
    )
}

export default SentimentInsights