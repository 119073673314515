import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import Styles from '../../../MetricData/metricdata.module.css'
import API from '../../../../../Services/API';
import { IconButton } from '@chakra-ui/react'
import { EditIcon, DeleteIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    Tooltip,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Card,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Checkbox, 
    Stack,
    Textarea,
    Text,
    Button
} from '@chakra-ui/react'
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';

const RejectComponent = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const editor = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [metricData, setMetricData] = useState()
    const [isLoading, setIsLoading] = useState()
    const [message, setMessage] = useState()
    const [errorMessage, setErrorMessage] = useState(null)
    

    const [selectedOption, setSelectedOption] = useState(props.defaultSelected)

    const approveMetric = (event) => {
      onClose()
      setIsLoading(true)

      const formData = new FormData()
      formData.append('id', parseInt(props.id))
      formData.append('approval_status', 'Rejected')
      formData.append('action', props.action)
      formData.append('comment', $("#description").val())
      
      API.put(`/elr-dashboard`, formData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response=>{
        if(response.data.error){
          props.getErrorUpdate(response.data.error)
          setIsLoading(false)
        }else{
          API.get(`/elr-dashboard`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then(response=>{
              props.getUpdatedPending(response.data)
              setIsLoading(false)
          }).catch(error=>{
            setIsLoading(false)
          })
        }
      }).catch(error =>{
        props.getErrorUpdate(error.response.data.error)
        setIsLoading(false)
      })
    }

    const handleChange = selectedOption => {
      setSelectedOption(selectedOption.value)
     
    };

    return (
      <>
        {isLoading ? <LoaderSpinner /> : null}
        <Tooltip id={props.id} label="Reject" aria-label="Reject"> 
          <IconButton
            variant='outline'
            colorScheme='teal'
            aria-label='Call Sage'      
            fontSize='14px'
            icon={<CloseIcon id={props.id} />}
            className={`${Styles.reject} ${Styles.hoverEffectReject}`} id={props.id}
            onClick={onOpen}
          />
        </Tooltip>
        <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
          <ModalOverlay className={Styles.overLay} />
          <ModalContent className={Styles.contentZindex} top={'0'}>
              <ModalCloseButton onClick={onClose} zIndex={'9'} />
              <ModalBody>
                <FormControl>
                  <FormLabel fontWeight={'bold'}> Reason for rejection </FormLabel>
                  <Textarea id={"description"}>                          
                  </Textarea>
                </FormControl>
              </ModalBody>
              <ModalFooter className={Styles.modelFooter}>
                  <Button mr={3} size="sm" onClick={onClose}>
                      Cancel
                  </Button>
                  <Button className={Styles.confirmButton} id={props.id}  size="sm" onClick={approveMetric}>Confirm</Button>
              </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default RejectComponent