import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import Styles from './delete.module.css'
import { DeleteIcon, PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import API from '../../../../../../../Services/API';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Card,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Checkbox, 
    Stack,
    Textarea,
    Text,
    Button
} from '@chakra-ui/react'
import LoaderSpinner from '../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const DeleteMetric = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] =useState(false)

    const deleteMetric = (event) =>{
        props.isLoaderloading(true)
        const formData = new FormData()
        formData.append("visible", false)
        formData.append("id", parseInt(props.id))
        API.put(`/metric`,formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            API.get(`/metric?action_id=${props.actionId}`, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .then((response1) => {
              onClose()
              props.getUpatedMetric(response1.data)
              props.isLoaderloading(false)
              setIsLoading(false)
            }).catch(error=>{
              setIsLoading(false)
            })
        })
    }

    return (
        <>
            {
                isLoading ? <LoaderSpinner /> : null
            }
            <DeleteIcon className='frontFlipIcon' onClick={onOpen} />
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex}>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text className={Styles.contentInfo}>
                          Are you sure you want to delete it? This action cannot be reversed.
                        </Text>
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter}>
                        <Button mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={Styles.confirmButton} id={props.id} onClick={deleteMetric}>Confirm</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DeleteMetric