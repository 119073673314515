import React, { useEffect, useState, useMemo } from "react";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import { Box, Text, Tooltip } from "@chakra-ui/react";
import { formatNumber } from "./NumberFormat";
import { getMetricdatatypeSymbol } from "./GeographicImpact";

const MapChart = ({ countryData, zoom, setIsLoading }) => {
  const [tooltipContent, setTooltipContent] = useState("");
  const [highlightedState, setHighlightedState] = useState(null);
  const [isLoading1, setIsLoading1] = useState(true);
  const [geojsonAllStateURL, setGeojsonAllStateURL] = useState(null);

  const countryMap = useMemo(() => {
    const filterdData= countryData.filter((item)=>item.parent_id!=null)
    const mapByCountryName = new Map();
    const mapByParentName = new Map();
  
    filterdData && filterdData.forEach(({ country_name, value, parent_name, metric__datatype }) => {
      if (country_name) mapByCountryName.set(country_name.trim(), { value, metric__datatype });
      if (parent_name) mapByParentName.set(parent_name, { value, metric__datatype });
    });
  
    return { mapByCountryName, mapByParentName };
  }, [countryData]);
  

  useEffect(() => {
    const fetchGeojsonData = async () => {
      try {
        setIsLoading(true);
        setIsLoading1(true);
        const response = await fetch(
          "https://esg-production.s3.us-west-1.amazonaws.com/geojson/geo.geojson"
        );
        if (response.ok) {
          const data = await response.json();
          setGeojsonAllStateURL(data);
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);
          setIsLoading1(false);
        } else {
          console.error("Failed to load GeoJSON data");
        }
      } catch (error) {
        console.error("Error fetching GeoJSON data:", error);
      }
    };

    fetchGeojsonData();
  }, []);

  const handleMouseEnter = (geo) => {
    setTooltipContent(geo.properties.admin);
    setHighlightedState(geo.properties.name);
  };

  const handleMouseLeave = () => {
    setTooltipContent("");
    setHighlightedState(null);
  };

  return (
    <div style={{ width: "100%", height: "auto", position: "relative" }}>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{
          scale: 130 * zoom,
          center: [10, 10],
        }}
        style={{
          width: "100%",
          margin: "auto",
          height: "100%",
        }}
      >
         <ZoomableGroup>
        {geojsonAllStateURL && (
          <Geographies geography={geojsonAllStateURL}>
            {({ geographies }) =>
              geographies
                .filter((geo) => geo.properties.admin !== "Antarctica")
                .map((geo) => {
                  const countryDatamapvalue = countryMap.mapByCountryName.get(geo.properties.name);
                  const countryValue = countryDatamapvalue?.value;
                  const Mtericdatatype = countryDatamapvalue?.metric__datatype;
                  const isHighlightedState =
                    countryValue !== undefined &&
                    countryData.some(
                      ({ country_name }) =>
                        country_name.trim() === geo.properties.admin || country_name.trim() === geo.properties.name
                    );

                  return (
                    <Tooltip
                      key={geo.rsmKey}
                      label={
                        <>
                          <Box>
                            <Text sx={{ margin: 0 }}>
                              {geo.properties.admin}:{geo.properties.name}
                            </Text>
                            {countryValue != undefined && (
                              <Text sx={{ margin: 0 }}>
                                {Mtericdatatype
                                  ? getMetricdatatypeSymbol(Mtericdatatype)
                                  : ""}{" "}
                                {formatNumber(countryValue)}
                              </Text>
                            )}
                          </Box>
                        </>
                      }
                    >
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={
                          isHighlightedState ||
                          highlightedState === geo.properties.name
                            ? "#00a0da"
                            : "#ffffff"
                        }
                        stroke="#f7f7f7"
                        strokeWidth={0.1}
                        // onMouseEnter={() => handleMouseEnter(geo)}
                        // onMouseLeave={handleMouseLeave}
                      />
                    </Tooltip>
                  );
                })
            }
          </Geographies>
        )}
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};

export default React.memo(MapChart);
