import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
    Input, InputGroup, InputRightElement, IconButton, 
    Box, Text, VStack, List, ListItem, Divider, HStack 
} from '@chakra-ui/react';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate
import API from '../../../Services/API';

const Search = () => {
    const token = window.localStorage.getItem("accessToken");
    const navigate = useNavigate(); // Initialize useNavigate
    const [query, setQuery] = useState('');
    const [results, setResults] = useState({});
    const [isExpanded, setIsExpanded] = useState(false);
    const [metrics, setMetrics] = useState([]);
    const [pillars, setPillars] = useState([]);
    const [pillarActions, setPillarActions] = useState([]);
    const [blogList, setBlogList] = useState([]);
    const location = useLocation(); // Initialize useLocation
    const searchRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [metricsRes, pillarsRes, actionsRes, blogsRes] = await Promise.all([
                    API.get('/all-metric-dashboard', { headers: { Authorization: `Bearer ${token}` } }),
                    API.get('/sio-pillar', { headers: { Authorization: `Bearer ${token}` } }),
                    API.get('/get-pillar-action', { headers: { Authorization: `Bearer ${token}` } }),
                    API.get('/blog', { headers: { Authorization: `Bearer ${token}` } }),
                ]);
                setMetrics(metricsRes.data);
                setPillars(pillarsRes.data);
                setPillarActions(actionsRes.data);
                setBlogList(blogsRes.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [token]);

    const groupedOptions = {
        "Metrics": metrics,
        "SIIO Organization": pillars,
        "Actions": pillarActions,
        "Impact Gallery": blogList,
    };

    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };

    const performSearch = useCallback(
        debounce((searchQuery) => {
            const filteredResults = {};
            Object.keys(groupedOptions).forEach((group) => {
                const items = groupedOptions[group].filter((item) => {
                    const itemName = group === "Impact Gallery" ? item.title : item.name;
                    return itemName.toLowerCase().includes(searchQuery.toLowerCase());
                });
                if (items.length > 0) {
                    filteredResults[group] = items;
                }
            });
            setResults(filteredResults);
        }, 300),
        [groupedOptions]
    );

    const handleInputChange = (e) => {
        const searchQuery = e.target.value;
        setQuery(searchQuery);
        performSearch(searchQuery);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const searchURL = `/searchresults?q=${encodeURIComponent(query)}`;

            if (location.pathname === '/searchresults') {
                // Already on /searchresults, update query params
                const urlParams = new URLSearchParams(location.search);
                urlParams.set('q', query);
                navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true }); // Update URL without navigating
                // Trigger search result update (optional logic for that page)
                window.dispatchEvent(new CustomEvent('updateSearch', { detail: { query } }));
            } else {
                // Navigate to /searchresults in a new tab
                window.open(searchURL, '_blank');
            }
        }
    };

    const clearSearch = () => {
        setQuery('');
        setResults({});
        setIsExpanded(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                clearSearch();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const SearchResultItem = ({ group, item }) => {
        const linkTo =
            group === "Actions"
                ? `/action/${item.module}/${encodeURIComponent(item.name)}`
                : group === "Impact Gallery"
                ? `/viewpost/${item.id}/${encodeURIComponent(item.title)}`
                : `/metric/${item.id}/${encodeURIComponent(item.name)}`;

        return (
            <ListItem
                p={2}
                borderRadius="md"
                _hover={{ bg: 'teal.50', cursor: 'pointer' }}
                _active={{ bg: 'teal.100' }}
            >
                <Link to={linkTo} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {group === "Impact Gallery" ? item.title : item.name}
                </Link>
            </ListItem>
        );
    };

    return (
        <Box w="100%" maxW="600px" mx="auto" mt={0} position="relative" ref={searchRef}>
            {isExpanded ? (
                <InputGroup>
                    <Input
                        placeholder="Search metrics, impact gallery and more."
                        value={query}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown} // Listen for Enter key
                        variant="filled"
                        focusBorderColor="teal.400"
                        autoFocus
                    />
                    <InputRightElement>
                        <IconButton
                            aria-label="Clear search"
                            icon={<CloseIcon />}
                            size="sm"
                            onClick={clearSearch}
                            color="gray.500"
                            variant="ghost"
                        />
                    </InputRightElement>
                </InputGroup>
            ) : (
                <HStack spacing={2}>
                    <IconButton
                        icon={<SearchIcon />}
                        aria-label="Search"
                        onClick={() => setIsExpanded(true)}
                        colorScheme="teal"
                    />
                </HStack>
            )}
            {Object.keys(results).length > 0 && (
                <VStack
                    align="start"
                    spacing={4}
                    bg="white"
                    p={4}
                    borderRadius="md"
                    boxShadow="md"
                    position="absolute"
                    top="100%"
                    w="100%"
                    zIndex="1"
                >
                    <Box
                        maxHeight="300px"
                        overflowY="auto"
                        w="100%"
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: '#4FD1C5',
                                borderRadius: '24px',
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                background: '#2C7A7B',
                            },
                            '&::-webkit-scrollbar-track': {
                                background: '#EDF2F7',
                            },
                        }}
                    >
                        {Object.entries(results).map(([group, items], index) => (
                            <Box key={index} w="100%">
                                <Text fontWeight="bold" fontSize="lg" color="teal.600">
                                    {group}
                                </Text>
                                <List spacing={2}>
                                    {items.map((item, itemIndex) => (
                                        <SearchResultItem key={itemIndex} group={group} item={item} />
                                    ))}
                                </List>
                                {index < Object.entries(results).length - 1 && <Divider my={4} />}
                            </Box>
                        ))}
                    </Box>
                </VStack>
            )}
        </Box>
    );
};

export default Search;
