import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from './executiveleadershipreviews.module.css'
import API from '../../../../Services/API';
import {Heading, Select, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';

import LoaderSpinner from '../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Checkbox, 
    Stack,
} from '@chakra-ui/react'
import DeleteElr from './DeleteElr/DeleteElr'
import AddSlideOne from './AddSlideOne/AddSlideOne';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DownloadIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import PptxGenJS from 'pptxgenjs';


import SlideOne from './SlideOne/SlideOne';
import SlideTwo from './SlideTwo/SlideTwo'

import FiscalYear from './SlideOne/FiscalYear/FiscalYear';


const ExecutiveLeadershipReviews = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState(false)
    const [elrSectionOne, setElrSectionOne] = useState()
    const [elrMetric, setElrMetric] = useState()
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [viewOneId, setViewOneId] = useState(null);
    const [impactStories, setImpactStories] = useState(null);
    const [elr, setElr] = useState(null);
    const [noData, setNoData] = useState(0);
    const editor = useRef(null);
    const [status, setStatus] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const [elrFile, setElrFile] = useState(null);
    const [submitMessage, setSubmitMessage] = useState(null);
    const [fiscalYearList, setFiscalYearList] = useState()
    const [fiscalQtrList, setFiscalQtrList] = useState()
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const [selectedFiscalQtr, setSelectedFiscalQtr] = useState(null);
    const [selectedFiscalYearList, setSelectedFiscalYearList] = useState(false)

    function bannerUpload(e) {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        // Create a file reader to read the file and generate a preview URL
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        } else {
            setPreview(null);
        }
    }

    const submitElr = (event) => {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('id', parseInt(viewOneId))
        formData.append('approval_status','Ready to Submit')
        formData.append('pillar', props.pillarId)
      
        API.put(`/elr-dashboard`, formData, {
            headers: {
            'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            API.get(`/elr-dashboard?pillar=${props.pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                
                setElrSectionOne(response.data)
                setPreview(response.data && response.data[0].banner)
                setViewOneId(response.data && response.data[0].id)
                setStatus(response.data && response.data[0].approval_status)
                setIsLoading(false)
                setNoData(0)
                setSubmitMessage('The ELR has been submitted for approval. You will receive an update by email soon.')
            }).catch(error=>{
                setErrorMessage(error.response.data.msg)
                setIsLoading(false)
                setNoData(1)
            })
        }).catch(error =>{
            setErrorMessage(error.response.data.msg)
            setIsLoading(false)
        })
    }

    useEffect(()=>{
        setIsLoading(true)
        API.get(`/elr-dashboard?pillar=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            
            setElrSectionOne(response.data)
            setPreview(response.data && response.data[0].banner)
            setViewOneId(response.data && response.data[0].id)
            setStatus(response.data && response.data[0].approval_status)
            setIsLoading(false)
            setNoData(0)
        }).catch(error=>{
            setIsLoading(false)
            setNoData(1)
        })

        API.get(`/impact-story?pillar=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setImpactStories(response.data)
            setIsLoading(false)
        }).catch(error=>{
             
            setIsLoading(false)
        })

        API.get(`/executive-level-review?pillar=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setElr(response.data)
            setIsLoading(false)
        }).catch(error=>{
             
            setIsLoading(false)
        })
        API.get(`/fiscal-year-detail`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setFiscalYearList(response.data[0].fiscalyear)
            setFiscalQtrList(response.data[1].fiscalquarter)
        })

    },[props.pillarId])
    const [elrBanner, setElrBanner] = useState(null);

    function elrBannerUpload(e) {
        const selectedFile = e.target.files[0];
        setElrBanner(selectedFile);
    }

    const addElr = (event) => {
        setIsLoading(true)
        const formData = new FormData()
        if(elrBanner != null){
            formData.append('banner', elrBanner)
        }
        formData.append('label', $("#elr_label").val())
        formData.append('description', $("#elrDescription").val())
        formData.append('pillar', parseInt(props.pillarId))
        //formData.append('elr', 1)
        
        API.post(`/executive-level-review`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/executive-level-review?pillar=${props.pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                onClose()
                setElr(response.data)
                setIsLoading(false)
            }).catch(error=>{
                 
                setIsLoading(false)
            })
        }).catch(error=>{
            alert('Facing technicale during application run, Please contact your adminstration')
            
            setIsLoading(false)

        })

    }

    const getUpdateElr = (elrView) =>{
        setElr(elrView)
    }

    const getFirstSlideUpdate = (slideOne) =>{
        setElrSectionOne(slideOne)
        setPreview(slideOne && slideOne.banner)
        setViewOneId(slideOne && slideOne.id)
    }
    
    const captureScreenshotById = async (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
          const canvas = await html2canvas(element);
          const imgData = canvas.toDataURL('image/png');
          return imgData;
        }
        return null;
    };

    const generatePowerPoint = async () => {
        const pptx = new PptxGenJS();
        const slide = pptx.addSlide();
    
        const imgData1 = await captureScreenshotById('pdf-content1');
        const imgData2 = await captureScreenshotById('pdf-content2');
        const imgData3 = await captureScreenshotById('pdf-content3');
    
        if (imgData1) {
          slide.addImage({
            data: imgData1,
            x: 0.5, // x position in inches
            y: 0.5, // y position in inches
            w: 8, // width in inches
            h: 4.5, // height in inches
          });
        }
    
        if (imgData2) {
          const slide2 = pptx.addSlide();
          slide2.addImage({
            data: imgData2,
            x: 0.5,
            y: 0.5,
            w: 8,
            h: 4.5,
          });
        }

        if (imgData3) {
            const slide3 = pptx.addSlide();
            slide3.addImage({
              data: imgData3,
              x: 0.5,
              y: 0.5,
              w: 8,
              h: 4.5,
            });
          }
    
        pptx.writeFile('React_UI_Presentation.pptx');
      };
    

    

    const generatePdf1 = (event) => {
        const input = document.getElementById('pdf-content1');
        if (input) {
            html2canvas(input, {
                useCORS: true,
                allowTaint: true,
                backgroundColor: null, // This ensures that the background image is preserved
                logging: true, // Enable logging for debugging
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210; // A4 width in mm
                const pageHeight = 297; // A4 height in mm
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;

                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                pdf.save("download.pdf");
            }).catch((error) => {
                console.error("Error generating PDF: ", error);
            });
        } else {
            console.error("Element not found: #pdf-content");
        }
    };
    const generatePdf2 = (event) => {
        const input = document.getElementById('pdf-content2');
        if (input) {
            html2canvas(input, {
                useCORS: true,
                allowTaint: true,
                backgroundColor: null, // This ensures that the background image is preserved
                logging: true, // Enable logging for debugging
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210; // A4 width in mm
                const pageHeight = 297; // A4 height in mm
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;

                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                pdf.save("download.pdf");
            }).catch((error) => {
                console.error("Error generating PDF: ", error);
            });
        } else {
            console.error("Element not found: #pdf-content");
        }
    };

    const generatePdf3 = (event) => {
        const input = document.getElementById('pdf-content2');
        if (input) {
            html2canvas(input, {
                useCORS: true,
                allowTaint: true,
                backgroundColor: null, // This ensures that the background image is preserved
                logging: true, // Enable logging for debugging
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210; // A4 width in mm
                const pageHeight = 297; // A4 height in mm
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;

                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                pdf.save("download.pdf");
            }).catch((error) => {
                console.error("Error generating PDF: ", error);
            });
        } else {
            console.error("Element not found: #pdf-content");
        }
    };

    const generateMulPdf = () => {
        const divIds = ['pdf-content1', 'pdf-content2', 'pdf-content3'];

        const promises = divIds.map(id => {
            const input = document.getElementById(id);
            return html2canvas(input, {
                useCORS: true,
                allowTaint: true,
                backgroundColor: null,
                logging: true,
            });
        });

        Promise.all(promises).then(canvases => {
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm

            canvases.forEach((canvas, index) => {
                const imgData = canvas.toDataURL('image/png');
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                if (index > 0) {
                    pdf.addPage();
                }
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            });

            pdf.save("download.pdf");
        }).catch(error => {
            console.error("Error generating PDF: ", error);
        });
    };

    const saveElrIn = (event) =>{
        setIsLoading(true)
        const formData = new FormData()
        if(elrBanner != null){
            formData.append('banner', elrBanner)
        }
        formData.append('label', $("#elr_label"+event.target.id).val())
        formData.append('description', $("#elrDescription"+event.target.id).val())
        formData.append('pillar', parseInt(props.pillarId))
        formData.append('id', event.target.id)
        
        API.put(`/executive-level-review`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/executive-level-review?pillar=${props.pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                onClose()
                setElr(response.data)
                setIsLoading(false)
            }).catch(error=>{
                 
                setIsLoading(false)
            })
        }).catch(error=>{
            
            setIsLoading(false)

        })
    }

    const elrBannerUploadIn = async (e, elrId) => {
        setElrFile(null)
        const selectedFile = e.target.files[0];
        setElrFile(selectedFile);
    };

    const slideOneUpdate = (slideOneUpdate) => {
        setElrSectionOne(slideOneUpdate)
    }

    const getFiscalYear = (event) => {
        setSelectedFiscalYear(event.target.value)
        const selectedOption = event.target[event.target.selectedIndex];
        
        const dataValue = selectedOption.dataset.value;
        const getYear = dataValue && dataValue.match(/\d{4}/)
        if(getYear) {
            setSelectedFiscalYearList("FY"+getYear[0].toString().substring(2))
            
        }
    }

    const getFiscalQtr = (event) =>{
        setSelectedFiscalQtr(event.target.value)
    }

    const sortedFiscalYearList = fiscalYearList && fiscalYearList.sort((a, b) => a.name.localeCompare(b.name));
    const sortedFiscalQtrList = fiscalQtrList && fiscalQtrList.sort((a, b) => a.name.localeCompare(b.name));


    return (
        <>
            {isLoading ? <LoaderSpinner /> : null }
            {
                errorMessage !== null ? <Box p={'0px 0 10px 10px'}> {errorMessage && errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()} </Box> : null
            }
            {/* {
                errorMessage !== '' ? <Box> Facing technical issue, please contact your administrator </Box> : null
            } */}
            {
                status === 'Approve' ? <Button onClick={generateMulPdf} className='buttonPrimary' position={'absolute'} right={'30px'}> Download all</Button> : null
            }

            {submitMessage !== null ? <Box> {submitMessage} </Box> : null}

            {/* <button onClick={generatePowerPoint}>Generate PowerPoint</button> */}
            
            
            <SimpleGrid columns={[1]} spacing='10px'>
                <Heading className={Styles.slideHeading}> Executive Leader Reporting 
                    {status === 'Approved By Comms Team' ?<DownloadIcon onClick={generatePdf1} className={Styles.downloadSlideone} /> : null }
                </Heading>
                {
                    props.editable && (elrSectionOne && elrSectionOne.length < 1) ? <AddSlideOne pillarId={props.pillarId} getFirstSlideUpdate={getFirstSlideUpdate} /> : null
                }
                <Box id='pdf-content1'>
                    {
                        elrSectionOne && elrSectionOne.map(slide1 =>
                            <>
                                <SlideOne 
                                    status={status} 
                                    editable={props.editable}
                                    preview={preview}
                                    slideData={slide1}
                                    pillarId={props.pillarId}
                                    heading1={slide1.heading1}
                                    description1={slide1.description1}
                                    heading2={slide1.heading2}
                                    description2={slide1.description2}
                                    heading3={slide1.heading3}
                                    description3={slide1.description3}
                                    heading4={slide1.heading4}
                                    description4={slide1.description4}
                                    slideOneUpdate={slideOneUpdate}
                                    elrMetric={elrMetric && elrMetric}
                                />
                            </>
                        )
                    }
                </Box>
                <Heading className={Styles.slideHeading} mt={'15px'}> Impact Stories
                    {status === 'Approved By Comms Team' ? <DownloadIcon onClick={generatePdf2} className={Styles.downloadSlideone} /> : null}
                </Heading>
                <Box id='pdf-content2'>
                    <SlideTwo
                        editable={props.editable}
                        pillarId={props.pillarId}
                    />
                </Box>
                <Heading className={Styles.slideHeading} mt={'15px'}> Executive Level Review 
                    {status === 'Approved By Comms Team' ? <DownloadIcon onClick={generatePdf3} className={Styles.downloadSlideone} /> : null }
                </Heading>
                <Box border={'1px solid #e2e8f0'} id='pdf-content3'>
                    {props.editable ? 
                        <Box>
                            <Button className={'defaultButton newElrButton'}  onClick={onOpen}> Create ELR</Button>     
                            <Box className='clearfix'></Box>
                        </Box>
                    : null}
                    <Box className='clearfix'></Box>
                    <SimpleGrid spacing={4} columns={[3]}>
                        {
                            elr && elr.map((elr, i)=>
                                <Card>
                                    {
                                        props.editable ? <DeleteElr id={elr.id} getUpdateElr={getUpdateElr} pillarId={props.pillarId}/> : null
                                    }
                                    {
                                        props.editable ? 
                                        <>
                                            <CardHeader>
                                                <FiscalYear
                                                    editable = {props.editable}
                                                    heading={props.slideData && props.slideData.heading1}
                                                    description={props.slideData && props.slideData.description1}
                                                    pillarId={props.pillarId}
                                                    id={props.slideData && props.slideData.id}
                                                    slideOneUpdate={props.slideOneUpdate}
                                                    slideType={'slide3'}
                                                />
                                                <FormControl>
                                                    <FormLabel fontWeight={'bold'}>Label</FormLabel>
                                                    <Input type='text' id={'elr_label'+elr.id} mb={'15px'} defaultValue={elr.label}  />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel fontWeight={'bold'}>Icon</FormLabel>
                                                    <Input type="file" id={'bannerUpload'+elr.id} onChange={(e) => elrBannerUploadIn(e, elr.id)} padding={"4px"}/>
                                                    <Image src={elr.banner} width={'60px'} mt={'15px'} alt={elr.label} />
                                                </FormControl>
                                                
                                                
                                            </CardHeader>
                                            <CardBody>
                                                <FormLabel fontWeight={'bold'}>Description</FormLabel>
                                                <JoditEditor
                                                    id={"elrDescription"+elr.id}
                                                    ref={editor}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onChange={newContent => {}}
                                                    value={elr.description}
                                                />
                                            </CardBody> 
                                            <CardFooter>
                                                <Button className='buttonPrimary' onClick={saveElrIn} id={elr.id}> Save </Button>
                                            </CardFooter>                                       
                                        </> : 
                                        <>
                                            <CardHeader>
                                                <Image src={elr.banner} width={'60px'} alt={elr.label} />
                                                <Heading size='md' className={Styles.boxHeading}> {elr.label}</Heading>
                                            </CardHeader>
                                            <CardBody>
                                                {elr.description != null  ? parse(elr.description) : ''}
                                            </CardBody>
                                        </>
                                    }
                                </Card> 
                            )
                        }
                    </SimpleGrid>
                    {/* {status !== 'Approve' ? <Button className='buttonPrimary' display={'block'} m={'20px auto'} onClick={submitElr}> Submit for approval </Button>:null} */}
                </Box>
            </SimpleGrid>
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex} maxW={'580px'}>
                    <ModalHeader>Add new ELR</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Label</FormLabel>
                            <Input type='text' id={'elr_label'} mb={'15px'} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Icon</FormLabel>
                            <Input type="file" onChange={elrBannerUpload} padding={"4px"}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <JoditEditor
                                id={"elrDescription"}
                                ref={editor}
                                tabIndex={1} // tabIndex of textarea
                                onChange={newContent => {}}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter}>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button variant='ghost' onClick={addElr}>Add</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ExecutiveLeadershipReviews