import React from 'react'
import { Tr, Td, Card, CardBody, Heading, Stack, StackDivider, Box, Text, SimpleGrid, Select, useToast, Wrap, WrapItem, Avatar } from '@chakra-ui/react'
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'

const LogsLoader = () => {
    return (
        <>
            <Tr>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
            </Tr>
            <Tr>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
            </Tr>
            <Tr>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
            </Tr>
            <Tr>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
            </Tr>
            <Tr>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
            </Tr>
            <Tr>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
            </Tr>
            <Tr>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
            </Tr>
            <Tr>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
            </Tr>
            <Tr>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
            </Tr>
            <Tr>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
            </Tr>
            <Tr>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
            </Tr>
            <Tr>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
            </Tr>
            <Tr>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
                <Td>
                    <Stack>
                        <Skeleton height='20px' />
                    </Stack>
                </Td>
            </Tr>
        </>
    )
}

export default LogsLoader