import React, { useState, useEffect, useRef, useContext } from 'react';
import DataFilter from './DataFilter/DataFilter';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery'
import Styles from './impactgallery.module.css'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import API from '../../../Services/API'
import BreadCrumbs from '../../../Components/Widgets/BreadCrumbs/BreadCrumbs'
import { Heading, SimpleGrid, Card, CardBody, Image, Stack, StackDivider, Box, Button, Text } from '@chakra-ui/react'
import { Input, FormControl, FormLabel } from '@chakra-ui/react'
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import JoditEditor from 'jodit-react';
import PlaceholderImg from '../../../../assets/images/placeholder-1.png'
import LoaderSpinner from '../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import PageInfo from './PageInfo/PageInfo';
import DeleteBlog from './DeleteBlog/DeleteBlog';
import { EditContext } from '../../../Context/BreadcrumbsContext';
import RegionMap from './RegionMap/RegionMap'
import ErrorWidget from '../../../Components/Widgets/ErrorWidget/ErrorWidget';


const ImpactGallery = (props) => {
  const token = window.localStorage.getItem("accessToken")
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  const editor = useRef(null);
  const [isLoading, setIsLoading] = useState(false)
  const [editable, setEditable] = useState();
  const [blogList, setBlogList] = useState();
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState(false)
  const [deleteMessage, setDeleteMessage] = useState(false)
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pageTitle, setPageTitle] = useState()
  const { edit, setEdit } = useContext(EditContext);
  const { id } = useParams();
  const { name } = useParams();
  const [countries, setCountries] = useState([])
  const [pillarActions, setPillarActions] = useState([])
  const [sioPillars, setSioPillars] = useState([]);
  const [tagDetails, setTagDetails] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectionType, setSelectionType] = useState(null)
  const [blogYears, setBlogYears] = useState(null)
  const aspectRatio = 16 / 9;
  const handleChange = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    setSelectedDate(`${year}-${month}-${day}`);
  };
  const [selectedValues, setSelectedValues] = useState({
    tagDetails: [],
    sioPillars: [],
    pillarActions: [],
    regionList: [],
    countries: [],
  });

  const geteditStatus = (isEdit) => {
    setEditable(isEdit)
  }

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
    let date = new Date()
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    setSelectedDate(`${year}-${month}-${day}`);
    API.get(`/blog`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    .then((response) => {
      setBlogList(response.data)
    }).catch(error=>{
      setIsLoading(false)
    })
    
    API.get(`/country-list`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then((response) => {
      setCountries(response.data)
    }).catch(error=>{
      //setIsLoading(false)
    })

    API.get(`/get-pillar-action`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      setPillarActions(response.data && response.data)
      setIsLoading(false)
    }).catch(error=>{
      setIsLoading(false)
    })

    API.get(`/sio-pillar`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      setSioPillars(response.data && response.data)
      setIsLoading(false)
    }).catch(error=>{
      setIsLoading(false)
    })

    API.get(`/tag-detail/`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      console.log("response",response?.data?.results)
      setTagDetails(response.data && response?.data?.results)
      setIsLoading(false)
    }).catch(error=>{
      setIsLoading(false)
    })

    API.get(`/region-list`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      setRegionList(response.data && response.data)
      setIsLoading(false)
    }).catch(error=>{
      setIsLoading(false)
    })

    API.get(`/get-year`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      setBlogYears(response.data && response.data)
      setIsLoading(false)
    }).catch(error=>{
      setIsLoading(false)
    })
  }, [token])

  const getUpdatedList = (updatedList) => {
    setBlogList(updatedList)
  }

  const getDeleteMessage = (updatedList) => {
    setDeleteMessage(updatedList)
    setMessage(false)
  }

  if (!blogList || !Array.isArray(blogList)) {
    return <LoaderSpinner />
  }

  // Helper function to get month name
  const getMonthYear = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
  }

  // Sorting and grouping the blog list by month
  const sortedData = blogList.slice().sort((a, b) => new Date(b.date) - new Date(a.date));

  const groupedData = sortedData.reduce((acc, curr) => {
    const monthYear = curr.date ? getMonthYear(curr.date) : 'Gallery with no Date'; // Handle null or undefined dates
    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(curr);
    return acc;
  }, {});

  const openSlidingPane = () => {
    setState({ isPaneOpen: true })
  }

  function bannerUpload(e) {
    setFile(e.target.files[0]);
  }

  const createBlog = () => {
    setIsLoading(true)
    const formData = new FormData()
    if (file != null) {
      formData.append('cover_image', file)
    }
    formData.append('title', $("#blogTitle").val())
    formData.append('author', $("#authorName").val())
    formData.append('content', $("#blogContent").val())
    formData.append('date', selectedDate)
    
    formData.append('blog_tag', JSON.stringify(selectedValues.tagDetails))
    formData.append('blog_pillar', JSON.stringify(selectedValues.pillarActions))
    formData.append('blog_action', JSON.stringify(selectedValues.sioPillars))
    formData.append('blog_region', JSON.stringify(selectedValues.regionList))
    formData.append('blog_country',JSON.stringify(selectedValues.countries))
    
    
    
  console.log("defaultdata",   selectedValues.countries,selectedValues.regionList,selectedDate)
    formData.append('approval_status', 'pending')
    // return;
    API.post(`/blog`, formData, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then((response) => {
      API.get(`/blog`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        setState({ isPaneOpen: false });
        setBlogList(response.data)
        setIsLoading(false)
        setMessage(true)
        setDeleteMessage(false)
      }).catch(error=>{
        setIsLoading(false)
      })
    }).catch(error=>{
      setIsLoading(false)
    })
  }

  const mainHeading = (getHeading) => {
    setPageTitle(getHeading)
  }
    
  const yearOptions = pillarActions && pillarActions.map(item =>
    ({label: item.name, value: item.id})
  )
    
  
  
  const handleChangeAction = (event) =>{
      if (!event) {
        API.get(`/blog`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
          .then((response) => {
            setBlogList(response.data)
          }).catch(error=>{
            setIsLoading(false)
        })
        return;
      }
        API.get(`/get-blog?action_id=${event.value}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
          setBlogList(response.data)
            //setIsLoading(false)
        }).catch(error=>{
            //setIsLoading(false)
        })
    }

    const handleChangeCountry = (event) => {
      if (!event) {
        API.get(`/blog`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
          .then((response) => {
            setBlogList(response.data)
          }).catch(error=>{
            setIsLoading(false)
        })
        return;
      }
        API.get(`/get-blog?country_id=${event.value}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
          setBlogList(response.data)
            //setIsLoading(false)
        }).catch(error=>{
            //setIsLoading(false)
        })
    }

    const handleChangeYear = (event) => {
      if (!event) {
        API.get(`/blog`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
          .then((response) => {
            setBlogList(response.data)
          }).catch(error=>{
            setIsLoading(false)
        })
        return;
      }
        API.get(`/get-blog?year=${event.value}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
          setBlogList(response.data)
            //setIsLoading(false)
        }).catch(error=>{
            //setIsLoading(false)
        })
    }

    const options = countries && countries.map(item =>
        ({label: item.name, value: item.id})
    )
    options?.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });


    const handleSelectChange = (selectedOptions, field) => {
      const selectedValueArray = selectedOptions ? selectedOptions.map(option => option.value) : [];
    
      // Update the selectedValues state for the specific field
      setSelectedValues(prevState => ({
        ...prevState,
        [field]: selectedValueArray
      }));
      
      console.log(`Selected values for ${field}:`, selectedValueArray);
      
    };
    console.log(`values`, selectedValues);
    
    const renderSelect = (data, placeholder, field) => {
      return (
        <Box mb={4}>
          <FormLabel>{placeholder} </FormLabel>
           <Select
            options={data.map(item => ({
              label: item.name,
              value: item.id
            }))}
            onChange={(selected) => handleSelectChange(selected, field)}
            isMulti
            placeholder={`Select ${placeholder}`}
          />
        </Box>
      );
    };


    return (
      <>
        {isLoading ? <LoaderSpinner /> : null}
        <BreadCrumbs
          geteditStatus={geteditStatus}
          title={pageTitle !== null ? pageTitle : "Impact Gallery"}
        />
        <PageInfo editable={edit} mainHeading={mainHeading} />
        <RegionMap />
        <Box mt={"25px"}></Box>
        {edit ? (
          <Button className={Styles.addBlogButton} onClick={openSlidingPane}>
            {" "}
            Add Blog{" "}
          </Button>
        ) : null}
        <Box className={Styles.clearfix}></Box>
        <Box className={Styles.filterDropDown}>
          <DataFilter
            actionOptions={pillarActions}
            countryOptions={countries}
            yearOptions={blogYears}
            getUpdatedList={getUpdatedList}
          />
        </Box>

        {message ? (
          <ErrorWidget message={"Created Impact Gallery successfully"} />
        ) : deleteMessage ? (
          <ErrorWidget message={"Deleted Impact Gallery successfully"} />
        ) : null}
        <Stack divider={<StackDivider />} spacing="4" minH={"200px"}>
          {Object.entries(groupedData).map(([monthYear, blogs]) => (
            <Box key={monthYear}>
              <Heading size="xs" textTransform="uppercase">
                {monthYear} ({blogs.length})
              </Heading>
              <SimpleGrid columns={[1, 2, 3, 5]} spacing="20px" mt={"10px"}>
                {blogs.map((item, index) => (
                  <Box key={index}>
                    {edit ? (
                      <DeleteBlog
                        id={item.id}
                        getUpdatedList={getUpdatedList}
                        getDeleteMessage={getDeleteMessage}
                      />
                    ) : null}
                    <Link
                      className={Styles.customLinkButton}
                      top={"0px"}
                      to={`/portfolio/ig/${id}/${name}/viewpost/${
                        item.id
                      }/${encodeURIComponent(item.title)}`}
                    >
                      <Card maxW="sm">
                        <CardBody p={0}>
                          <Box
                            style={{
                              width: "100%",
                              //paddingBottom: `${(0.5 / aspectRatio) * 100}%`,
                              paddingBottom: "76px",
                              position: "relative",
                            }}
                          >
                            <Image
                              src={
                                item.cover_image !== null
                                  ? item.cover_image
                                  : PlaceholderImg
                              }
                              alt="Description of the image"
                              borderRadius="lg"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "cover", // Ensures the image covers the entire container
                              }}
                            />
                          </Box>
                          {/* <Image
                              src={item.cover_image !== null ? item.cover_image : PlaceholderImg}
                              alt='Cover image'
                              borderRadius='lg'
                            /> */}
                          <Stack mt="4" spacing="3">
                            <Heading
                              size="sm"
                              p={"0 10px"}
                              className={Styles.listHeading}
                            >
                              {item.title}
                            </Heading>
                          </Stack>
                        </CardBody>
                      </Card>
                    </Link>
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          ))}
        </Stack>
        <SlidingPane
          className={Styles.slidingPane}
          overlayClassName="some-custom-overlay-class"
          isOpen={state.isPaneOpen}
          title="Create Blog"
          subtitle=""
          width="50%"
          onRequestClose={() => {
            setState({ isPaneOpen: false });
          }}
        >
          <SimpleGrid columns={[1]} spacing="20px" mt={"10px"}>
            <Box>
              <FormControl>
                <FormLabel>Name </FormLabel>
                <Input type="text" id={"blogTitle"} mb={"15px"} />
              </FormControl>
              <FormControl>
                <FormLabel>Author </FormLabel>
                <Input type="text" id={"authorName"} mb={"15px"} />
              </FormControl>
              <FormControl mb={"15px"}>
                <FormLabel>Upload banner image</FormLabel>
                <Input type="file" onChange={bannerUpload} padding={"4px"} />
              </FormControl>
              <FormControl mb={"15px"}>
                <FormLabel>Select Date</FormLabel>
                <DatePicker
                  selected={selectedDate}
                  // selected={startDate}
                  onChange={handleChange}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="YYYY-MM-DD"
                />
              </FormControl>

              <FormControl mb={"15px"}>
                {renderSelect(tagDetails, "Tags", "tagDetails")}
              </FormControl>
              <FormControl mb={"15px"}>
                {renderSelect(sioPillars, "Social Impact Office", "sioPillars")}
              </FormControl>
              <FormControl mb={"15px"}>
                {renderSelect(pillarActions, "Actions", "pillarActions")}
              </FormControl>
              <FormControl mb={"15px"}>
                {renderSelect(regionList, "Regions", "regionList")}
              </FormControl>
              <FormControl mb={"15px"}>
                {renderSelect(countries, "Countries", "countries")}
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <FormLabel> Content </FormLabel>
                <JoditEditor
                  id={"blogContent"}
                  ref={editor}
                  tabIndex={1} // tabIndex of textarea
                  onChange={(newContent) => {}}
                />
                <Button
                  colorScheme="blue"
                  onClick={createBlog}
                  mt={"20px"}
                  float={"right"}
                >
                  Create Blog
                </Button>
              </FormControl>
            </Box>
          </SimpleGrid>
        </SlidingPane>
      </>
    );
}

export default ImpactGallery;