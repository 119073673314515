import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import Styles from './addimpact.module.css'
import API from '../../../../../Services/API';
import {AddIcon} from '@chakra-ui/icons'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Box,
    Card,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Checkbox, 
    Stack,
    Textarea,
    Image
} from '@chakra-ui/react'
import { SketchPicker } from 'react-color';
import LoaderSpinner from '../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';


const AddImpactMetric = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState()
    const [file, setFile] = useState(null);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState('#ffffff');

    const handleClick = () => {
      setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
      setDisplayColorPicker(false);
    };

    const handleChange = (newColor) => {
      setColor(newColor.hex);
    };

    const addMetric = (event) =>{
        setIsLoading(true)
        const formData = new FormData()
        if(file != null){
            formData.append('image', file)
        }
        formData.append('pillar_id', props.pillarId)
        formData.append('name', $('#impactName').val())
        formData.append('key_fact', $('#keyfact').val())
        formData.append('color_picker', color)
        formData.append('metrictype', 'impact')

        API.post(`/metric`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/metric?pillar_id=${props.pillarId}&query=impact`, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            }).then(response1=>{
              onClose()
              props.getUpdate(response1.data)
              setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)
        })
    }

    function bannerUpload(e) {
        setFile(e.target.files[0]);
    }


    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            <Button className='defaultButton' onClick={onOpen}>
                <AddIcon mr={'10px'} onClick={onOpen} /> Add impact Metric 
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex}>
                    <ModalHeader>Add new impact metric</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Name</FormLabel>
                            <Input type='text' id={'impactName'} mb={'15px'} />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Value</FormLabel>
                            <Input type='text' id={'keyfact'} mb={'15px'} />
                        </FormControl>

                        
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Background Colour</FormLabel>
                            <Input
                                type="text"
                                value={color}
                                onClick={handleClick}
                                readOnly
                                mb={'15px'}
                            />
                            {displayColorPicker && (
                                <Box style={{ position: 'absolute', zIndex: '2' }}>
                                <Box
                                    style={{
                                        position: 'fixed',
                                        top: '0px',
                                        right: '0px',
                                        bottom: '0px',
                                        left: '0px',
                                    }}
                                    onClick={handleClose}
                                />
                                    <SketchPicker color={color} onChange={handleChange} />
                                </Box>
                            )}
                        </FormControl>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Card Image</FormLabel>
                            <Input type="file" onChange={bannerUpload} padding={"4px"}/>
                            <Image src={props.info && props.info.banner} alt={props.info && props.info.banner} />
                        </FormControl>
                        

                        
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter}>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='blue' onClick={addMetric}>Add</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal> 
        </>
    )
}

export default AddImpactMetric