import React, { useState } from 'react';
import Styles from '../impactgallery.module.css';
import Select from 'react-select';
import API from '../../../../Services/API'; // Ensure your API service is correctly imported
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const DataFilter = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const [isLoading, setIsLoading] = useState(false)
    const [selectedAction, setSelectedAction] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '12px', 
            fontSize: '12px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '12px',
        }),
    };

    const actionOptions = props.actionOptions?.map(item => ({
        value: item.id,
        label: item.name,
    }));

    const countryOptions = props.countryOptions?.map(item => ({
      value: item.id,
      label: item.name,
    })).sort((a, b) => a.label.localeCompare(b.label)) || [];

    const yearOptions = props.yearOptions?.map(item => ({
        value: item.year,
        label: item.year.toString(),
    }));

    const handleChangeFilter = async (selectedOption, actionMeta) => {
        setIsLoading(true)
        let actionId = selectedAction;
        let countryId = selectedCountry;
        let year = selectedYear;

        switch (actionMeta.name) {
            case 'actionFilter':
                actionId = selectedOption ? selectedOption.value : null;
                setSelectedAction(actionId);
                break;
            case 'countryFilter':
                countryId = selectedOption ? selectedOption.value : null;
                setSelectedCountry(countryId);
                break;
            case 'yearFilter':
                year = selectedOption ? selectedOption.value : null;
                setSelectedYear(year);
                break;
            default:
                break;
        }

        try {
            // If all filters are null, call the default /blog endpoint
            if (!actionId && !countryId && !year) {
                const response = await API.get(`/blog`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                props.getUpdatedList(response.data)
                setIsLoading(false)
            } else {
                // If any filter is selected, call the filtered API
                const response = await API.get(`/get-blog`, {
                    params: {
                        action_id: actionId,
                        country_id: countryId,
                        year: year,
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                props.getUpdatedList(response.data)
                setIsLoading(false)
            }
        } catch (error) {
            console.error("Error fetching blog data:", error);
            setIsLoading(false)
        }
    };

    

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            <Select
                styles={customStyles}
                className={`${Styles.marginRight} ${Styles.selectBox}`}
                onChange={(option, actionMeta) => handleChangeFilter(option, { ...actionMeta, name: 'actionFilter' })}
                options={actionOptions}
                placeholder="Select Action"
                isClearable
            />
            <Select
                styles={customStyles}
                className={`${Styles.marginRight} ${Styles.selectBox}`}
                onChange={(option, actionMeta) => handleChangeFilter(option, { ...actionMeta, name: 'countryFilter' })}
                options={countryOptions}
                placeholder="Select Country"
                isClearable
            />
            <Select
                styles={customStyles}
                className={`${Styles.marginRight} ${Styles.selectBox}`}
                onChange={(option, actionMeta) => handleChangeFilter(option, { ...actionMeta, name: 'yearFilter' })}
                options={yearOptions}
                placeholder="Select Year"
                isClearable
            />
        </>
    );
};

export default DataFilter;