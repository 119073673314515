import React, { useState, useEffect, useRef, useMemo } from 'react';
import Select from 'react-select';
import Styles from './metric.module.css'
import $ from 'jquery'
import API from '../../../../../../Services/API';
import { Link, useParams } from 'react-router-dom'
import {Card, SimpleGrid, CardHeader, CardBody, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Box, Input, Textarea, Button, Image} from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import AreaChartComponent from '../../../../../Modules/Charts/AreaChartComponent';
import CreateMetric from './CreateMetric/CreateMetric';
import NumberFormat from '../../../../../Modules/NumberFormat/NumberFormat';
import DeleteMetric from './DeleteMetric/DeleteMetric'
import { InfoOutlineIcon } from '@chakra-ui/icons'

const Metrics = (props) => {
  const token = window.localStorage.getItem("accessToken")
  const [isLoading, setIsLoading]  = useState(true)
  const [editable, setEditable] = useState();
  const [metrics, setMetrics] = useState();
  const [length, setLength] = useState();
  const [newLoaderLoading, setNewLoaderLoading]  = useState(null)
  const [deleteMessage, setDeleteMessage]  = useState(false)
  const [fiscalYearList, setFiscalYearList] = useState([])
  const [fiscalQtrList, setFiscalQtrList] = useState([])
  const [countries, setCountries] = useState()

  const [period, setPeriod] = useState('')
  const [country, setCountry] = useState('')
  const [periodType, setPeriodType] = useState('')


  useEffect(() => {
    setIsLoading(true)
    API.get(`/metric?action_id=${props.actionId}`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    .then((response) => {
      setMetrics(response.data)
      setLength(response.data.length)
      setIsLoading(false)
    }).catch(error=>{
      setIsLoading(false)
    })
    API.get(`/fiscal-year-detail`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
        setFiscalYearList(response.data[0].fiscalyear)
        setFiscalQtrList(response.data[1].fiscalquarter)
    }).catch(error=>{
        setIsLoading(false)
    })
    API.get(`/country-list`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    .then((response) => {
        setCountries(response.data)
    }).catch(error=>{
        //setIsLoading(false)
    })
  },[props.actionId])

  const getUpdatedMetrics = (metric) =>{
    setMetrics(metric)
    setLength(metric.length)
  }
  const currentYear = new Date().getFullYear();
  const handleFlip = (event) => {
    $(event.target).closest('.parentBox').addClass('flipped')
  };
  const handleFlipBack = (event) => {
    $(event.target).closest('.parentBox').removeClass('flipped')
  };
  const getUpatedMetric = (metric) =>{
    setMetrics(metric)
    setDeleteMessage(true)
  }
  const isLoaderloading = (loding) =>{
    setNewLoaderLoading(loding)
  }

  

  const options = [
    ...(fiscalYearList && fiscalYearList.length > 0 ? fiscalYearList.map(year => ({ value: year.id, label: year.name, name: 'year'})) : []),
    ...(fiscalQtrList && fiscalQtrList.length > 0 ? fiscalQtrList.map(quarter => ({ value: quarter.id, label: quarter.name, name: 'qtr'})) : [])
  ];

  options.sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });

  const countriesList = countries && countries.map(item =>
    ({label: item.name, value: item.id})
  )

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '12px', 
      fontSize: '12px',
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '12px',
    }),
  };

  const handleChange = (event) => {
    if (!event) {
      setPeriod('')
      setPeriodType('')
      if(country === ''){
        API.get(`/metric?action_id=${props.actionId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .then((response) => {
          setMetrics(response.data)
          setLength(response.data.length)
          setIsLoading(false)
        }).catch(error=>{
          setIsLoading(false)
        })
      }else{
        API.get(`/metric-filter?country=${country}&action_id=${props.actionId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
            setMetrics(response.data)
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })          
      }
    }else{
      setPeriod(event.value)
      setPeriodType(event.name)
      API.get(`/metric-filter?fiscal_${event.name}=${event.value}&country=${country}&action_id=${props.actionId}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response=>{
          setMetrics(response.data)
          setIsLoading(false)
      }).catch(error=>{
          setIsLoading(false)
      })
    }
  };

  const handleChangeCountry = (event) => {
    if (!event) {
      setCountry('')
      if(period !== ''){
        API.get(`/metric-filter?fiscal_${periodType}=${period}&action_id=${props.actionId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
            setMetrics(response.data)
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })        
      }else{
        API.get(`/metric?action_id=${props.actionId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .then((response) => {
          setMetrics(response.data)
          setLength(response.data.length)
          setIsLoading(false)
        }).catch(error=>{
          setIsLoading(false)
        })
      }
    }else{
      // const [period, setPeriod] = useState('')
      // const [country, setCountry] = useState('')
      // const [periodType, setPeriodType] = useState('')

      setCountry(event.value)

      if(period !== '' ){
        API.get(`/metric-filter?fiscal_${periodType}=${period}&country=${event.value}&action_id=${props.actionId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
            setMetrics(response.data)
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })
      }else{
        API.get(`/metric-filter?country=${event.value}&action_id=${props.actionId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
            setMetrics(response.data)
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })
      }

      

    }

    // API.get(`/metric-filter?country=${event.value}&action_id=${props.actionId}`, {
    //     headers: {
    //       'Authorization': 'Bearer ' + token
    //     }
    // }).then(response=>{
    //   setMetrics(response.data)
    //     //setIsLoading(false)
    // }).catch(error=>{
    //     //setIsLoading(false)
    // })
  }

  return (
    <>
      { 
        isLoading ? <LoaderSpinner />: null
      }
      {
        props.editable ? <CreateMetric actionId={props.actionId} getUpdatedMetrics={getUpdatedMetrics} /> : null
      }
      {newLoaderLoading ? <LoaderSpinner /> : null}
      {deleteMessage ? <Box className='colorBlue'> Deleted metric successfully </Box> : null}
      {
        length === 0 ? <Box className={Styles.info}> There are no metric(s) assigned to this action yet. </Box> : null
      }
      {
        <Box>
          <Box className={Styles.filterDropDown}>
            <Select
              styles={customStyles}
              className={Styles.marginRight +" "+ Styles.selectBox}
              onChange={handleChange}
              options={options}
              placeholder="Select fiscal year or Qtr"
              isClearable
            />
            <Select
              styles={customStyles}
              className={Styles.selectBox}
              //value={selectedOption}
              onChange={handleChangeCountry}
              options={countriesList}
              placeholder="Select Country"
              isClearable
            />
          </Box>
          <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'}>
            {
              metrics && metrics.map(metric => 
                <Box maxW='sm' borderWidth='1px' borderRadius='lg' className='parentBox'>
                  <Box className='frontCard'>
                    <InfoOutlineIcon onClick={handleFlip} className='frontFlipIcon' />
                    {
                      props.editable ? 
                        <DeleteMetric 
                          id={metric.id} 
                          pillarId={props.pillarId}
                          actionId ={props.actionId}
                          type={'allmetrics'} 
                          getUpatedMetric={getUpatedMetric}
                          filterStatus={props.filterStatus && props.filterStatus}
                          isLoaderloading={isLoaderloading}
                        /> 
                      : null 
                    }
                    <Box className='clearfix'></Box>
                    <Link className={Styles.teamLink} to={`/metric/${metric.id}/${encodeURIComponent(metric.name)}`}>
                        <Box p={'10px'}>
                            <Heading as='h5' size='sm' className={Styles.metricTitle}>
                                {metric.name}
                            </Heading>
                            <Box className='metricCount'>
                                <>
                                  {
                                    <> 
                                      {
                                        metric?.chart_value?.chart_top_value?.length > 0 ? 
                                          <>
                                            {
                                              metric.chart_value.chart_top_value.map(item =>
                                                item.value !== null ?
                                                  <>
                                                    { 
                                                      metric.datatype === 'money' ? "$" : null
                                                    }
                                                    <NumberFormat number={item.value} />
                                                    {
                                                    metric.datatype === 'percent' ? "%" : null
                                                  }
                                                </> : null
                                              )
                                            }
                                          </> 
                                        : null
                                      }
                                    </>
                                  }
                                </>
                              </Box>
                              <AreaChartComponent chartData={metric?.chart_value?.result} />
                        </Box>
                    </Link>
                  </Box>
                  <Box className='backCard'>
                    <InfoOutlineIcon onClick={handleFlipBack} className='frontFlipIcon' />
                    <Box className='clearfix'></Box>
                    {
                      metric.description === '<p><br></p>' ? 
                        <Box className='noDesc'> No description </Box>
                      : 
                      metric.description === null ? 'No description' : parse(metric.description)
                    }
                  </Box>
                </Box>
              )
            }
          </SimpleGrid>
        </Box>
      }
    </>
  )
}

export default Metrics