import React, { useState, useEffect, useRef, useContext } from 'react';
import { ReactCountryFlag } from 'react-country-flag';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery'
import Styles from './viewcountry.module.css'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import API from '../../../../../Services/API';
import BreadCrumbs from '../../../../../Components/Widgets/BreadCrumbs/BreadCrumbs'
import { Heading, SimpleGrid, Card, CardBody, Image, Stack, StackDivider, Box, Button } from '@chakra-ui/react'
import { Input, FormControl, FormLabel } from '@chakra-ui/react'
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import JoditEditor from 'jodit-react';
import PlaceholderImg from '../../../../../../assets/images/placeholder-1.png'
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
//import PageInfo from './PageInfo/PageInfo';
import DeleteBlog from '../../DeleteBlog/DeleteBlog';
import { EditContext } from '../../../../../Context/BreadcrumbsContext';


const ViewCountry = () => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading]  = useState(true)
    const [editable, setEditable] = useState();
    const [action, setAction] = useState();
    const {countryId} = useParams();
    const editor = useRef(null);
    const [file, setFile] = useState(null);
    const [countryInfo, setCountryInfo] = useState(null);
    const [countryList, setCountryList] = useState(null);
    const [stateCount, setStateCount] = useState(null);
    const {edit, setEdit } = useContext(EditContext);
    const [pageTitle, setPageTitle] = useState()
    const [blogList, setBlogList] = useState();
    const [selectedDate, setSelectedDate] = useState(new Date());
  
    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
    });
    const [deleteMessage, setDeleteMessage] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const { id } = useParams();
    const { name } = useParams();
    const [message, setMessage] = useState(false)

    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
        API.get(`/project-modules?module_id=${countryId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/get-sub-modules?module_id=${countryId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then((response1) => {
                setStateCount(response1.data.length)
                setCountryList(response1.data)
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
            setCountryInfo(response.data[0])
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })
        let date = new Date()
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        setSelectedDate(`${year}-${month}-${day}`);
        API.get(`/get-blog?country_id=${countryId}`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
        })
        .then((response) => {
            setBlogList(response.data)
        }).catch(error=>{
            setIsLoading(false)
        })
    },[countryId])

    const geteditStatus = (isEdit) => {
        setEditable(isEdit)
    }

    const updatePageInfo = (event) => {
        setIsLoading(true)
        const formData = new FormData()
        if(file != null){
            formData.append('banner', file)
        }
        formData.append('name', $("#pagetitle").val())
        formData.append('description', $("#description").val())
        formData.append('id', countryInfo && countryInfo.id)
        API.put(`/project-modules`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/project-modules?module_id=${countryId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then((response1) => {
                setCountryInfo(response1.data[0])
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)
        })
    }
    function bannerUpload(e) {
        setFile(e.target.files[0]);
    }

    if (!blogList || !Array.isArray(blogList)) {
        return <LoaderSpinner />
    }
    
    // Helper function to get month name
    const getMonthYear = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('default', { month: 'long', year: 'numeric' });
    }
    
    // Sorting and grouping the blog list by month
    const sortedData = blogList.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
    
    const groupedData = sortedData.reduce((acc, curr) => {
        const monthYear = curr.date ? getMonthYear(curr.date) : 'Gallery with no Date'; // Handle null or undefined dates
        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }
        acc[monthYear].push(curr);
        return acc;
    }, {});

    const getUpdatedList = (updatedList) => {
        setBlogList(updatedList)
    }

    const getDeleteMessage = (updatedList) => {
        setDeleteMessage(updatedList)
        setMessage(false)
    }
    

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            <BreadCrumbs geteditStatus={geteditStatus} title={countryInfo && countryInfo.name} pillarId={action && action.id} />
            <Card mb={'15px'}>
                <CardBody>
                    {
                        edit ? 
                            <>
                                <Stack spacing={3} mb={'30px'}>
                                    <Input
                                        id={"pagetitle"} 
                                        placeholder='Please Enter Title' 
                                        size='md' 
                                        backgroundColor={"#ffffff"} 
                                        defaultValue={countryInfo && countryInfo.name}
                                    />
                                    <Input type="file" onChange={bannerUpload}  padding={"4px"}/>
                                    <Image src={countryInfo && countryInfo.banner} alt={countryInfo && countryInfo.name} />
                                    {/* <JoditEditor
                                        id={"description"}
                                        ref={editor}
                                        value={props.info && props.info.description}
                                        tabIndex={1} // tabIndex of textarea
                                        onChange={newContent => {}}
                                    /> */}
                                    <Button colorScheme='teal' variant='outline' w={100} onClick={updatePageInfo}>
                                        Save
                                    </Button>
                                </Stack>
                            </>
                        : 
                        <>
                            <ReactCountryFlag countryCode={countryInfo && countryInfo.country_code} svg className={Styles.flagImage} />
                            <Image src={countryInfo && countryInfo.banner} alt={countryInfo && countryInfo.name}/>
                        </>
                    }
                </CardBody>
            </Card>
            <Stack divider={<StackDivider />} spacing='4'>
        {
          Object.entries(groupedData).map(([monthYear, blogs]) => (
            <Box key={monthYear}>
              <Heading size='xs' textTransform='uppercase'>
                {monthYear} ({blogs.length})
              </Heading>
              <SimpleGrid columns={[1, 2, 3, 5]} spacing='20px' mt={'10px'}>
                {
                  blogs.map((item, index) => (
                    <Box key={index}>
                      {edit ? <DeleteBlog id={item.id} getUpdatedList={getUpdatedList} getDeleteMessage={getDeleteMessage} /> : null}
                      <Link className={Styles.customLinkButton} top={'0px'} to={`/portfolio/ig/${id}/${name}/viewpost/${item.id}/${encodeURIComponent(item.title)}`}>
                        <Card maxW='sm'>
                          <CardBody p={0}>
                          <Box
                            style={{
                              width: '100%',
                              //paddingBottom: `${(0.5 / aspectRatio) * 100}%`,
                              paddingBottom: '76px',
                              position: 'relative',
                            }}
                          >
                            <Image
                              src={item.cover_image !== null ? item.cover_image : PlaceholderImg}
                              alt="Description of the image"
                              borderRadius='lg'
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', // Ensures the image covers the entire container
                              }}
                            />
                          </Box>
                            {/* <Image
                              src={item.cover_image !== null ? item.cover_image : PlaceholderImg}
                              alt='Cover image'
                              borderRadius='lg'
                            /> */}
                            <Stack mt='4' spacing='3'>
                              <Heading size='sm' p={'0 10px'} className={Styles.listHeading}>{item.title} fdsydshdf</Heading>
                            </Stack>
                          </CardBody>
                        </Card>
                      </Link>
                    </Box>
                  ))
                }
              </SimpleGrid>
            </Box>
          ))
        }
      </Stack>
        </>
    )
}

export default ViewCountry