import React, { useState, useEffect, useRef, useContext } from 'react';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import API from '../../../../../../../../../Services/API';
import BreadCrumbs from '../../../../../../../../Widgets/BreadCrumbs/BreadCrumbs';
import { Heading, Card, CardBody, Box, Input, Button, Text } from '@chakra-ui/react';
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import LoaderSpinner from '../../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import { EditContext } from '../../../../../../../../../Context/BreadcrumbsContext';

const ViewStrategy = () => {
  const token = window.localStorage.getItem('accessToken');
  const [isLoading, setIsLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [viewStrategy, setViewStrategy] = useState({});
  const { strategyid } = useParams();
  const editor = useRef(null);
  const { edit, setEdit } = useContext(EditContext);

  // Reusable fetch strategy function
  const fetchStrategy = () => {
    API.get(`/strategy?id=${strategyid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setViewStrategy(response.data?.[0]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
    setIsLoading(true);
    fetchStrategy();
  }, [strategyid]);

  const updateStrategy = () => {
    setIsLoading(true);
    const body = {
      id: parseInt(strategyid),
      name: $('#prop_label').val(),
      description: $('#description').val(),
    };

    API.put(`/strategy`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => fetchStrategy())
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <LoaderSpinner />}

      <BreadCrumbs geteditStatus={setEditable} title={viewStrategy?.name || 'Strategy'} />

      <Card>
        <CardBody>
          <Box>
            {edit ? (
              <>
                {/* Editable form for strategy name and description */}
                <Input
                  id="prop_label"
                  placeholder="Enter strategy name"
                  defaultValue={viewStrategy?.name || ''}
                  size="md"
                  mb="15px"
                />

                <JoditEditor
                  id="description"
                  ref={editor}
                  value={viewStrategy?.description || ''}
                  tabIndex={1} // Set tabindex for textarea focus
                  onChange={() => {}}
                />

                <Button className="buttonPrimary" float="right" mt="15px" onClick={updateStrategy}>
                  Save
                </Button>
              </>
            ) : (
              <>
                {/* Display strategy name and description */}
                <Heading as="h2" size="lg" mb="25px">
                  {viewStrategy?.name}
                </Heading>

                <Text mt="25px">
                  {viewStrategy?.description ? parse(viewStrategy.description) : 'No description available'}
                </Text>
              </>
            )}
          </Box>
        </CardBody>
      </Card>
    </>
  );
};

export default ViewStrategy;