import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import API from "../../../../../Services/API";

const SentimentInsights = () => {
    const token = window.localStorage.getItem("accessToken")
    const [pageName, setPageName] = useState(null)
    const {id} = useParams();
    return (
        <>
            <iframe
                src="https://external.sprinklr.com/research/insights/listening/dashboard/6605a9b9ab10735fd2d33cb1/tab/0?id=971DED6795ADA7595E00D3BEB97B87F90DE6557A0C5C0B9A2DD4397AB88964EA"
                width="100%"
                height="600px"
                style={{ border: 'none' }}
                scrolling="yes"
                frameBorder="0"
                allow="encrypted-media"
                title="Dashboard Iframe"
            ></iframe>
        </>
    )
}

export default SentimentInsights