import {React, useState, useRef} from 'react'
import Styles from './delete.module.css'
import API from '../../../Services/API'
import { DeleteIcon } from '@chakra-ui/icons'
import {
  useDisclosure,
  Button,
  Box,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Spinner
} from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Card,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Checkbox, 
    Stack,
    Textarea,
    Text,
} from '@chakra-ui/react'

import LoaderSpinner from '../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'

const DeletePillar = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const [openPopup, setOpenPopup] = useState(false)
    const [message, setMessage] = useState('Deleted article successfully')
    const [isLoading, setIsLoading]  = useState(false)

    const deleteAction = (event) => {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('id', parseInt(props.id))
        formData.append('visible', false)
        API.put(`/pillar`, formData, 
            {
                headers: {'Authorization': 'Bearer ' + token}
            }
        ).then(response =>{
            onClose()
            API.get(`/sio-pillar`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                props.getUpdatedPillars(response.data)
                setIsLoading(false)
                //window.location.reload()
            }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
           
        })
    }

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            <Box className='deleteIconTag' onClick={onOpen} cursor={'pointer'}>
                <DeleteIcon onClick={onOpen} cursor={'pointer'} />
            </Box>
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex}>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text className={Styles.contentInfo}>
                          Are you sure you want to delete it? This action cannot be reversed.
                        </Text>
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter}>
                        <Button mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={Styles.confirmButton} id={props.id} onClick={deleteAction}>Confirm</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DeletePillar