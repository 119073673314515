import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../../../MetricData/metricdata.module.css'
import API from '../../../../../Services/API';
import { IconButton } from '@chakra-ui/react'
import { EditIcon, DeleteIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Card,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Checkbox, 
    Stack,
    Textarea,
    Text,
    Button,
    Tooltip
} from '@chakra-ui/react'
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import Select from 'react-select';

const ApproveComponent = (props) => {
  const token = window.localStorage.getItem("accessToken")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [metricData, setMetricData] = useState()
  const [isLoading, setIsLoading] = useState()
  const [message, setMessage] = useState()
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedOption, setSelectedOption] = useState(props.defaultSelected)
  
  const approveMetric = (event) => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append('id', parseInt(props.id))
    formData.append('action', props.action)
    
    if(props.defaultSelected.toLowerCase() === 'submitted'){
      formData.append('approval_status', 'Approved')
    }else if(props.defaultSelected.toLowerCase() ==='approved'){
      formData.append('approval_status', 'Approved by Action Office')
    }

    API.put(`/elr-dashboard`, formData, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      API.get(`/elr-dashboard`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response=>{
        props.getUpdatedPending(response.data)
        setIsLoading(false)
      }).catch(error=>{
        setIsLoading(false)
      })
    }).catch(error =>{
      props.getErrorUpdate(error.response.data.error)
      setIsLoading(false)
    })
  }
  
  const options = [
    { value: 'Approved', label: 'Approved' },
    { value: 'Approved by Comms Team', label: 'Approved by Comms Team' },
    { value: 'Approved by Action Office', label: 'Approved by Action Office' },
  ];

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption.value)
   
  };

  return (
    <>
        {isLoading ? <LoaderSpinner /> : null}
        <Tooltip id={props.id} label="Approve" aria-label="Approve"> 
          <IconButton
            variant='outline'
            colorScheme='teal'
            aria-label='Call Sage'      
            fontSize='14px'
            icon={<CheckIcon id={props.id} />}
            className={`${Styles.approve} ${Styles.hoverEffectApprove}`} id={props.id}
            onClick={approveMetric}
          />
        </Tooltip>
        
        <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
            <ModalOverlay className={Styles.overLay} />
            <ModalContent className={Styles.contentZindex}>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel fontWeight={'bold'}> Select status from below </FormLabel>
                    <Select
                      options={options}
                      onChange={handleChange}
                      placeholder="Select status"
                      defaultValue={selectedOption}
                    />
                  </FormControl>
                  {/* <Text className={Styles.contentInfo}>
                    Are you sure you want to approve? Once approved the data would be moved to relevant section
                  </Text> */}
                </ModalBody>
                <ModalFooter className={Styles.modelFooter}>
                    <Button mr={3} size="sm" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button className={Styles.confirmButton} id={props.id}  size="sm" onClick={approveMetric}>Update Status</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
  )
}

export default ApproveComponent